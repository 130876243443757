<app-notification-message
  [errorMessage]="errorMessage"
  [successMessage]="successMessage"
>
</app-notification-message>
<app-namc-trial-order-buttons
  [eventId]="eventId"
  [currentRole]="currentRole"
  [eventDetailsFlags]="eventDetailsFlags"
  [selectedOrder]="selectedOrder"
  (namcCommentEmit)="onNamcCommentEmit($event)"
  [rowCount]="rowCount"
  [selectedRows]="selectedRows"
  [screen]="screen"
  [displayRowData]="displayRowData"
></app-namc-trial-order-buttons>
<app-grid
  [columnDefs]="columnDefsFiltered"
  [displayRowData]="displayRowData"
  [rowCount]="rowCount"
  [screen]="screen"
  [rowSelection]="rowSelection"
  (select)="onSelectOrder($event)"
  (select)="onRowSelectionChanged($event)"
  (pageLimit)="onPaginationChanged($event)"
  (select)="onSelectOrder($event)"
></app-grid>
<div class="create_footer fixed-position">
  <button
    class="cancel me-3 mt-2 roboto-bold cancelbtnalign"
    (click)="cancel()"
  >
    BACK
  </button>
  <button
    class="b2-button add black-button blackbtnalign mt-1"
    style="margin-right: 30px;"
    *ngIf="
      eventDetailsFlags.isAllEvents === 'false' &&
      eventDetailsFlags.isEventDeleted === 'false' &&
      (this.currentRole === 'NAMC Admin' || this.currentRole === 'TMNA Admin')
    "    
    (click) = "onSave()"
  >
    SAVE
  </button>
  <button
    class="black-button blackbtnalign me-3 mt-2"
    *ngIf="
      eventDetailsFlags.isAllEvents === 'false' &&
      eventDetailsFlags.isEventDeleted === 'false' &&
      (this.currentRole === 'IS Admin' ||
        this.currentRole === 'QE User' ||
        this.currentRole === 'PE User' ||
        this.currentRole === 'Shop User' ||
        this.currentRole === 'PC User')
    "
    disabled
  >
    SAVE
  </button>
</div>
