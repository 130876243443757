<app-notification-message [errorMessage]="errorMessage">
</app-notification-message>
<div (ngSubmit)="onSubmit()">
  <div class="form-container">
    <div class="form-group">
      <div class="label-container">
        <div *ngIf="!isViewMode" class="vertical-bar"></div>
        <label for="orderTracking">ORDER TRACKING NUMBER</label>
      </div>
      <input
        id="orderTracking"
        type="text"
        [readonly]="isViewMode || editMode"
        [(ngModel)]="ordernumber"
        [ngClass]="{ 'readonly-input': isViewMode || editMode }"
        class="form-control"
        disableSpecialCharacters
      />
    </div>
    <div class="form-group">
      <div class="label-container">
        <label for="psmsRouting">PSMS ROUTING</label>
      </div>
      <input
        id="psmsRouting"
        maxlength="5"
        [readonly]="isViewMode || isSaveDisabled"
        [(ngModel)]="psmsRouting"
        [ngClass]="{ 'readonly-input': isViewMode || isSaveDisabled }"
        type="text"
        class="form-control"
        disableSpecialCharacters
      />
    </div>
    <div class="form-group">
      <div class="label-container">
        <label for="poTracking">PO TRACKING NUMBER</label>
      </div>
      <input
        *ngIf="!POMode"
        id="poTracking"
        type="text"
        [(ngModel)]="trackingnumber"
        [ngClass]="{ 'readonly-input': isViewMode }"
        maxlength="12"
        class="form-control"
        disableSpecialCharacters
      />
      <input
        *ngIf="POMode"
        id="poTracking"
        type="text"
        [readonly]="isViewMode || isSaveDisabled"
        [(ngModel)]="trackingnumber"
        [ngClass]="{ 'readonly-input': isViewMode || isSaveDisabled }"
        maxlength="12"
        class="form-control"
        disableSpecialCharacters
      />
    </div>

    <div class="form-group">
      <div class="label-container">
        <div *ngIf="!isViewMode" class="vertical-bar"></div>
        <label for="supplierCode">SUPPLIER CODE</label>
      </div>
      <input
        *ngIf="isViewMode"
        type="text"
        [readonly]="isViewMode"
        maxlength="12"
        class="form-control"
        [(ngModel)]="suppliercode"
        [ngClass]="{ 'readonly-input': isViewMode }"
      />
      <p-dropdown
        *ngIf="!isViewMode"
        optionLabel="supplierCode"
        optionValue="supplierCode"
        [(ngModel)]="suppliercode"
        (ngModelChange)="onSupplierCodeChange($event)"
        [options]="supplierCodes"
        [ngClass]="{ 'readonly-input': isViewMode }"
        scrollHeight="200px"
        [filter]="true"
      >
      </p-dropdown>
      <div class="error-message" *ngIf="mandatoryFieldErrors?.supplierCode">
        {{ mandatoryFieldErrors.supplierCode }}
      </div>
    </div>

    <div class="form-group">
      <div class="label-container">
        <div *ngIf="!isViewMode" class="vertical-bar"></div>
        <label for="supplierName">SUPPLIER NAME</label>
      </div>
      <input
        type="text"
        [readonly]="isViewMode || editMode"
        maxlength="12"
        class="form-control"
        [(ngModel)]="suppliername"
        [ngClass]="{ 'readonly-input': isViewMode || editMode }"
        disableSpecialCharacters
      />
    </div>

    <div class="form-group">
      <label for="kanbanNumber">KANBAN NUMBER</label>
      <input
        id="kanbanNumber"
        maxlength="4"
        type="text"
        [(ngModel)]="kanbanNumber"
        [readonly]="isViewMode"
        [ngClass]="{ 'readonly-input': isViewMode }"
        class="form-control"
        disableSpecialCharacters
      />
    </div>
    <div class="form-group">
      <div class="label-container">
        <div *ngIf="!isViewMode" class="vertical-bar"></div>
        <label for="partNumber"
          >PART NUMBER
          <span *ngIf="currentRole === '26MTM'" class="text-primary fst-italic"
            >'I' denotes blank space</span
          >
        </label>
      </div>
      <input
        id="partNumber"
        maxlength="12"
        type="text"
        [(ngModel)]="partNumber"
        [readonly]="isViewMode"
        [ngClass]="{ 'readonly-input': isViewMode }"
        class="form-control"
        disableSpecialCharacters
      />
      <div class="error-message" *ngIf="mandatoryFieldErrors?.partNumber">
        {{ mandatoryFieldErrors.partNumber }}
      </div>
    </div>
    <div class="form-group">
      <div class="label-container">
        <div *ngIf="!isViewMode" class="vertical-bar"></div>
        <label for="partName">PART NAME</label>
      </div>
      <input
        id="partName"
        type="text"
        class="form-control"
        [(ngModel)]="partName"
        maxlength="50"
        [readonly]="isViewMode"
        [ngClass]="{ 'readonly-input': isViewMode }"
        disableSpecialCharacters
      />
      <div class="error-message" *ngIf="mandatoryFieldErrors?.partName">
        {{ mandatoryFieldErrors.partName }}
      </div>
    </div>
    <div *ngIf="editMode" class="form-group">
      <div class="label-container">
        <label for="partName">COMMENT</label>
      </div>
      <input
        id="partName"
        type="text"
        class="form-control"
        [(ngModel)]="comment"
        maxlength="1000"
        [readonly]="isViewMode"
        [ngClass]="{ 'readonly-input': isViewMode }"
        disableSpecialCharacters
      />
    </div>
  </div>
  <div class="form-container">
    <div class="form-group">
      <label for="plant1">PLANT 1 QUANTITY</label>
      <input
        type="number"
        id="plant1"
        maxlength="6"
        min="0"
        [readonly]="isViewMode || isSaveDisabled"
        [(ngModel)]="plant1Qty"
        [ngClass]="{ 'readonly-input': isViewMode || isSaveDisabled }"
        class="form-control"
        (keydown)="onKeyDown($event)"
        disableSpecialCharacters
      />
    </div>
    <div class="form-group">
      <label for="plant2">PLANT 2 QUANTITY</label>
      <input
        id="plant2"
        maxlength="6"
        min="0"
        type="number"
        [readonly]="isViewMode || isSaveDisabled"
        [(ngModel)]="plant2Qty"
        [ngClass]="{ 'readonly-input': isViewMode || isSaveDisabled }"
        class="form-control"
        (keydown)="onKeyDown($event)"
        disableSpecialCharacters
      />
    </div>
    <div class="form-group">
      <label for="plant3">PLANT 3 QUANTITY</label>
      <input
        id="plant3"
        maxlength="6"
        min="0"
        [readonly]="isViewMode || isSaveDisabled"
        [(ngModel)]="plant3Qty"
        [ngClass]="{ 'readonly-input': isViewMode || isSaveDisabled }"
        type="number"
        class="form-control"
        (keydown)="onKeyDown($event)"
        disableSpecialCharacters
      />
    </div>
    <div class="form-group">
      <div class="label-container">
        <div *ngIf="!isViewMode" class="vertical-bar"></div>
        <label for="totalQuantity">TOTAL QUANTITY</label>
      </div>
      <input
        id="totalQuantity"
        type="text"
        maxlength="6"
        [(ngModel)]="totalQuantity"
        [readonly]="isViewMode || isSaveDisabled"
        [ngClass]="{ 'readonly-input': isViewMode || isSaveDisabled }"
        class="form-control"
        (keydown)="onKeyDown($event)"
        pattern="^\d*$"
        disableSpecialCharacters
        min="0"
      />
      <div class="error-message" *ngIf="mandatoryFieldErrors?.totalQuantity">
        {{ mandatoryFieldErrors.totalQuantity }}
      </div>
      <div
        class="error-message"
        *ngIf="totalErrorMessage && !mandatoryFieldErrors?.totalQuantity"
      >
        {{ totalErrorMessage }}
      </div>
    </div>
    <div class="form-group">
      <label for="manifestNumber">MANIFEST NUMBER</label>
      <input
        id="manifestNumber"
        type="text"
        [readonly]="isViewMode || editMode"
        [(ngModel)]="manifestNumber"
        [ngClass]="{ 'readonly-input': isViewMode || editMode }"
        maxlength="8"
        class="form-control"
        disableSpecialCharacters
      />
    </div>
    <div class="form-group">
      <label for="storeAddress"> STORE ADDRESS</label>
      <input
        id="storeAddress"
        maxlength="10"
        type="text"
        [(ngModel)]="storeAddress"
        [readonly]="isViewMode || isSaveDisabled"
        [ngClass]="{ 'readonly-input': isViewMode || isSaveDisabled }"
        class="form-control"
        disableSpecialCharacters
      />
    </div>
    <div class="form-group">
      <label for="lineSideAdress">LINE SIDE ADDRESSS</label>
      <input
        id="lineSideAdress"
        maxlength="10"
        type="text"
        [(ngModel)]="lineSideAdress"
        [readonly]="isViewMode || isSaveDisabled"
        [ngClass]="{ 'readonly-input': isViewMode || isSaveDisabled }"
        class="form-control"
        disableSpecialCharacters
      />
    </div>
  </div>
  <div class="form-container">
    <div *ngIf="isViewMode" class="form-group">
      <div class="label-container">
        <div *ngIf="!isViewMode" class="vertical-bar"></div>
        <label for="namcDate">SPECIALIST NAME</label>
      </div>
      <input
        id="namcDate"
        type="text"
        [readonly]="isViewMode"
        [(ngModel)]="specialistName"
        [ngClass]="{ 'readonly-input': isViewMode }"
        class="form-control"
        disableSpecialCharacters
      />
    </div>
    <div *ngIf="isViewMode" class="form-group">
      <div class="label-container">
        <div *ngIf="!isViewMode" class="vertical-bar"></div>
        <label for="namcDate">SPECIALIST CODE</label>
      </div>
      <input
        id="namcDate"
        type="text"
        [readonly]="isViewMode"
        [(ngModel)]="specialistCode"
        [ngClass]="{ 'readonly-input': isViewMode }"
        class="form-control"
        disableSpecialCharacters
      />
    </div>
    <div *ngIf="isViewMode" class="form-group">
      <div class="label-container">
        <label for="namcDate">NAMC COMMENT</label>
      </div>
      <input
        id="namcDate"
        type="text"
        [readonly]="isViewMode"
        [(ngModel)]="namccomment"
        [ngClass]="{ 'readonly-input': isViewMode }"
        class="form-control"
        disableSpecialCharacters
      />
    </div>
    <div *ngIf="isViewMode" class="form-group">
      <div class="label-container">
        <label for="namcDate">SUPPLIER COMMENT</label>
      </div>
      <input
        id="namcDate"
        type="text"
        [readonly]="isViewMode"
        [(ngModel)]="supplierComment"
        [ngClass]="{ 'readonly-input': isViewMode }"
        class="form-control"
        disableSpecialCharacters
      />
    </div>
    <div class="form-group">
      <div class="label-container">
        <label for="namcDate">SCAN COMMENT</label>
      </div>
      <input
        id="namcDate"
        type="text"
        [readonly]="isViewMode || editMode"
        [(ngModel)]="scanComment"
        [ngClass]="{ 'readonly-input': isViewMode || editMode }"
        class="form-control"
        disableSpecialCharacters
      />
    </div>
    <div *ngIf="!editMode" class="form-group">
      <div class="label-container">
        <div *ngIf="!isViewMode" class="vertical-bar"></div>
        <label for="namcDate">NAMC DUE DATE</label>
      </div>
      <input
        id="namcDate"
        type="text"
        class="form-control datepicker"
        [(ngModel)]="namcDate"
        [readonly]="isViewMode"
        [ngClass]="{ 'readonly-input': isViewMode }"
        disableSpecialCharacters
      />
    </div>
    <div *ngIf="editMode" class="form-group">
      <div class="label-container">
        <div *ngIf="!isViewMode" class="vertical-bar"></div>
        <label for="supplierDate">Order Delivery Due Date </label>
      </div>
      <input
        id="supplierDate"
        type="date"
        class="form-control datepicker"
        [(ngModel)]="deliveryduedate"
        [readonly]="isViewMode"
        (keydown)="stopDateInput($event)"
        [ngClass]="{ 'readonly-input': isViewMode }"
      />
      <div class="error-message" *ngIf="dateErrors.supplierDeliveryDate">
        {{ dateErrors.supplierDeliveryDate }}
      </div>
    </div>
    <div class="form-group">
      <div class="label-container">
        <div *ngIf="!isViewMode" class="vertical-bar"></div>
        <label for="supplierDate">SUPPLIER PICKUP DATE </label>
      </div>
      <input
        id="supplierDate"
        type="date"
        class="form-control datepicker"
        [(ngModel)]="supplierPickupDate"
        [readonly]="isViewMode"
        (keydown)="stopDateInput($event)"
        [ngClass]="{ 'readonly-input': isViewMode }"
      />
      <div class="error-message" *ngIf="dateErrors.supplierPickupDate">
        {{ dateErrors.supplierPickupDate }}
      </div>
    </div>
    <div *ngIf="!editMode" class="form-group">
      <div class="label-container">
        <div *ngIf="!isViewMode" class="vertical-bar"></div>
        <label for="supplierDate">SUPPLIER SHIP DATE </label>
      </div>
      <input
        id="supplierDate"
        [readonly]="isViewMode"
        [(ngModel)]="shippingDateTime"
        [ngClass]="{ 'readonly-input': isViewMode }"
        type="text"
        class="form-control datepicker"
      />
    </div>
    <div *ngIf="currentRole === '26MTM'" class="form-group">
      <div class="label-container">
        <label for="workOrderNumber">WORK ORDER NUMBER (WON)</label>
      </div>
      <input
        id="workOrderNumber"
        maxlength="17"
        [readonly]="isViewMode || isSaveDisabled"
        [(ngModel)]="workOrderNumber"
        [ngClass]="{ 'readonly-input': isViewMode || isSaveDisabled }"
        type="text"
        class="form-control"
      />
    </div>
  </div>
  <div class="form-container">
    <div class="form-group">
      <label for="lifecycleCode">LIFE CYCLE CODE</label>
      <input
        id="lifecycleCode"
        maxlength="1"
        [readonly]="isViewMode || isSaveDisabled"
        [(ngModel)]="lifeCycleCode"
        [ngClass]="{ 'readonly-input': isViewMode || isSaveDisabled }"
        type="text"
        class="form-control"
        disableSpecialCharacters
      />
    </div>
    <div *ngIf="POMode" class="form-group">
      <label for="lifecycleCode">SEND TO SAP FLAG</label>
      <!-- <input
        id="lifecycleCode"
        maxlength="1"
        type="text"
        [(ngModel)]="sendToSapFlag"
        [readonly]="isViewMode || isSaveDisabled"
        [ngClass]="{ 'readonly-input': isViewMode || isSaveDisabled }"
        class="form-control"
        disableSpecialCharacters
      /> -->
      <div class="fakeClass">
        <input
          type="radio"
          [(ngModel)]="sendToSapFlag"
          [value]="'YES'"
          [disabled]="isViewMode || isSaveDisabled"
          [ngClass]="{ 'readonly-input': isViewMode || isSaveDisabled }"
          (keydown)="toggleSelection('YES')"
        />
        <div class="mt-2">YES</div>

        <input
          type="radio"
          [(ngModel)]="sendToSapFlag"
          [value]="'NO'"
          [disabled]="isViewMode || isSaveDisabled"
          [ngClass]="{ 'readonly-input': isViewMode || isSaveDisabled }"
          (keydown)="toggleSelection('NO')"
        />
        <div class="mt-2">NO</div>
      </div>
    </div>

    <div *ngIf="!editMode && POMode" class="form-group">
      <label for="lifecycleCode">PO LINE NUMBER</label>
      <input
        id="lifecycleCode"
        maxlength="1"
        type="text"
        [(ngModel)]="poLineNo"
        [readonly]="isViewMode"
        [ngClass]="{ 'readonly-input': isViewMode }"
        class="form-control"
        disableSpecialCharacters
      />
    </div>
    <div *ngIf="!editMode && POMode" class="form-group">
      <label for="lifecycleCode">ARIBA PR NUMBER</label>
      <input
        id="lifecycleCode"
        maxlength="1"
        type="text"
        [(ngModel)]="aribaPrNumber"
        [readonly]="isViewMode"
        [ngClass]="{ 'readonly-input': isViewMode }"
        class="form-control"
        disableSpecialCharacters
      />
    </div>
    <div *ngIf="!editMode && POMode" class="form-group">
      <label for="lifecycleCode">ARIBA ORDER STATUS</label>
      <input
        id="lifecycleCode"
        maxlength="1"
        [readonly]="isViewMode"
        [(ngModel)]="aribaOrderStatus"
        [ngClass]="{ 'readonly-input': isViewMode }"
        type="text"
        class="form-control"
        disableSpecialCharacters
      />
    </div>
    <div *ngIf="!editMode && POMode" class="form-group">
      <label for="lifecycleCode">ARIBA ORDER COMMENT</label>
      <input
        id="lifecycleCode"
        maxlength="1"
        [readonly]="isViewMode"
        [(ngModel)]="aribaOrderComment"
        [ngClass]="{ 'readonly-input': isViewMode }"
        type="text"
        class="form-control"
        disableSpecialCharacters
      />
    </div>
    <div *ngIf="!editMode" class="form-group">
      <label for="palletCode">PALLET CODE</label>
      <input
        id="palletCode"
        [readonly]="isViewMode"
        [(ngModel)]="palletCode"
        [ngClass]="{ 'readonly-input': isViewMode }"
        type="text"
        maxlength="2"
        class="form-control"
        disableSpecialCharacters
      />
    </div>
  </div>
  <div class="form-container" style="margin-bottom: 20px">
    <div class="form-group">
      <div class="label-container">
        <div *ngIf="!isViewMode" class="vertical-bar"></div>
        <label for="rows">ORDER QUANTITY NAMES</label>
      </div>
      <div>
        <table>
          <thead>
            <tr>
              <th>Quantity Name</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of orderQuantities; let i = index">
              <td>
                <input
                  type="text"
                  class="no-border"
                  [(ngModel)]="item.name"
                  [readonly]="isViewMode || isSaveDisabled"
                  [ngClass]="{ 'readonly-input': isViewMode || isSaveDisabled }"
                  name="name{{ i }}"
                />
              </td>

              <td>
                <input
                  type="text"
                  [(ngModel)]="item.quantity"
                  name="quantity{{ i }}"
                  [readonly]="isViewMode || isSaveDisabled"
                  [ngClass]="{ 'readonly-input': isViewMode || isSaveDisabled }"
                  min="0"
                  disableSpecialCharacters
                  pattern="^\d*$"
                  (keydown)="onKeyDown($event)"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="errorMessage" class="error-message">
          {{ errorMessage }}
        </div>
      </div>
    </div>
  </div>

  <br />

  <footer class="footer">
    <button class="cancel-button" (click)="cancel()" *ngIf="!isViewMode">
      CANCEL
    </button>
    <button class="cancel-button" (click)="cancel()" *ngIf="isViewMode">
      BACK
    </button>
    <div class="button-container" *ngIf="!isViewMode">
      <button class="b2-button add" (click)="onSubmit()">SUBMIT</button>
    </div>
  </footer>
</div>
