import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { AttributeDetails } from 'src/app/core/model/trial-order.model';
import { User } from 'src/app/core/model/user.model';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-edit-attribute',
  template: `<button class="edit me-3"
    *ngIf="(params.buttonConfiguration.isAllEvents === 'true' || params.buttonConfiguration.isEventDeleted === 'false'  || params.eventId === '-1' )
     && 
      (this.currentRole === 'NAMC Admin' || this.currentRole === 'TMNA Admin')"
      style="display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 26px;
        height: 26px;
        border: 1px solid #d1d1d1;
        border-radius: 4px;
        background: transparent linear-gradient(180deg, #ffffff 0%, #e4ecf0 100%) 0% 0% no-repeat;
        cursor: pointer;
        box-sizing: border-box;
        padding: 5px;
        transition: background-color 0.3s ease;
        box-shadow: 0.2s;"
        >
    <fa-icon
      [icon]="faEdit"
      title="Modify Single Order"
      (click)="modifyOrder()"
      (keydown)="modifyOrder()"
    ></fa-icon>
  </button>
  <button class="edit me-3"
    *ngIf="
      (params.buttonConfiguration.isAllEvents === 'true' || params.buttonConfiguration.isEventDeleted === 'false'  || params.eventId === '-1' ) && 
      ((this.currentRole === 'IS Admin' || this.currentRole === 'QE User' || this.currentRole === 'PE User' || this.currentRole === 'Shop User' || this.currentRole === 'PC User'))"
      style="display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 26px;
        height: 26px;
        border: 1px solid #d1d1d1;
        border-radius: 4px;
        background: transparent linear-gradient(180deg, #ffffff 0%, #e4ecf0 100%) 0% 0% no-repeat;
        cursor: pointer;
        box-sizing: border-box;
        padding: 5px;
        transition: background-color 0.3s ease;
        box-shadow: 0.2s;" disabled>
    <fa-icon
      [icon]="faEdit"
      title="Modify Single Order"
    ></fa-icon>
  </button>`,
})
export class EditSingleOrderComponent implements ICellRendererAngularComp {
  faEdit = faPencilAlt;
  user: User | null = null;
  params: any;
  currentRole = '';
  eventDetailsFlags: AttributeDetails = {
    trialEventName: '',
    displayPOTrackingNumber: '',
    showEdit: '',
    isAllEvents: '',
    isEventDeleted: '',
  };
  mode = 'edit';
  constructor(
    private readonly userService: UserService,
    public router: Router
  ) {}

  refresh(): boolean {
    return false;
  }

  agInit(params: any): void {
    this.params = params;
    this.userService.userDetails.subscribe((user) => {
      this.user = user;
      if (user) {
        this.loadRoleDetails();
      }
    });
  }

  loadRoleDetails() {
    this.userService.selectedRoles.subscribe((selectedRoles) => {
      const role = this.user?.roles.find(
        (value) => value.namcCode === selectedRoles?.namc
      );
      this.currentRole = role?.roleDescription ?? '';
    });
  }

  modifyOrder() {
    const rowData = JSON.stringify(this.params.data); // Retrieve row data from params
    sessionStorage.setItem('gridData',rowData);
    sessionStorage.setItem('mode',this.mode)
    if(sessionStorage.getItem('eventId') !== '-1')
      this.router.navigate(['/event-order/trial-orders/trialOrderList/editTrials']);
    else
      this.router.navigate(['home/quicklinkordernamc/editTrials']);
  }
}
