import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, of, retry, throwError } from 'rxjs';
import { handleError } from 'src/app/core/common/common';
import { Events } from 'src/app/core/model/events.model';
import { SelectedRole } from 'src/app/core/model/role-check.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UploadOrdersService {
  baseApiUrl: string;

  constructor(private readonly http: HttpClient) {
    this.baseApiUrl = environment.baseAPIURL;
  }

  summonEvents(roleDetails: SelectedRole) {
    const roleDetail = JSON.stringify(roleDetails);
    const encodedRoleDetails = encodeURIComponent(roleDetail);
    return this.http
      .get<Events[]>(
        `${this.baseApiUrl}/events-orders/events?roleDetails=${encodedRoleDetails}`,
        {
          withCredentials: true,
          responseType: 'json',
        }
      )
      .pipe(catchError(handleError));
  }

  submitOrders(payloadObj: any) {
    return this.http
      .post<{
        status: boolean;
        errorList: string[];
        processedOrders: string[];
      }>(`${this.baseApiUrl}/supplier/uploadOrder`, payloadObj, {
        withCredentials: true,
        responseType: 'json',
      })
      .pipe(catchError(handleError));
  }

  submitUpdatedOrders(payload: any) {
    return this.http
      .post<{
        result: string;
        glitchLogs: string[];
        status: boolean;
        processedOrdersNums: string[];
      }>(`${this.baseApiUrl}/supplier/uploadUpdatedOrder`, payload, {
        withCredentials: true,
        responseType: 'json',
      })
      .pipe(
        retry({
          count: 1,
          delay: (error, retryCount) =>
            this.shouldRetry(error)
              ? of(retryCount * 1000)
              : throwError(() => error),
        }),
        catchError(handleError)
      );
  }

  private shouldRetry(error: HttpErrorResponse): boolean {
    return error.status === 500 || error.status === 503 || error.status === 0;
  }
}
