import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, retry } from 'rxjs';
import { handleError } from 'src/app/core/common/common';
import { SelectedRole } from 'src/app/core/model/role-check.model';
import {
  AttributeDetails,
  DownloadBlankOrderDetails,
  DownloadOrderStatusDetails,
  EciApiResponse,
  EventList,
  LabelApiResponse,
  SapPayload,
  SendToSap,
  ShippingApiResponse,
  SupplierShipDatePayload,
  TrialOrderDetails,
  TrialOrderHistory,
  TrialOrderShippingAdd,
} from 'src/app/core/model/trial-order.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TrialOrdersService {
  savedNamcColumns = JSON.parse(
    localStorage.getItem('namc-columns-saved') ?? '[]'
  );
  savedSupplierColumns = JSON.parse(
    localStorage.getItem('supplier-columns-saved') ?? '[]'
  );
  private readonly NamcColumnSubject = new BehaviorSubject<
    { label: string; value: string }[]
  >(this.savedNamcColumns);
  private readonly SupplierColumnSubject = new BehaviorSubject<
    { label: string; value: string }[]
  >(this.savedSupplierColumns);
  namcValue$ = this.NamcColumnSubject.asObservable();
  supplierValue$ = this.SupplierColumnSubject.asObservable();
  baseApiUrl: string;

  constructor(private readonly http: HttpClient) {
    this.baseApiUrl = environment.baseAPIURL;
  }

  sendMailNotification(payloadObj: any) {
    return this.http
      .post<{ message: string }>(
        `${this.baseApiUrl}/events-orders/trial-order/supplier-notification`,
        payloadObj,
        {
          withCredentials: true,
          responseType: 'json',
        }
      )
      .pipe(catchError(handleError));
  }

  getEventList(roleDetails: SelectedRole) {
    const roleDetail = JSON.stringify(roleDetails);
    const encodedRoleDetails = encodeURIComponent(roleDetail);
    return this.http
      .get<EventList[]>(
        `${this.baseApiUrl}/events-orders/trial-order/events?roleDetails=${encodedRoleDetails}`,
        {
          withCredentials: true,
          responseType: 'json',
        }
      )
      .pipe(catchError(handleError));
  }

  downloadEciFile(eventId: number) {
    return this.http
      .get<EciApiResponse>(
        `${this.baseApiUrl}/events-orders/orders/exportEci`,
        {
          params: {
            eventId: eventId,
          },
          withCredentials: true,
          responseType: 'json',
        }
      )
      .pipe(catchError(handleError));
  }

  downloadShippingFile(trialEventId: number) {
    return this.http
      .get<ShippingApiResponse>(
        `${this.baseApiUrl}/events-orders/orders/exportShippingInfo`,
        {
          params: {
            eventId: trialEventId,
          },
          withCredentials: true,
          responseType: 'json',
        }
      )
      .pipe(catchError(handleError));
  }

  downloadLabelFile(eventid: number) {
    return this.http
      .get<LabelApiResponse>(
        `${this.baseApiUrl}/events-orders/orders/exportLabelRequest`,
        {
          params: {
            eventId: eventid,
          },
          withCredentials: true,
          responseType: 'json',
        }
      )
      .pipe(catchError(handleError));
  }

  getOrderHistory(eventId: string) {
    return this.http
      .get<TrialOrderHistory[]>(
        `${this.baseApiUrl}/events-orders/history?eventId=${eventId}`,
        {
          withCredentials: true,
          responseType: 'json',
        }
      )
      .pipe(catchError(handleError));
  }

  getShippingDate(orderNumber: string) {
    return this.http
      .get(
        `${this.baseApiUrl}/events-orders/orders/shipping/date?orderNumber=${orderNumber}`,
        {
          withCredentials: true,
          responseType: 'json',
        }
      )
      .pipe(catchError(handleError));
  }

  getOrderShipping(eventId: string) {
    return this.http
      .get<TrialOrderShippingAdd[]>(
        `${this.baseApiUrl}/events-orders/orders/shipping?eventId=${eventId}`,
        {
          withCredentials: true,
          responseType: 'json',
        }
      )
      .pipe(catchError(handleError));
  }

  getEventDetails(eventId: string) {
    return this.http
      .get<AttributeDetails>(
        `${this.baseApiUrl}/events-orders/orders/event-detail?eventId=${eventId}`,
        {
          withCredentials: true,
          responseType: 'json',
        }
      )
      .pipe(catchError(handleError));
  }

  getGridData(
    eventId: string,
    start: number,
    end: number,
    roleDetails: SelectedRole
  ) {
    const roleDetail = JSON.stringify(roleDetails);
    const encodedRoleDetails = encodeURIComponent(roleDetail);
    return this.http
      .get<any>(
        `${this.baseApiUrl}/events-orders/orders?eventId=${eventId}&start=${start}&end=${end}&roleDetails=${encodedRoleDetails}`,
        {
          withCredentials: true,
          responseType: 'json',
        }
      )
      .pipe(catchError(handleError));
  }

  acceptShipDate(payload: SupplierShipDatePayload) {
    return this.http
      .post(
        `${this.baseApiUrl}/events-orders/orders/shipping/date/accept`,
        payload,
        {
          withCredentials: true,
          responseType: 'json',
        }
      )
      .pipe(catchError(handleError));
  }

  rejectShipDate(payload: SupplierShipDatePayload) {
    return this.http
      .post(
        `${this.baseApiUrl}/events-orders/orders/shipping/date/reject`,
        payload,
        {
          withCredentials: true,
          responseType: 'json',
        }
      )
      .pipe(catchError(handleError));
  }

  updateComments(payload: TrialOrderDetails[], flagValue: string) {
    return this.http
      .post<{ success: string; error: string }>(
        `${this.baseApiUrl}/events-orders/orders/comment?flagValue=${flagValue}`,
        payload,
        {
          withCredentials: true,
          responseType: 'json',
        }
      )
      .pipe(catchError(handleError));
  }

  confirmShipDateSupplier(payload: SupplierShipDatePayload) {
    return this.http
      .post(
        `${this.baseApiUrl}/events-orders/orders/shipping/date/accept/supplier`,
        payload,
        {
          withCredentials: true,
          responseType: 'json',
        }
      )
      .pipe(catchError(handleError));
  }

  toggleSapInterface(payload: SapPayload) {
    return this.http
      .post(`${this.baseApiUrl}/events-orders/orders/sap-flag`, payload, {
        withCredentials: true,
        responseType: 'json',
      })
      .pipe(catchError(handleError));
  }

  sendToSap(eventId: SendToSap) {
    return this.http
      .post(`${this.baseApiUrl}/events-orders/orders/sendToSap`, eventId, {
        withCredentials: true,
        responseType: 'json',
      })
      .pipe(catchError(handleError));
  }

  getBlankOrderTemplateData(eventId: string) {
    return this.http
      .get<DownloadBlankOrderDetails>(
        `${this.baseApiUrl}/events-orders/orders/blank-template?eventId=${eventId}`,
        {
          withCredentials: true,
          responseType: 'json',
        }
      )
      .pipe(catchError(handleError));
  }

  getOrderStatusTemplateData(eventId: string) {
    return this.http
      .get<DownloadOrderStatusDetails>(
        `${this.baseApiUrl}/events-orders/orders/status-template?eventId=${eventId}`,
        {
          withCredentials: true,
          responseType: 'json',
        }
      )
      .pipe(catchError(handleError));
  }

  readExcel(filePath: string) {
    return this.http
      .get(filePath, { responseType: 'arraybuffer' })
      .pipe(catchError(handleError));
  }

  sendSupplierSendRequest(payload: any) {
    return this.http
      .post(`${this.baseApiUrl}/events-orders/orders/shipping/date`, payload, {
        withCredentials: true,
        responseType: 'json',
      })
      .pipe(catchError(handleError));
  }

  multipleSaveOrdersForSupplier(payload: any) {
    return this.http
      .post<{ result: string; errorList: string[] }>(
        `${this.baseApiUrl}/events-orders/orders/saveMultipleOrders`,
        payload,
        {
          withCredentials: true,
          responseType: 'json',
        }
      )
      .pipe(catchError(handleError));
  }

  emitNamcColumns(value: { label: string; value: string }[]): void {
    this.NamcColumnSubject.next(value);
  }

  emitSupplierColumns(value: { label: string; value: string }[]): void {
    this.SupplierColumnSubject.next(value);
  }
}
