<div class="popup-container">
  <button class="close black-button" (click)="onNoClick()">
    <mat-icon>close</mat-icon>
  </button>

  <h2 class="p-3 mb-0 roboto-medium">Confirm Send</h2>
  <hr id="shadow" />
  <br />
  <div class="dialog-body">
    <label for="descriptiveText" class="catlabel">
      {{ descriptiveText }}
      <p style="color: red; font-size: large">{{ warningText }}</p>
    </label>
  </div>
  <div class="dialog-actions">
    <button mat-button class="b2-button add yes-button" (click)="saveClick()">
      YES
    </button>
    <button class="b2 me-3 ms-3 roboto-bold" (click)="onNoClick()">NO</button>
  </div>
</div>
