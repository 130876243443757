import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { TrialOrdersService } from 'src/app/services/event-and-orders/trial-orders.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FiltersComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<FiltersComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      isSupplier: boolean;
      OriginalColumns: { label: string; value: string }[];
    },
    private readonly valueService: TrialOrdersService
  ) {}
  faXmark = faXmark;

  cities: { label: string; value: string }[] = [];

  selectedCities: { label: string; value: string }[] = [];

  chipClicked(chip: string) {
    this.selectedCities = this.selectedCities.filter(
      (item) => item.label !== chip
    );
  }

  selectAllnSave() {
    this.selectedCities = [...this.cities];
    this.onSave();
  }

  selectAll(): void {
    this.selectedCities = [...this.cities];
  }

  clearAll(): void {
    this.selectedCities = [];
  }

  onSave() {
    if (this.data.isSupplier) {
      this.valueService.emitSupplierColumns(this.selectedCities);
    } else this.valueService.emitNamcColumns(this.selectedCities);
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.cities = this.data.OriginalColumns;

    if (this.data.isSupplier) {
      const savedColumns = JSON.parse(
        localStorage.getItem('supplier-columns-saved') ?? '[]'
      );

      if (savedColumns.length === 0) {
        this.selectedCities = [...this.cities];
      } else {
        this.selectedCities = savedColumns;
      }

      this.valueService.supplierValue$?.subscribe(
        (value: { label: string; value: string }[]) => {
          this.selectedCities = value;
        }
      );
    } else {
      const savedColumns = JSON.parse(
        localStorage.getItem('namc-columns-saved') ?? '[]'
      );

      if (savedColumns.length === 0) {
        this.selectedCities = [...this.cities];
      } else {
        this.selectedCities = savedColumns;
      }

      this.valueService.namcValue$?.subscribe(
        (value: { label: string; value: string }[]) => {
          this.selectedCities = value;
        }
      );
    }
  }

  closeDialog() {
    this.dialogRef?.close();
  }
}
