<div class="add-new-message-container">
  <h2>Add New Message</h2>
  <hr />
  <form class="form-container">
    <div class="form-group">
      <div class="d-flex">
        <div class="vertical-bar"></div>
        <label for="supplierPickupDate">MESSAGE EXPIRATION DATE</label>
      </div>
      <input
        type="date"
        id="supplierPickupDate"
        class="form-control"
        name="supplierPickupDate"
        [(ngModel)]="expirationDate"
        [ngClass]="{
          'red-border': !MandatDate
        }"
      />
      <small class="text-danger" *ngIf="!MandatDate"
        >This is a mandatory Field</small
      >
    </div>

    <div class="form-group">
      <div class="d-flex">
        <div class="vertical-bar"></div>
        <label for="namcComment">MESSAGE</label>
      </div>
      <textarea
        id="namcComment"
        class="form-control"
        name="namcComment"
        [(ngModel)]="namcComment"
        maxlength="500"
        [ngClass]="{
          'red-border': !MandatComment
        }"
      ></textarea>
      <small class="text-danger" *ngIf="!MandatComment"
        >This is a mandatory Field</small
      >
    </div>
    <footer class="footer">
      <button class="cancel-button" (click)="cancel()">CANCEL</button>

      <div class="button-container">
        <button class="b2-button add" (click)="validateFields()">SUBMIT</button>
      </div>
    </footer>
  </form>
</div>
