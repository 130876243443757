import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import axios from 'axios';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Event } from 'src/app/core/model/events.model';
import { User } from 'src/app/core/model/user.model';
import { NotificationMessageComponent } from 'src/app/core/notification-message/notification-message.component';
import { UploadOrdersService } from 'src/app/services/events-orders/upload-orders.service';
import { UploadOrderSupplierService } from 'src/app/services/upload-orders-supplier/upload-orders-supplier.service';
import { UserService } from 'src/app/services/user.service';
@Component({
  selector: 'app-upload-orders-data',
  templateUrl: './upload-orders-data.component.html',
  styleUrls: ['./upload-orders-data.component.scss'],
})
export class UploadOrdersDataComponent implements OnInit {
  @ViewChild(NotificationMessageComponent)
  childComponent!: NotificationMessageComponent;
  eventForm!: FormGroup;
  uploadedFileName: string | null = null;
  events: Event[] = [];
  hideForm = false;
  errorMessage: string | undefined = undefined;
  successMessage: string | undefined = undefined;
  user: User | undefined;
  userId = '';
  eventid = 0;
  selectedOption = '';
  uploadUrlErrorMessage = 'Failed to generate upload URL.';
  constructor(
    private readonly fb: FormBuilder,
    private readonly ngxUiLoaderService: NgxUiLoaderService,
    private readonly uploadOrdersService: UploadOrderSupplierService,
    private readonly uploadOrderServiceNamc: UploadOrdersService,
    private readonly userService: UserService
  ) {}

  ngOnInit(): void {
    this.userService.userDetails.subscribe((value) => {
      if (value !== null) {
        this.user = value;
        this.userId = value.userId;
      }
    });
    this.eventForm = this.fb.group({
      event: ['', Validators.required],
      eventDescription: [{ value: '', disabled: true }],
      eventType: [{ value: '', disabled: true }],
      projectCode: [{ value: '', disabled: true }],
      file: [null, Validators.required],
    });
    this.eventForm.get('eventDescription')?.disable();
    this.eventForm.get('eventType')?.disable();
    this.eventForm.get('projectCode')?.disable();
    this.summonEvents();
  }

  summonEvents() {
    this.uploadOrdersService.summonEvents().subscribe({
      next: (response) => {
        this.events = response;
      },
      error: (_error) => {
        this.errorMessage = 'Failed to fetch events. Please try again later.';
      },
    });
  }
  setDetails(event: any): void {
    const selectedEventId = (event as HTMLSelectElement).value;
    const selectedEvent = this.events.find(
      (event: { eventId: number }) => event.eventId === +selectedEventId
    );
    if (selectedEvent) {
      this.eventid = selectedEvent.eventId;

      this.eventForm.patchValue({
        eventDescription: selectedEvent.eventDesc,
        eventType: selectedEvent.eventType,
        projectCode: selectedEvent.projectCode,
        eventid: selectedEvent.eventId,
      });
      this.eventForm.get('eventDescription')?.disable();
      this.eventForm.get('eventType')?.disable();
      this.eventForm.get('projectCode')?.disable();
    }

  }

  onFileChange(event: any) {
    const input = event.target as HTMLInputElement;
    const validMimeTypes = [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
    ];

    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      const fileSize = file.size;
      const maxSize = 20 * 1024 * 1024;
      const maxNameSize = 50;

      if (fileSize > maxSize) {
        this.uploadedFileName = null;
        this.childComponent.specialInvoke(
          'E',
          'The maximum file size can be 20 MB only.'
        );
        return;
      }

      if (file?.name.length > maxNameSize) {
        this.uploadedFileName = null;
        this.childComponent.specialInvoke(
          'E',
          'The maximum file name Length can be 50 Characters only.'
        );
        return;
      }

      if (validMimeTypes.includes(file.type)) {
        if (file) {
          this.uploadedFileName = file.name;
          this.eventForm.patchValue({
            file: file,
          });
        }
      } else {
        this.childComponent.specialInvoke(
          'E',
          'Not A Valid File To Process, Only Upload Valid Excel Sheets'
        );
      }
    }
  }

  removeFile() {
    this.uploadedFileName = null;
    this.eventForm.patchValue({
      file: null,
    });
  }

  showForm(_event: any): void {
    this.eventForm.reset();
    this.hideForm = true;
    this.uploadedFileName = null;
    this.eventForm.get('eventDescription')?.disable();
    this.eventForm.get('eventType')?.disable();
    this.eventForm.get('projectCode')?.disable();
  }
  options = [
    { value: 'eci', name: 'Upload ECI Data' },
    { value: 'label', name: 'Upload Label Request' },
    { value: 'shipping', name: 'Upload Shipping Info' },
  ];
  buttonTextMapping: any = {
    eci: 'Upload ECI Data',
    label: 'Upload Label Request',
    shipping: 'Upload Shipping Info',
  };

  get buttonText(): string {
    return this.buttonTextMapping[this.selectedOption] || 'Default Text';
  }
  onSubmit(): void {
    if (this.eventForm?.valid && this.uploadedFileName) {
      this.ngxUiLoaderService.start('Loader-01');
      const folderMapping: { [key: string]: string } = {
        eci: 'uploadeci',
        label: 'uploadlabel',
        shipping: 'uploadshipping',
      };
      const folderName = folderMapping[this.selectedOption];
      const file = this.eventForm.get('file')?.value;
      if (folderName && file) {
        const originalFileName = file.name;
        const fileType = originalFileName.substring(
          originalFileName.lastIndexOf('.') + 1
        );
        const fileName = `${folderName}/${originalFileName}`;
        this.uploadOrdersService.uploadFile(fileName, fileType).subscribe({
          next: (response) => {
            const fileInput = this.eventForm.value.file;
            const renamedFile = new File([fileInput], response.filename, {
              type: fileInput.type,
              lastModified: fileInput.lastModified,
            });

            axios.put(response.url, renamedFile).then((_response) => {
              const payload = {
                eventid: this.eventid,
                userId: this.userId,
                filename: fileName,
              };
              this.apiCall(payload);
            });
          },
          error: (_err) => {
            this.ngxUiLoaderService.stop('Loader-01');
            this.errorMessage = this.uploadUrlErrorMessage;
          },
        });
      }
    } else {
      this.eventForm.markAllAsTouched();
    }
  }
  apiCall(payload: any) {
    const accumulatedApi = this.uploadOrdersService.universalUploadCall(
      payload,
      this.selectedOption
    );

    accumulatedApi?.subscribe({
      next: (response) => {
        this.eventForm.reset();
        this.uploadedFileName = null;

        if (response.result === '101') {
          this.childComponent.specialInvoke('E', 'Sheet Is Empty');
        } else if (response.result === '100') {
          this.childComponent.specialInvoke(
            'E',
            'File Contains Multiple Sheets'
          );
        } else if (response.result === '102') {
          this.childComponent.specialInvoke('E', 'File Contains Less Columns');
        } else if (response.result === '103') {
          this.childComponent.specialInvoke(
            'E',
            'File Contains More Columns than expected'
          );
        } else if (['104', '105', '107', '404'].includes(response.result)) {
          this.childComponent.specialInvoke(
            'E',
            response.resultDetail.join('\n')
          );
        } else if (response.result === '200') {

          let message = "";
          if(response.successRows>0){
            message = `THE FILE HAS BEEN SUCCESSFULLY SUBMITTED. Processed ${response.successRows} of ${response.numberOfRows} Rows`;
            this.childComponent.specialInvoke(
                  'S',
                  message
                );
          }else {
           const  rowsErrors = response.rowsWithError > 0 ? `, rows containing errors: ${response.rowsWithError}` : "";
            message = `The file presented the following errors:  total errors: ${response.columnsWithErrors} ${rowsErrors}`
              this.childComponent.specialInvoke(
                'E',
                message
              );
            }
        } else {
          this.childComponent.specialInvoke(
            'E',
            response?.resultDetail?.join('\n')
          );
        }
        this.ngxUiLoaderService.stop('Loader-01');
      },
      error: (err) => {
        this.eventForm.reset();
          this.removeFile();
        this.ngxUiLoaderService.stop('Loader-01');
        this.childComponent.specialInvoke('E', 'Something Went Wrong.');
      },
    });
  }
}
