import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-ship-change-request',
  templateUrl: './ship-change-request.component.html',
  styleUrls: ['./ship-change-request.component.scss'],
})
export class ShipChangeRequestComponent implements OnInit {
  isDisabled = false;
  EntryDate!: string;
  NamcDueDate!: string;
  SupplierComments!: string;
  NamcComments!: string;
  validDateMsg1 = '';
  validDateMsg2 = '';

  constructor(
    private readonly dialogRef: MatDialogRef<ShipChangeRequestComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      requestForSuppShipDtChng: string;
      requestForSuppShipcmmntChng: string;
      deliveryDate: string;
    }
  ) {}

  ngOnInit(): void {
    this.isDisabled = true;
    this.EntryDate = this.data.requestForSuppShipDtChng;
    this.NamcDueDate = this.data.deliveryDate;
    this.SupplierComments = this.data.requestForSuppShipcmmntChng;

  }

  onAcceptClick() {
    this.EntryDate = this.data.requestForSuppShipDtChng;
    this.isDisabled = false;
  }

  onRejectClick() {
    this.EntryDate = 'Reject';
    this.isDisabled = false;
  }

  onYesClick(): void {
    if (!this.validDate(this.NamcDueDate)) {
      this.validDateMsg1 = 'please enter a valid Date';
      setTimeout(() => {
        this.validDateMsg1 = '';
      }, 3000);
      return;
    }
    if (!this.validDate(this.EntryDate) && this.EntryDate !== 'Reject') {
      this.validDateMsg2 = 'please enter a valid Date';
      setTimeout(() => {
        this.validDateMsg2 = '';
      }, 3000);
      return;
    }
    this.dialogRef.close({
      status: true,
      data: {
        namcComment: this.NamcComments,
        deliveryDate: this.NamcDueDate,
        promiseDate: this.EntryDate,
      },
    });
  }

  closeDialog(): void {
    this.dialogRef.close({
      status: false,
      data: {},
    });
  }

  validDate(dateString: string) {
    const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/;

    if (!regex.test(dateString)) {
      return false;
    }

    const [month, day, year] = dateString
      .split('/')
      .map((num) => parseInt(num, 10));

    const date = new Date(year, month - 1, day);

    if (
      date.getMonth() + 1 !== month ||
      date.getDate() !== day ||
      date.getFullYear() !== year
    ) {
      return false;
    }

    return true;
  }
}
