import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { WarningPopupComponent } from 'src/app/core/dialogs/warning-popup/warning-popup.component';
import {
  AttributeList,
  DropdownList,
  OrderType,
} from 'src/app/core/model/manage-trial-events.model';
import { SelectedRole } from 'src/app/core/model/role-check.model';
import { NotificationMessageComponent } from 'src/app/core/notification-message/notification-message.component';
import { ManageTrialEventsService } from 'src/app/services/events-and-orders/manage-trial-events.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-create-event',
  templateUrl: './create-event.component.html',
  styleUrls: ['./create-event.component.scss'],
  providers: [FormBuilder],
})
export class CreateEventComponent implements OnInit {
  @ViewChild(NotificationMessageComponent)
  childComponent!: NotificationMessageComponent;
  faTrashCan = faTrashCan;
  createForm: FormGroup;
  selectedOption: string | null = null;
  isView = false;
  isEdit = false;
  showErrorMessage = false;
  selectedRoleDetail: SelectedRole = {
    role: undefined,
    namc: undefined,
    supplierCode: undefined,
    childSupplierCode: undefined,
  };
  disableSpecialistCode: boolean = false

  partsSourceCatalog!: AttributeList[];
  carryOverPartsCatalog = [
    {
      value: 'YES',
      description: 'YES',
    },
    {
      value: 'NO',
      description: 'NO',
    },
  ];
  assignQpcCatalog = [
    {
      value: 'YES',
      description: 'YES',
    },
    {
      value: 'NO',
      description: 'NO',
    },
  ];
  logisticsProviderCatalog!: AttributeList[];
  orderTypeCatalog!: OrderType[];
  shipmentCountryCatalog!: AttributeList[];
  packingNeedCatalog: any;
  ringiNumberCatalog!: { ringiNumber: string }[];

  errorMessage: string | undefined;
  successMessage: string | undefined;

  defaultQuantities: string[] = [
    'PLT1QTY',
    'PLT2QTY',
    'PLT3QTY',
    'TRIALQTY',
    'ASSYTRAINQTY',
    'CFQTY',
    'PEQTY',
    'QEQTY',
    'AXWQTY',
  ];
  userId!: string;
  isDisabled = false;
  EditStatus!: string;
  defaultQuantitiesNums: number[] | undefined = [];
  createdBy!: string;
  eventId!: number | string;
  navigateUrl = '/event-order/manage-trial-events';
  shipmentScheduledCatalog = [
    { value: '', label: '(Select)' },
    { value: 'na', label: 'NA' },
    { value: 'Yes', label: 'YES' },
    { value: 'No', label: 'NO' },
  ];

  constructor(
    private readonly fb: FormBuilder,
    private readonly ngxUiLoaderService: NgxUiLoaderService,
    private readonly manageTrialEventService: ManageTrialEventsService,
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly dialog: MatDialog
  ) {
    this.createForm = this.fb.group({
      namcCode: [{ value: '', disabled: true }, Validators.required],
      projectCode: ['', [Validators.required, Validators.maxLength(4)]],
      partsSource: ['', Validators.required],
      eventName: ['', [Validators.required, Validators.maxLength(30)]],
      eventDescription: ['', [Validators.required, Validators.maxLength(100)]],
      assignQpc: ['', Validators.required],

      shipmentLocation: ['', [Validators.required, Validators.maxLength(30)]],
      shipmentAddress: ['', [Validators.required, Validators.maxLength(150)]],
      shipmentCity: ['', [Validators.required, Validators.maxLength(20)]],
      shipmentState: ['', [Validators.required, Validators.maxLength(4)]],
      shipmentCountry: ['', Validators.required],
      shipmentZip: ['', [Validators.required, Validators.maxLength(20)]],
      shipmentScheduled: [''],

      mainRouteNameOverride: [{ value: '', disabled: true }],
      subRouteNameOverride: [{ value: '', disabled: true }],
      costCenter: ['', Validators.required],
      ringiNumber: ['', Validators.required],
      dockCode: ['', [Validators.required, Validators.maxLength(2)]],
      orderType: [{ value: '', disabled: false }, Validators.required],
      orderNumberSeries: ['', [Validators.required, Validators.maxLength(6)]],
      trialOrderSpecialistCode: [
        '',
        [Validators.required, Validators.maxLength(2)],
      ],
      carryOverParts: ['', Validators.required],
      packingNeed: [''],
      rows: this.fb.array([]),
      logisticsProvider: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.ngxUiLoaderService.start('LOADER-01');
    this.selectedRoleDetail = JSON.parse(
      sessionStorage.getItem('roleDetails') ?? '{}'
    );
    this.fetchDropDownDetails();

    this.createForm.patchValue({
      namcCode: this.selectedRoleDetail.namc,
    });
    this.setRequiredValidators(this.selectedRoleDetail.namc);
    this.initialiseUserSettings();

    if (this.router.url.includes('/view-event')) {
      this.isView = true;
      this.createForm.disable();
      this.depositEditParams();
      this.isDisabled = true;
    } else if (this.router.url.includes('/edit-event')) {
      this.isEdit = true;
      if (this.isEdit) {
        this.createForm.controls['mainRouteNameOverride'].enable();
        this.createForm.controls['subRouteNameOverride'].enable();
      }
      this.depositEditParams();
    } else {
      this.selectedOption = 'NO';
      this.setDefaultRows();
      this.createForm.get('mainRouteNameOverride')?.disable();
      this.createForm.get('subRouteNameOverride')?.disable();
    }
  }

  depositEditParams() {
    this.manageTrialEventService.editData$?.subscribe((data) => {
      if (data) {
        const params = data;
        if (params) {
          this.createForm.patchValue({
            projectCode: params['projectCode'],
            partsSource: params['partSource'],
            eventName: params['eventName'],
            eventDescription: params['eventDescription'],
            assignQpc: params['assignQPCToTrial'],

            shipmentLocation: params['shipmentLocation'],
            shipmentAddress: params['shipmentAddress'],
            shipmentCity: params['shipmentCity'],
            shipmentState: params['shipmentState'],
            shipmentCountry: params['shipmentCountry'],
            shipmentZip: params['shipmentZip'],
            shipmentScheduled: params['shipmentScheduledTracking'],

            mainRouteNameOverride: params['mainRouteNameOverride'],
            subRouteNameOverride: params['subRouteNameOverride'],
            costCenter: params['costCenter'],
            ringiNumber: params['ringiNumber'],
            dockCode: params['dockCode'],
            orderType: params['orderType'] === 'PURCHASE ORDER' ? '2' : '1',
            orderNumberSeries: params['orderNumberSeries'],
            carryOverParts: params['carryoverPartsOrdering'],
            packingNeed: params['packagingNeed'],
            logisticsProvider: params['logisticProvider'],
          });
          this.selectedOption = params['isNAQD'] === 'Y' ? 'YES' : 'NO';
          this.mobiliseQuantities(params['eventId']);
          this.EditStatus = params['eventStatus'];
          this.createdBy = params['userId'];
          this.eventId = params['eventId'];
        }
      } else {
        this.errorMessage = 'Something Went Wrong.';
      }
    });
  }

  mobiliseQuantities(eventId: string) {
    this.manageTrialEventService.mobiliseOrderQuantities(eventId)?.subscribe({
      next: (result) => {
        if (result && Array.isArray(result.quantityResult)) {
          this.defaultQuantities = [
            'PLT1QTY',
            'PLT2QTY',
            'PLT3QTY',
            ...result.quantityResult.map((element) => element.quantityName),
          ];
          this.defaultQuantitiesNums = result.quantityResult.map(
            (element) => element?.quantityId || 0
          );
          const specialistCode =
            result.specialistDetails[0].specialistCode ?? 0;
          this.createForm.patchValue({
            trialOrderSpecialistCode: specialistCode,
          });
          if (+specialistCode) {
            this.disableSpecialistCode = true;
          } else {
            this.disableSpecialistCode = false;
          }
          if (result.orderCount !== 0) {
            this.createForm.get('orderType')?.disable();
          } else {
            this.EditStatus === 'ACTIVE'
              ? this.createForm.get('orderType')?.enable()
              : this.createForm.get('orderType')?.disable();
          }
        }
        this.setDefaultRows();
        if (this.isView) {
          this.createForm?.disable();
        }
      },
      error: (err) => {
        this.ngxUiLoaderService.stop('LOADER-01');
        const error = err as HttpErrorResponse;
        this.childComponent.specialInvoke('E', error.error.error);
      },
    });
  }

  initialiseUserSettings() {
    this.userService.userDetails?.subscribe((value) => {
      if (value !== null) {
        const specialistCode = value.roles.find(
          (element) => element.namcCode === this.selectedRoleDetail.namc
        );
        if (!/(\/edit-event|\/view-event)/.test(this.router.url)) {
          this.createForm.patchValue({
            trialOrderSpecialistCode: Number(specialistCode?.specialistCode),
          });
        }
        this.userId = value.userId;
        if (specialistCode?.roleName === 'NAMC_ADMIN') {
          if (+specialistCode?.specialistCode) {
            this.disableSpecialistCode = true;
          }
        }
      }
    });
  }

  setRequiredValidators(namcCode: string | undefined) {
    if (namcCode === '71NPE') {
      let control = this.createForm.get('ringiNumber');
      control?.setValidators([Validators.required]);
      control?.updateValueAndValidity();
      control = this.createForm.get('costCenter');
      control?.clearValidators();
      control?.updateValueAndValidity();
    } else {
      let control = this.createForm.get('costCenter');
      control?.setValidators([Validators.required]);
      control?.updateValueAndValidity();
      control = this.createForm.get('ringiNumber');
      control?.clearValidators();
      control?.updateValueAndValidity();
    }
  }

  fetchDropDownDetails() {
    this.manageTrialEventService.dropDownOnLoad()?.subscribe({
      next: (result: DropdownList) => {
        this.ngxUiLoaderService.stop('LOADER-01');
        this.partsSourceCatalog = result.partSourceList;
        this.shipmentCountryCatalog = result.countryList;
        this.orderTypeCatalog = result.orderTypeList;
        this.logisticsProviderCatalog = result.logisticProviderList;
        this.packingNeedCatalog = [
          {
            attributeValue: '',
            attributeDescription: '(Select)',
          },
        ];
        this.packingNeedCatalog.push(...result.packagingList);
        this.ringiNumberCatalog = result.ringiNumberList;
        if (!/(\/edit-event|\/view-event)/.test(this.router.url)) {
          this.createForm.patchValue({
            mainRouteNameOverride: result?.mainRouteName[0]?.mainRouteName,
            subRouteNameOverride: result?.subRouteName[0]?.subRouteName,
          });
        }
      },
      error: (err) => {
        this.ngxUiLoaderService.stop('LOADER-01');
        const error = err as HttpErrorResponse;
        this.childComponent.specialInvoke('E', error.error.error);
      },
    });
  }

  setSuccessMessage(success: string) {
    this.successMessage = success;
    setTimeout(() => {
      this.successMessage = undefined;
    }, 5000);
  }

  cancel() {
    if (this.router.url.includes('/view-event')) {
      window.history.back();
    } else {
      const dialogRef = this.dialog.open(WarningPopupComponent, {
        disableClose: true,
        width: '570px',
        height: '219px',
      });

      dialogRef.afterClosed()?.subscribe((result: string | undefined) => {
        if (result === undefined) {
          return;
        }
        if (result === 'Success') {
          this.router.navigate([this.navigateUrl]);
        }
      });
    }
  }

  get rows(): FormArray {
    return this.createForm.get('rows') as FormArray;
  }

  onSubmit(): void {
    if (this.createForm.valid) {
      this.ngxUiLoaderService.start('LOADER-01');
      const payload = this.rejuvanatePayload();

      const eventAction = this.isEdit
        ? this.manageTrialEventService.editEvent(payload)
        : this.manageTrialEventService.induceEvent(payload);

      eventAction?.subscribe({
        next: (result) => {
          this.ngxUiLoaderService.stop('LOADER-01');
          if (result === '805') {
            this.childComponent.specialInvoke('E', 'Unknown Specialist Code');
          } else if (result === '804') {
            this.childComponent.specialInvoke(
              'E',
              'Provided Event Name already exists'
            );
          } else if (this.isEdit && result === '200') {
            this.manageTrialEventService.setMessage(
              `Event '${payload.eventName}' Updated Successfully.`
            );
            this.router.navigate([this.navigateUrl]);
          } else if (!this.isEdit && result === '200') {
            this.manageTrialEventService.setMessage(
              `New Event '${payload.eventName}' Created Successfully.`
            );
            this.router.navigate([this.navigateUrl]);
          }
          window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        error: (err) => {
          this.ngxUiLoaderService.stop('LOADER-01');
          const error = err as HttpErrorResponse;
          this.childComponent.specialInvoke('E', error.error.error);
        },
      });
    } else {
      this.createForm.markAllAsTouched();
    }
  }

  rejuvanatePayload() {
    return {
      createdBy: this.isEdit ? this.createdBy : this.userId,
      userId: this.userId,
      createdTs: new Date().toLocaleDateString('en-US'),
      eventId: this.isEdit ? this.eventId : '',
      assignQPCToTrial: this.createForm.value.assignQpc,
      carryoverPartsOrdering: this.createForm.value.carryOverParts,
      dockCode: this.createForm.value.dockCode,
      eventDescription: this.createForm.value.eventDescription,
      eventName: this.createForm.value.eventName,
      eventTypeId: Number(this.createForm.value.orderType),
      logisticProvider: this.createForm.value.logisticsProvider,
      mainRouteNameOverride:
        this.createForm.getRawValue().mainRouteNameOverride,
      subRouteNameOverride: this.createForm.getRawValue().subRouteNameOverride,
      namcCode: this.createForm.getRawValue().namcCode,
      orderNumberSeries: this.createForm.value.orderNumberSeries,
      specialistCode: Number(this.createForm.value.trialOrderSpecialistCode),
      partsSource: this.createForm.value.partsSource,
      projectCode: this.createForm.value.projectCode,
      shipmentAddress: this.createForm.value.shipmentAddress,
      shipmentCity: this.createForm.value.shipmentCity,
      shipmentState: this.createForm.value.shipmentState,
      shipmentCountry: this.createForm.value.shipmentCountry,
      shipmentLocation: this.createForm.value.shipmentLocation,
      shipmentPostalCode: this.createForm.value.shipmentZip,
      shipmentScheduledTracking: this.createForm.value.shipmentScheduled,
      statusId: this.isEdit ? this.EditStatusGen() : 1,
      packagingNeed: this.createForm.value.packingNeed,
      costCenter: this.createForm.value.costCenter,
      ringiNumber: this.createForm.value.ringiNumber,
      isNAQD: this.selectedOption === 'YES' ? 'Y' : 'N',
      quantityNamesTxt: this.generateQtyNames(),
      quantityNamesHdn: this.generateQtyIds(),
    };
  }

  generateQtyIds() {
    return this.defaultQuantitiesNums?.join(',');
  }

  generateQtyNames() {
    if (this.createForm.value.rows) {
      return (
        'TRIALQTY,' +
        this.createForm.value.rows
          .map((elememt: any) => {
            return elememt.value;
          })
          .join(',')
      );
    }
    return 'TRIALQTY';
  }

  EditStatusGen() {
    switch (this.EditStatus) {
      case 'ACTIVE':
        return 1;
      case 'DELETED':
        return 2;
      case 'COMPLETED':
        return 3;
      default:
        return 4;
    }
  }

  toggleSelection(option: string) {
    if (this.selectedOption === option) {
      this.selectedOption = null;
    } else {
      this.selectedOption = option;
    }
  }

  setDefaultRows() {
    const formArray = this.rows;
    this.defaultQuantities.forEach((name) => {
      formArray.push(this.createRow(name));
    });
  }

  createRow(value: string): FormGroup {
    if (['PLT1QTY', 'PLT2QTY', 'PLT3QTY', 'TRIALQTY'].includes(value)) {
      return this.fb.group({
        value: [{ value: value, disabled: true }],
      });
    }
    return this.fb.group({
      value: value,
    });
  }

  addRow() {
    if (this.rows.length < 9) {
      const row = this.fb.group({
        value: new FormControl(''),
      });
      this.rows.push(row);
    } else {
      this.showErrorMessage = true;
    }
    setTimeout(() => {
      this.showErrorMessage = false;
    }, 5000);
  }

  deleteRow(index: number) {
    this.rows.removeAt(index);
  }

  onKeyDown(event: KeyboardEvent) {
    const inputValue = (event.target as HTMLInputElement).value;
    const allowedCharacters = /^\d*$/;
    const char = event.key;
    const allowedSpecialKeys = [
      'Backspace',
      'Delete',
      'ArrowLeft',
      'ArrowRight',
      'ArrowUp',
      'ArrowDown',
      'Tab',
      'Enter',
      'Escape',
    ];
    if (!allowedCharacters.test(char) && !allowedSpecialKeys.includes(char)) {
      event.preventDefault();
    }
    if (inputValue.length >= 2 && !allowedSpecialKeys.includes(char)) {
      event.preventDefault();
    }
  }
}
