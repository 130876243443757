import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TrialOrderDetails } from 'src/app/core/model/trial-order.model';
import { TrialOrdersService } from 'src/app/services/event-and-orders/trial-orders.service';
@Component({
  selector: 'app-accept-orders-dialog',
  templateUrl: './accept-orders-dialog.component.html',
  styleUrls: ['./accept-orders-dialog.component.scss'],
})
export class AcceptOrdersDialogComponent {
  headerTitle: string;
  actionButtonLabel: string;
  comment = '';
  selectedRow: TrialOrderDetails[] = [];
  ordersNumber = '';

  constructor(
    private readonly dialogRef: MatDialogRef<AcceptOrdersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly ngxUiLoaderService: NgxUiLoaderService,
    private readonly trialOrderService: TrialOrdersService
  ) {
    this.headerTitle = data.headerTitle || 'Default Title';
    this.actionButtonLabel = data.actionButtonLabel || 'Default Action';
    this.selectedRow = this.data.selectedOrder;
  }

  onYesClick(): void {
    this.ordersNumber = this.selectedRow
      .map((order) => order.orderNumber)
      .join(',');
    if (this.headerTitle === 'Accept') {
      this.acceptShipDate();
    } else {
      this.rejectShipDate();
    }
  }

  acceptShipDate() {
    if(this.comment === ''){
      this.dialogRef.close({error:"Comments cannot be empty for selected order(s)."});
      return;
    }
    const payload = {
      appendOrderNumbers: this.ordersNumber,
      appendComment: this.comment,
    };
    this.ngxUiLoaderService.start('accept');
    this.trialOrderService.acceptShipDate(payload)?.subscribe({
      next: (result) => {
        this.ngxUiLoaderService.stop('accept');
        this.dialogRef.close(result);
      },
      error: (err) => {
        this.ngxUiLoaderService.stop('accept');
        const error = err as HttpErrorResponse;
        this.dialogRef.close(error.error);
      },
    });
  }

  rejectShipDate() {
    if(this.comment === ''){
      this.dialogRef.close({error:"Comments cannot be empty for selected order(s)."});
      return;
    }
    const payload = {
      appendOrderNumbers: this.ordersNumber,
      appendComment: this.comment,
    };
    this.ngxUiLoaderService.start('reject');
    this.trialOrderService.rejectShipDate(payload)?.subscribe({
      next: (result) => {
        this.ngxUiLoaderService.stop('reject');
        this.dialogRef.close(result);
      },
      error: (err) => {
        this.ngxUiLoaderService.stop('reject');
        const error = err as HttpErrorResponse;
        this.dialogRef.close(error.error);
      },
    });
  }

  onNoClick(): void {
    this.dialogRef.close('');
  }

  closeDialog(): void {
    this.dialogRef.close('');
  }
}
