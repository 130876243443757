import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { WarningPopupComponent } from 'src/app/core/dialogs/warning-popup/warning-popup.component';
import {
  OrderData,
  TrialOrderDetails,
} from 'src/app/core/model/trial-order.model';
import { User } from 'src/app/core/model/user.model';
import { TrialOrdersService } from 'src/app/services/event-and-orders/trial-orders.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-confirm-single-order',
  templateUrl: './confirm-single-order.component.html',
  styleUrls: ['./confirm-single-order.component.scss'],
})
export class ConfirmSingleOrderComponent implements OnInit {
  currentRole: string | undefined;
  namcCode = '';
  successMessage: string | undefined = undefined;
  errorMessage: string | undefined = undefined;
  user: User | null = null;
  orderTrackingNumber = '';
  partNumber = '';
  partName = '';
  namcDueDate = '';
  totalQuantity = '';
  supplierPickupDate = '';
  supplierShipDate = '';
  namcComment = '';
  supplierComment = '';
  supplierMode = false;
  selectedOrder: TrialOrderDetails = {} as TrialOrderDetails;
  orderData: OrderData = {} as OrderData;
  disableflag = false;
  minDate = '';
  isError = false;
  isErrorSupplierDate = false;
  screen = '';
  isRequired = false;
  previousDateValue = '';

  constructor(
    public dialog: MatDialog,
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly orderservice: TrialOrdersService,
    private readonly trialOrderService: TrialOrdersService,
    private readonly ngxUiLoaderService: NgxUiLoaderService
  ) {}

  ngOnInit(): void {
    this.minDate = this.getTodayDate();
    this.selectedOrder = JSON.parse(history.state?.data);
    this.screen = history.state?.screen;
  
    this.userService.userDetails.subscribe((user) => {
     
      this.user = user;
      this.supplierMode = true;
    });
    this.mapData();
  }

  getTodayDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  mapData() {
    this.orderTrackingNumber = this.selectedOrder.orderNumber;
    this.ngxUiLoaderService.start('confirmShipDateSupplierOnload');
    this.orderservice.getShippingDate(this.orderTrackingNumber)?.subscribe({
      next: (res: any) => {
        this.ngxUiLoaderService.stop('confirmShipDateSupplierOnload');
        this.orderData = res;
        this.disableflag = this.orderData.disableFlag;
        if (this.disableflag === true) {
          this.errorMessage = this.orderData.message;
        }
        this.partNumber = this.orderData.orderData?.partNumber;
        this.partName = this.orderData.orderData?.partName;
        this.namcComment = this.orderData.orderData?.namcComment;
        this.supplierComment = this.orderData.orderData?.supplierComment;
        this.supplierPickupDate = this.orderData.orderData?.supplierPickupDate;
        this.supplierShipDate = this.convertToDateFormat(
          this.orderData.orderData?.supplierPickupDate
        );
        this.namcDueDate = this.orderData.orderData?.orderDueDate;
        this.totalQuantity = this.orderData.orderData?.totalQty;
        this.previousDateValue = this.convertToDateFormat(
          this.orderData.orderData?.supplierPickupDate
        );
      },
      error: (_err) => {
        this.errorMessage = 'Failed to fetch supplier codes';
      },
    });
  }

  onDateChange(newValue: string): void {
    if (this.previousDateValue !== newValue) this.isRequired = true;
  }

  convertToDateFormat(date: string): string {
    const [month, day, year] = date.split('/');
    return `${year}-${month?.padStart(2, '0')}-${day?.padStart(2, '0')}`;
  }

  stopDateInput(event: KeyboardEvent){
    event.preventDefault();
  }

  cancel(buttonName: string) {
    if(buttonName === 'cancel'){
      const dialogRef = this.dialog.open(WarningPopupComponent, {
        disableClose: true,
        width: '570px',
        height: '220px',
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result === 'Success') {
          if (this.screen === 'quickLinks') {
            this.router.navigate(['home/quicklinkordersupplier']);
          } else {
            this.router.navigate([
              'event-order/trial-orders/supplierTrialOrderList',
            ]);
          }
        }
      });
    }
    else if (this.screen === 'quickLinks') {
        this.router.navigate(['home/quicklinkordersupplier']);
      } else {
        this.router.navigate([
          'event-order/trial-orders/supplierTrialOrderList',
        ]);
      }
  }

  isBefore10Days(dateString1: string, dateString2: string) {
    const inputDate1 = new Date(dateString1);
    const inputDate2 = new Date(dateString2);

    inputDate2.setDate(inputDate2.getDate() - 10);
    return inputDate1 < inputDate2;
  }

  onSubmit() {
    if (this.supplierComment === '' && this.isRequired) {
      this.isError = true;
      return;
    }
    if (this.isBefore10Days(this.supplierShipDate, this.supplierPickupDate)) {
      this.isErrorSupplierDate = true;
      return;
    }
    const payload = {
      appendOrderNumbers: this.selectedOrder.orderNumber,
      appendComment: this.supplierComment,
      appendDeliveryDate: this.supplierShipDate ?? '',
      userId:this.user?.userId
    };
    this.ngxUiLoaderService.start('confirmShipDateSupplier');
    this.trialOrderService.confirmShipDateSupplier(payload).subscribe({
      next: (result) => {
        this.ngxUiLoaderService.stop('confirmShipDateSupplier');
        if (result) {
          this.successMessage = Object.values(result)[0];
          this.router.navigate(
            ['event-order/trial-orders/supplierTrialOrderList'],
            {
              state: {
                successMessage: this.successMessage,
              },
            }
          );
        }
      },
      error: (err) => {
        this.disableflag = true;
        this.ngxUiLoaderService.stop('confirmShipDateSupplier');
        const error = err as HttpErrorResponse;
        this.errorMessage = error.error.error;
        setTimeout(() => {
          this.errorMessage = undefined;
          this.successMessage = undefined;
        }, 5000);
      },
    });
  }
}
