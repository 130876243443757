import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { faWeibo } from '@fortawesome/free-brands-svg-icons';
import { faCheckSquare } from '@fortawesome/free-regular-svg-icons';
import {
  faCheck,
  faColumns,
  faComment,
  faDownload,
  faEye,
  faFilter,
  faShareFromSquare,
  faTruck,
} from '@fortawesome/free-solid-svg-icons';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmSelectedOrdersComponent } from 'src/app/core/dialogs/event-and-orders/confirm-selected-orders/confirm-selected-orders.component';
import { SupplierShipDateComponent } from 'src/app/core/dialogs/event-and-orders/supplier-ship-date/supplier-ship-date.component';
import { FiltersComponent } from 'src/app/core/dialogs/filter/filter.component';
import { SupplierCommentsActionComponent } from 'src/app/core/dialogs/supplier-comments-action/supplier-comments-action.component';
import { SupplierCommentsActionList } from 'src/app/core/model/comments.model';
import { SelectedRole } from 'src/app/core/model/role-check.model';
import {
  AttributeDetails,
  EciApiResponse,
  ExcelHeader,
  LabelApiResponse,
  ShippingApiResponse,
  TrialOrder,
  TrialOrderDatas,
  TrialOrderDetails,
  TrialOrderdata,
} from 'src/app/core/model/trial-order.model';
import { UserResponse } from 'src/app/core/model/user.model';
import { ExportOrdersService } from 'src/app/services/event-and-orders/export-orders.service';
import { TrialOrdersService } from 'src/app/services/event-and-orders/trial-orders.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-supplier-trial-order-buttons',
  templateUrl: './supplier-trial-order-buttons.component.html',
  styleUrls: ['./supplier-trial-order-buttons.component.scss'],
})
export class SupplierTrialOrderButtonsComponent implements OnInit {
  customizedSuccessMessage: string[] = [];
  customizederrorMessage: string[] = [];
  successMessage: string | undefined = undefined;
  errorMessage: string | undefined = undefined;
  faComment = faComment;
  faCheckSquareO = faCheckSquare;
  faEye = faEye;
  faWeibo = faWeibo;
  faFilter = faFilter;
  faCheck = faCheck;
  faDownload = faDownload;
  faTruck = faTruck;
  faColumns = faColumns;
  faShareFromSquare = faShareFromSquare;
  @Input() currentRole = '';
  @Input() eventId = '';
  @Input() projectCode = '';
  @Input() displayRowData: TrialOrderDetails[] = [];
  @Input() eventDetailsFlags: AttributeDetails = {
    trialEventName: '',
    displayPOTrackingNumber: '',
    showEdit: '',
    isAllEvents: '',
    isEventDeleted: '',
  };

  unChangeMessage = 'From Trial Order, do NOT change this field.';
  supShipDateMessage =
    'MM/DD/YYYY format, Date parts are due at NAMC. Do NOT change this field.';
  supPickupDateMessage =
    'MM/DD/YYYY format, Date parts are planned to be picked up at supplier. Do NOT change this field.';
  labelRequestMessage = 'From Label Request, do NOT change this field.';
  bufferType = 'application/octet-stream';
  trialOrderUnchangeMessage = 'From Trial Order, do NOT change this field.';
  orderId!: string;
  @Output() supplierCommentEmit: EventEmitter<SupplierCommentsActionList> =
    new EventEmitter<SupplierCommentsActionList>();
  @Input() selectedOrder: TrialOrderDetails[] = [];
  limit = 4200;
  @Input() rowCount!: number;
  @Input() screen!: string;
  trialOrderDetails: TrialOrderDetails[] = [];
  start = 1;
  userId!: string;
  selectedRoles: SelectedRole | null = null;
  mode = '';

  constructor(
    private readonly exportService: ExportOrdersService,
    private readonly trialOrderService: TrialOrdersService,
    public dialog: MatDialog,
    private readonly ngxUiLoaderService: NgxUiLoaderService,

    private readonly eciService: TrialOrdersService,
    private readonly userService: UserService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.userService.getUserDetails()?.subscribe((response: UserResponse) => {
      this.userId = response.userDetails.userId;
    });
    this.userService.selectedRoles?.subscribe((selectedRoles) => {
      this.selectedRoles = selectedRoles;
    });
  }

  openConfirmSingleOrder() {
    if (this.selectedOrder.length === 1) {
      const data = JSON.stringify(this.selectedOrder[0]);
      if (sessionStorage.getItem('eventId') === '-1') {
        this.router.navigate(
          ['home/quicklinkordersupplier/confirmSupplierOrder'],
          {
            state: { data: data, screen: this.screen },
          }
        );
      } else {
        this.router.navigate(
          ['event-order/trial-orders/confirmSupplierOrder'],
          {
            state: { data: data, screen: this.screen },
          }
        );
      }
    } else if (this.selectedOrder.length === 0 || !this.selectedOrder) {
      this.errorMessage = 'YOU NEED TO SELECT AT LEAST ONE ORDER';
      this.settingTimeOut();
    } else {
      this.errorMessage =
        'YOU NEED TO SELECT ONE ORDER FOR THE SELECTED OPERATION';
      this.settingTimeOut();
    }
  }

  openConfirmDialog() {
    if (this.selectedOrder.length === 0) {
      this.errorMessage = 'YOU NEED TO SELECT AT LEAST ONE ORDER';
      this.settingTimeOut();
      return;
    }
    const dialogRef = this.dialog.open(ConfirmSelectedOrdersComponent, {
      width: '400px',
      data: {
        selectedOrder: this.selectedOrder,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.supplierCommentEmit.emit({ message: '', success: true });
      this.customizederrorMessage =
        Object.keys(result).length > 1 ? result.error : Object.values(result);
      this.customizedSuccessMessage = result.success ?? [];
      setTimeout(() => {
        this.customizederrorMessage.length = 0;
        this.customizedSuccessMessage.length = 0;
      }, 7000);
    });
  }

  close() {
    this.customizedSuccessMessage.length = 0;
    this.customizederrorMessage.length = 0;
  }

  supplierCommentsValidation() {
    if (this.selectedOrder.length === 0) {
      this.errorMessage = 'YOU NEED TO SELECT AT LEAST ONE ORDER';
      this.settingTimeOut();
    } else if (this.selectedOrder.length > 1) {
      this.errorMessage =
        'YOU NEED TO SELECT ONE ORDER FOR THE SELECTED OPERATION';
      this.settingTimeOut();
    } else if (this.selectedOrder[0].status.toLowerCase() === 'cancelled') {
      this.errorMessage =
        'Supplier Comment cannot be updated for Order Status Cancelled.';
      this.settingTimeOut();
    } else {
      this.orderId = this.selectedOrder[0].orderNumber;
      this.openSupplierComments(this.selectedOrder);
    }
  }

  openSupplierComments(selectedOrder: TrialOrderDetails[]) {
    this.successMessage = undefined;
    this.errorMessage = undefined;
    const dialogRef = this.dialog.open(SupplierCommentsActionComponent, {
      disableClose: true,
      width: '570px',
      height: '309px',
      data: {
        selectedOrder,
      },
    });

    dialogRef.afterClosed()?.subscribe((result: string | undefined) => {
      if (result === undefined) {
        return;
      }
      if (result === 'Success') {
        this.supplierCommentEmit.emit({
          success: true,
          message: `Supplier Comment for '${this.orderId}' Updated successfully.`,
        });
      } else {
        this.supplierCommentEmit.emit({
          success: false,
          message: `Supplier Comment for '${this.orderId}' failed to update.`,
        });
      }
      this.selectedOrder = [];
    });
  }

  openFilterDialog() {
    this.dialog.open(FiltersComponent, {
      width: '800px',
      data: {
        isSupplier: true,
        OriginalColumns: [
          {
            value: 'ORDER STATUS',
            label: 'ORDER STATUS',
          },
          {
            value: 'ORDER TRACKING NO',
            label: 'ORDER TRACKING NO',
          },
          {
            value: 'ORDER TYPE',
            label: 'ORDER TYPE',
          },
          {
            value: 'PO Tracking No',
            label: 'PO Tracking No',
          },
          {
            value: 'SAP Status',
            label: 'SAP Status',
          },
          {
            value: 'EVENT NAME',
            label: 'EVENT NAME',
          },
          {
            value: 'PROJECT',
            label: 'PROJECT',
          },
          {
            value: 'PSMS ROUTING',
            label: 'PSMS ROUTING',
          },
          {
            value: 'SPECIALIST NAME',
            label: 'SPECIALIST NAME',
          },
          {
            value: 'SUPPLIER CODE',
            label: 'SUPPLIER CODE',
          },
          {
            value: 'KANBAN NUMBER',
            label: 'KANBAN NUMBER',
          },
          {
            value: 'PART NUMBER',
            label: 'PART NUMBER',
          },
          {
            value: 'PART NAME',
            label: 'PART NAME',
          },
          {
            value: 'REVISION',
            label: 'REVISION',
          },
          {
            value: 'NAMC DUE DATE',
            label: 'NAMC DUE DATE',
          },
          {
            value: 'SUPPLIER PICKUP DATE',
            label: 'SUPPLIER PICKUP DATE',
          },
          {
            value: 'SUPPLIER SHIP DATE',
            label: 'SUPPLIER SHIP DATE',
          },
          {
            value: 'TOTAL QTY',
            label: 'TOTAL QTY',
          },
          {
            value: 'SUPPLIER NAME',
            label: 'SUPPLIER NAME',
          },
          {
            value: 'NAMC COMMENT',
            label: 'NAMC COMMENT',
          },
          {
            value: 'SUPPLIER COMMENT',
            label: 'SUPPLIER COMMENT',
          },
          {
            value: 'STORE ADDRESS',
            label: 'STORE ADDRESS',
          },
          {
            value: 'LINE SIDE ADDRESS',
            label: 'LINE SIDE ADDRESS',
          },
          {
            value: 'NAMC CONTACT',
            label: 'NAMC CONTACT',
          },
          {
            value: 'DOCK CODE',
            label: 'DOCK CODE',
          },
          {
            value: 'PALLET CODE',
            label: 'PALLET CODE',
          },
          {
            value: 'MANIFEST NUMBER',
            label: 'MANIFEST NUMBER',
          },
          {
            value: 'RECEIVER NUMBER',
            label: 'RECEIVER NUMBER',
          },
          {
            value: 'NA PIPE STATUS',
            label: 'NA PIPE STATUS',
          },
          {
            value: 'ECI NUMBER',
            label: 'ECI NUMBER',
          },
          {
            value: 'ECI REVISION',
            label: 'ECI REVISION',
          },
          {
            value: 'SPECIALIST CODE',
            label: 'SPECIALIST CODE',
          },
          {
            value: 'SHIPPING DATE',
            label: 'SHIPPING DATE',
          },
          {
            value: 'TRAILER NUMBER',
            label: 'TRAILER NUMBER',
          },
          {
            value: 'SHIPPING CARRIER',
            label: 'SHIPPING CARRIER',
          },
          {
            value: 'TRACKING/AIRLBILL NO',
            label: 'TRACKING/AIRLBILL NO',
          },
          {
            value: 'LIFE CYCLE CODE',
            label: 'LIFE CYCLE CODE',
          },
          {
            value: 'WORK ORDER NUMBER',
            label: 'WORK ORDER NUMBER',
          },
          {
            value: 'PO LINE NUMBER',
            label: 'PO LINE NUMBER',
          },
          {
            value: 'SUPPLIER SHIP DATE CHANGE REQUEST',
            label: 'SUPPLIER SHIP DATE CHANGE REQUEST',
          },
          {
            value: 'SUPPLIER SHIP DATE CHANGE COMMENT',
            label: 'SUPPLIER SHIP DATE CHANGE COMMENT',
          },
          {
            value: 'ARIBA PR NO',
            label: 'ARIBA PR NO',
          },
          {
            value: 'ARIBA ORDER STATUS',
            label: 'ARIBA ORDER STATUS',
          },
          {
            value: 'ARIBA ORDER COMMENT',
            label: 'ARIBA ORDER COMMENT',
          },
        ],
      },
    });
  }

  saveColumns(updateStorage: boolean) {
    const subscription = this.trialOrderService.supplierValue$.subscribe(
      (value: { label: string; value: string }[]) => {
        if (updateStorage) {
          localStorage.setItem('supplier-columns-saved', JSON.stringify(value));
          updateStorage = false;
          subscription?.unsubscribe();
        }
      }
    );
    this.successMessage = 'Preferences Have Been Successfully saved';
    setTimeout(() => {
      this.successMessage = undefined;
    }, 3000);
  }

  exportSupplierOrders() {
    if (this.screen === 'TrialOrder') {
      this.exportTrialOrderData();
    } else {
      this.exportQuicklinksOrderData();
    }
  }

  exportTrialOrderData() {
    const roleDetails = JSON.parse(
      sessionStorage.getItem('roleDetails') ?? '{}'
    ) as SelectedRole;
    this.ngxUiLoaderService.start('getTrialOrdersData');
    const control: number[] = [];
    for (let i = 1; i <= Math.ceil(this.rowCount / 4200); i++) {
      control.push(1);
      this.trialOrderService
        .getGridData(this.eventId, this.start, this.limit, roleDetails)
        ?.subscribe({
          next: (result) => {
            this.trialOrderDetails = [
              ...this.trialOrderDetails,
              ...result.trialOrderData,
            ];
            control.pop();
            if (control.length === 0) {
              this.ngxUiLoaderService.stop('getTrialOrdersData');
              this.exportService.setExportOrderDetails(
                this.trialOrderDetails,
                this.currentRole,
                this.eventDetailsFlags.displayPOTrackingNumber,
                this.eventId
              );
            }
          },
          error: (err) => {
            this.ngxUiLoaderService.stop('getTrialOrdersData');
            const error = err as HttpErrorResponse;
            this.errorMessage = error.error.error;
            this.settingTimeOut();
          },
        });
      this.start = this.limit + 1;
      this.limit = this.limit + 4200;
    }
  }

  exportQuicklinksOrderData() {
    this.trialOrderDetails = this.displayRowData;
    this.ngxUiLoaderService.start('getQuickLinksOrdersData');
    this.exportService.setExportOrderDetailsQuickLinksSupplier(
      this.trialOrderDetails
    );
    this.ngxUiLoaderService.stop('getQuickLinksOrdersData');
  }

  settingTimeOut() {
    setTimeout(() => {
      this.errorMessage = undefined;
      this.successMessage = undefined;
    }, 5000);
  }
  openTemplateShipping() {
    this.eciService.downloadShippingFile(Number(this.eventId)).subscribe({
      next: (result: ShippingApiResponse): void => {
        const excelHeaders: ExcelHeader[] = result.excelHeaders;
        const trialOrderData: TrialOrderdata[] = result.trialOrderData;
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');
        const headers = excelHeaders.map((header) => header.attrib_val_txt);
        worksheet.addRow(headers);
        const headerNotesMap: { [key: string]: string } = {
          'ORDER TRACKING NO': this.unChangeMessage,
          'SUPPLIER CODE': this.unChangeMessage,
          'KANBAN NO': this.unChangeMessage,
          'PART NO': this.unChangeMessage,
          'PART NAME': this.unChangeMessage,
          'NAMC DUE DATE': this.supShipDateMessage,
          'SUP PICKUP DATE': this.supPickupDateMessage,
          'SUP SHIP DATE': this.supShipDateMessage,
          'TOTAL QTY': this.unChangeMessage,
          'NUMBER OF FULL BOXES': this.labelRequestMessage,
          'QTY PER FULL BOX': this.labelRequestMessage,
          'NUMBER OF PARTIAL BOXES': this.labelRequestMessage,
          'QTY PER PARTIAL BOX': this.labelRequestMessage,
          'TOTAL NUMBER OF BOXES': this.labelRequestMessage,
          'ECI NUMBER': 'From Label Request,do NOT change this field.',
          'ECI REVISION': 'From Label Request,do NOT change this field.',
          'SHIPPING DATE':
            'MM/DD/YYYY format, Date parts physically shipped from Supplier.',
          'TRAILER NUMBER':
            'Trailer Number up to 10 characters, enter trailer number from pickup, no dashes.',
          'SHIPPING CARRIER':
            'Enter Carrier Name trial parts were picked up by.',
          'IS SHIPPING DETAIL PROVIDED':
            'Answer YES or NO.YES means you are ready to make the Shiping Details.NO means you are not ready,but will submit at a later date.',
          'TRACKING/AIRBILL NUMBER':
            'Optional upto 30 characters,enter Tracking Number if shipping by UPS/Fed EX/USPS,or Airbill number if shipping by Air.',
        };
        const headerToKeyMap: {
          [key: string]: keyof TrialOrderdata | 'quantityValues';
        } = {
          'ORDER TRACKING NO': 'orderNumber',
          'SUPPLIER CODE': 'supplierCd',
          'KANBAN NO': 'kanbanNumber',
          'PART NO': 'partNumber',
          'PART NAME': 'partName',
          'NAMC DUE DATE': 'namcDueDate',
          'SUP PICKUP DATE': 'supplierPickupDate',
          'SUP SHIP DATE': 'supplierShippingDate',
          'TOTAL QTY': 'totalQty',
          'NUMBER OF FULL BOXES': 'nofullBoxes',
          'QTY PER FULL BOX': 'qtyperFullbox',
          'NUMBER OF PARTIAL BOXES': 'noPartialBoxes',
          'QTY PER PARTIAL BOX': 'qtyperPartialbox',
          'TOTAL NUMBER OF BOXES': 'totalnoBoxes',
          'ECI NUMBER': 'eciNumber',
          'ECI REVISION': 'eciRevision',
          'SHIPPING DATE': 'shippingDate',
          'TRAILER NUMBER': 'trailerNumber',
          'SHIPPING CARRIER': 'shippingCarrier',
          'IS SHIPPING DETAIL PROVIDED': 'isShippingDetailProvided',
          'TRACKING/AIRBILL NUMBER': 'trackingairbillNumber',
        };
        trialOrderData.forEach((order) => {
          const rowData = excelHeaders.map((header) => {
            const headerText = header.attrib_val_txt.trim().toUpperCase();
            const key = headerToKeyMap[headerText];
            if (key) {
              return order[key] ?? '';
            }
            if (order.quantityValues && Array.isArray(order.quantityValues)) {
              const qtyItem = order.quantityValues.find(
                (item: any) =>
                  item.quantityName.trim().toUpperCase() === headerText
              );
              return qtyItem ? qtyItem.orderQuantity : 0;
            }
            return '';
          });

          worksheet.addRow(rowData);
        });
        excelHeaders.forEach((header, index) => {
          const cell = worksheet.getCell(1, index + 1);
          cell.value = header.attrib_val_txt;

          cell.font = { bold: true, size: 10 };
          cell.alignment = { vertical: 'top', horizontal: 'left' };

          const note =
            headerNotesMap[header.attrib_val_txt] || this.unChangeMessage;
          cell.note = note;
          if (index < headers.indexOf('SHIPPING DATE')) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFC0C0C0' },
            };
          }
          worksheet.eachRow((row, rowIndex) => {
            row.eachCell((cell, colIndex) => {
              let maxLength = 10; // Set minimum width
              const cellValue = cell.value ? cell.value.toString() : '';
              maxLength = Math.max(maxLength, cellValue.length + 2);

              if (
                !worksheet.getColumn(colIndex).width ||
                worksheet.getColumn(colIndex).width! < maxLength
              ) {
                worksheet.getColumn(colIndex).width = maxLength;
              }
            });
            if (rowIndex > 1) {
              const dataCell = row.getCell(index + 1);
              if (index < headers.indexOf('SHIPPING DATE')) {
                dataCell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'FFC0C0C0' },
                };
              }

              dataCell.border = {
                top: { style: 'thin', color: { argb: 'FFC0C0C0' } },
                left: { style: 'thin', color: { argb: 'FFC0C0C0' } },
                bottom: { style: 'thin', color: { argb: 'FFC0C0C0' } },
                right: { style: 'thin', color: { argb: 'FFC0C0C0' } },
              };

              dataCell.alignment = { vertical: 'top', horizontal: 'left' };
            }
          });
        });
        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], { type: this.bufferType });
          const now = new Date();
          const currentDate = `${(now.getMonth() + 1)
            .toString()
            .padStart(2, '0')}${now
            .getDate()
            .toString()
            .padStart(2, '0')}${now.getFullYear()}`;

          const currentTime = `${now
            .getHours()
            .toString()
            .padStart(2, '0')}${now
            .getMinutes()
            .toString()
            .padStart(2, '0')}${now.getSeconds().toString().padStart(2, '0')}`;

          const fileName = `ShippingTemplate_${this.projectCode}_${currentDate}_${currentTime}.xlsx`;
          saveAs(blob, fileName);
        });
      },
    });
  }

  openTemplateLabel() {
    this.eciService.downloadLabelFile(Number(this.eventId)).subscribe({
      next: (result: LabelApiResponse): void => {
        const excelHeaders: ExcelHeader[] = result.excelHeaders;
        const trialOrderData: TrialOrder[] = result.trialOrderData;
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');
        const headers = excelHeaders.map((header) => header.attrib_val_txt);
        worksheet.addRow(headers);

        const headerNotesMap: { [key: string]: string } = {
          'ORDER TRACKING NO': this.unChangeMessage,
          'SUPPLIER CODE': this.unChangeMessage,
          'KANBAN NO': this.unChangeMessage,
          'PART NO': this.unChangeMessage,
          'PART NAME': this.unChangeMessage,
          'NAMC DUE DATE': this.supShipDateMessage,
          'SUP PICKUP DATE': this.supPickupDateMessage,
          'SUP SHIP DATE':
            'MM/DD/YYYY format, Date parts agreed are due to ship from Supplier. Do NOT change this field.',
          'TOTAL QTY': this.unChangeMessage,
          'NUMBER OF FULL BOXES':
            'Required, Enter the number of Full boxes requested only.',
          'QTY PER FULL BOX':
            'Required, Enter the QPC per container for full boxes.',
          'NUMBER OF PARTIAL BOXES':
            'Optional, Enter the No of Partial boxes requested only (Should only be one box, unless splitting shipments).',
          'QTY PER PARTIAL BOX':
            'Optional, Enter the QPC per container for partial boxes.',
          'TOTAL NUMBER OF BOXES':
            'Auto calculated, Enter the total number of boxes, both full and partial being requested.',
          'IS LABEL DETAIL PROVIDED':
            'ready to make the Label Request to Print. NO means you are not ready, but will submit at a later date.',
        };

        const headerToKeyMap: {
          [key: string]: keyof TrialOrder | 'quantityData';
        } = {
          'ORDER TRACKING NO': 'orderNumber',
          'SUPPLIER CODE': 'supplierCd',
          'KANBAN NO': 'kanbanNumber',
          'PART NO': 'partNumber',
          'PART NAME': 'partName',
          'NAMC DUE DATE': 'namcDueDate',
          'SUP PICKUP DATE': 'supplierPickupDate',
          'SUP SHIP DATE': 'supplierShippingDate',
          'TOTAL QTY': 'totalQty',
          'NUMBER OF FULL BOXES': 'nofullBoxes',
          'QTY PER FULL BOX': 'qtyperFullbox',
          'NUMBER OF PARTIAL BOXES': 'noPartialBoxes',
          'QTY PER PARTIAL BOX': 'qtyperPartialbox',
          'TOTAL NUMBER OF BOXES': 'totalnoBoxes',
          'IS LABEL DETAIL PROVIDED': 'isLabelDetailProvided',
        };

        trialOrderData.forEach((order, rowIndex) => {
          const rowData = excelHeaders.map((header) => {
            const headerText = header.attrib_val_txt.trim().toUpperCase();
            const key = headerToKeyMap[headerText];

            if (key) {
              return order[key] ?? '';
            }
            if (order.quantityData && Array.isArray(order.quantityData)) {
              const qtyItem = order.quantityData.find(
                (item: any) => item.qtyName.trim().toUpperCase() === headerText
              );
              return qtyItem ? qtyItem.orderQty : 0;
            }
            return '';
          });

          const newRow = worksheet.addRow(rowData);
          const fullBoxColIndex = headers.indexOf('NUMBER OF FULL BOXES') + 1;
          const partialBoxColIndex =
            headers.indexOf('NUMBER OF PARTIAL BOXES') + 1;
          const totalBoxColIndex = headers.indexOf('TOTAL NUMBER OF BOXES') + 1;

          if (
            fullBoxColIndex > 0 &&
            partialBoxColIndex > 0 &&
            totalBoxColIndex > 0
          ) {
            const fullBoxColLetter =
              worksheet.getColumn(fullBoxColIndex).letter;
            const partialBoxColLetter =
              worksheet.getColumn(partialBoxColIndex).letter;
            newRow.getCell(totalBoxColIndex).value = {
              formula: `${fullBoxColLetter}${
                rowIndex + 2
              } + ${partialBoxColLetter}${rowIndex + 2}`,
            };
          }
        });

        excelHeaders.forEach((header, index) => {
          const cell = worksheet.getCell(1, index + 1);
          cell.value = header.attrib_val_txt;
          cell.font = { bold: true, size: 10 };
          cell.alignment = { vertical: 'top', horizontal: 'left' };

          const note =
            headerNotesMap[header.attrib_val_txt] || this.unChangeMessage;
          cell.note = note;

          if (index <= headers.indexOf('TOTAL QTY')) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFC0C0C0' },
            };
          }

          worksheet.eachRow((row, rowIndex) => {
            if (rowIndex > 1) {
              const dataCell = row.getCell(index + 1);
              if (index <= headers.indexOf('TOTAL QTY')) {
                dataCell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'FFBFBFBF' },
                };
              }
              dataCell.border = {
                top: { style: 'thin', color: { argb: 'FFBFBFBF' } },
                left: { style: 'thin', color: { argb: 'FFBFBFBF' } },
                bottom: { style: 'thin', color: { argb: 'FFBFBFBF' } },
                right: { style: 'thin', color: { argb: 'FFBFBFBF' } },
              };
              dataCell.alignment = { vertical: 'top', horizontal: 'left' };
            }
          });
        });

        // Auto-adjust column widths
        worksheet.columns = headers.map((header, index) => ({
          key: header,
          width: Math.max(
            header.length + 2,
            ...trialOrderData.map((order) => {
              const key = headerToKeyMap[header.toUpperCase()];
              const value = key ? (order[key] ?? '').toString() : '';
              return value.length + 2;
            })
          ),
        }));

        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], { type: this.bufferType });
          const now = new Date();
          const currentDate = `${(now.getMonth() + 1)
            .toString()
            .padStart(2, '0')}${now
            .getDate()
            .toString()
            .padStart(2, '0')}${now.getFullYear()}`;
          const currentTime = `${now
            .getHours()
            .toString()
            .padStart(2, '0')}${now
            .getMinutes()
            .toString()
            .padStart(2, '0')}${now.getSeconds().toString().padStart(2, '0')}`;
          const fileName = `LabelRequestTemplate_${this.projectCode}_${currentDate}_${currentTime}.xlsx`;
          saveAs(blob, fileName);
        });
      },
    });
  }
  openTemplateECI() {
    this.eciService.downloadEciFile(Number(this.eventId)).subscribe({
      next: (result: EciApiResponse): void => {
        const excelHeaders: ExcelHeader[] = result.excelHeaders;
        const trialOrderData: TrialOrderDatas[] = result.trialOrderData;
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');
        const headers = excelHeaders.map((header) => header.attrib_val_txt);
        worksheet.addRow(headers);

        const headerNotesMap: { [key: string]: string } = {
          'ORDER TRACKING NO': this.unChangeMessage,
          'SUPPLIER CODE': this.unChangeMessage,
          'KANBAN NO': this.unChangeMessage,
          'PART NO': this.unChangeMessage,
          'PART NAME': this.unChangeMessage,
          'NAMC DUE DATE': this.supShipDateMessage,
          'SUP PICKUP DATE': this.supPickupDateMessage,
          'SUP SHIP DATE': this.supShipDateMessage,
          'TOTAL QTY': this.unChangeMessage,
          'ECI NUMBER': 'Required, ECI Number is 9 digits.',
          'ECI REVISION':
            'Required, ECI Revision is one or two digits, 0 is not accepted.',
        };

        const headerToKeyMap: {
          [key: string]: keyof TrialOrderDatas | 'qtyValues';
        } = {
          'ORDER TRACKING NO': 'orderNumber',
          'SUPPLIER CODE': 'supplierCd',
          'KANBAN NO': 'kanbanNumber',
          'PART NO': 'partNumber',
          'PART NAME': 'partName',
          'NAMC DUE DATE': 'orderDueDate',
          'SUP PICKUP DATE': 'supplierPickupDate',
          'SUP SHIP DATE': 'promiseDate',
          'TOTAL QTY': 'totalQty',
          'ECI NUMBER': 'eciNumber',
          'ECI REVISION': 'eciRevision',
        };

        trialOrderData.forEach((order) => {
          const rowData = excelHeaders.map((header) => {
            const headerText = header.attrib_val_txt.trim().toUpperCase();
            const key = headerToKeyMap[headerText];

            if (key) {
              return order[key] ?? '';
            }

            if (order.qtyValues && Array.isArray(order.qtyValues)) {
              const qtyItem = order.qtyValues.find(
                (item: any) => item.qtyName.trim().toUpperCase() === headerText
              );
              return qtyItem ? qtyItem.qtyOrder : 0;
            }
            return '';
          });

          worksheet.addRow(rowData);
        });

        excelHeaders.forEach((header, colIndex) => {
          const headerCell = worksheet.getCell(1, colIndex + 1);
          headerCell.value = header.attrib_val_txt;
          headerCell.font = { bold: true, size: 10 };
          headerCell.alignment = { vertical: 'top', horizontal: 'left' };

          if (
            header.attrib_val_txt === 'ECI NUMBER' ||
            header.attrib_val_txt === 'ECI REVISION'
          ) {
            headerCell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFFFFFFF' },
            };
            worksheet.getColumn(colIndex + 1).numFmt = '@';
          } else {
            headerCell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFBFBFBF' },
            };
          }

          const note =
            headerNotesMap[header.attrib_val_txt] || this.unChangeMessage;
          headerCell.note = note;

          worksheet.eachRow((row, rowIndex) => {
            if (rowIndex > 1) {
              const dataCell = row.getCell(colIndex + 1);
              if (
                header.attrib_val_txt === 'ECI NUMBER' ||
                header.attrib_val_txt === 'ECI REVISION'
              ) {
                dataCell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'FFFFFFFF' },
                };
              } else {
                dataCell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'FFBFBFBF' },
                };
              }
              dataCell.border = {
                top: { style: 'thin', color: { argb: 'FFBFBFBF' } },
                left: { style: 'thin', color: { argb: 'FFBFBFBF' } },
                bottom: { style: 'thin', color: { argb: 'FFBFBFBF' } },
                right: { style: 'thin', color: { argb: 'FFBFBFBF' } },
              };
              dataCell.alignment = { vertical: 'top', horizontal: 'left' };
            }
          });
        });

        // Auto adjust column widths
        worksheet.columns = excelHeaders.map((header, colIndex) => {
          const headerText = header.attrib_val_txt.trim();
          const maxContentWidth = trialOrderData.reduce((maxWidth, order) => {
            const key = headerToKeyMap[headerText.toUpperCase()];
            let cellValue = key ? order[key] : '';

            if (!cellValue && order.qtyValues) {
              const qtyItem = order.qtyValues.find(
                (item: any) =>
                  item.qtyName.trim().toUpperCase() === headerText.toUpperCase()
              );
              cellValue = qtyItem ? qtyItem.qtyOrder : '';
            }

            return Math.max(
              maxWidth,
              cellValue ? cellValue.toString().length : 0
            );
          }, headerText.length);

          return {
            header: headerText,
            key: headerText,
            width: maxContentWidth + 2, // Add padding
          };
        });

        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], { type: this.bufferType });
          const now = new Date();

          // Format date as MMDDYYYY
          const currentDate = `${(now.getMonth() + 1)
            .toString()
            .padStart(2, '0')}${now
            .getDate()
            .toString()
            .padStart(2, '0')}${now.getFullYear()}`;

          // Format time as HHMM
          const currentTime = `${now
            .getHours()
            .toString()
            .padStart(2, '0')}${now
            .getMinutes()
            .toString()
            .padStart(2, '0')}${now.getSeconds().toString().padStart(2, '0')}`;

          const fileName = `EciDataTemplate_${this.projectCode}_${currentDate}_${currentTime}.xlsx`;

          saveAs(blob, fileName);
        });
      },
    });
  }

  openShipChangeDialog() {
    if (this.selectedOrder.length === 0) {
      this.supplierCommentEmit.emit({
        success: false,
        message: `Please, Select At Least One Order`,
      });
      return;
    }
    if (this.selectedOrder.length > 1) {
      this.supplierCommentEmit.emit({
        success: false,
        message: `YOU NEED TO SELECT ONLY ONE ORDER`,
      });
      return;
    }
    const dialogRef = this.dialog.open(SupplierShipDateComponent, {
      width: '400px',
      data: 'fromSupplier',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.status) {
        const payloadObj = this.getConstructedPayloadShippingDate(
          result.result
        );
        this.ngxUiLoaderService.start('Loader-01');
        this.trialOrderService.sendSupplierSendRequest(payloadObj).subscribe({
          next: (res: any) => {
            this.ngxUiLoaderService.stop('Loader-01');
            if (res.result === 'Success' || res.status) {
              this.supplierCommentEmit.emit({
                success: true,
                message: `Orders Have Been Successfully Updated`,
              });
            } else {
              this.supplierCommentEmit.emit({
                success: false,
                message: res.errorList.join('\n'),
              });
            }
            this.selectedOrder.length = 0;
          },
          error: (err) => {
            this.ngxUiLoaderService.stop('Loader-01');
            this.supplierCommentEmit.emit({
              success: false,
              message:
                err.status === 422
                  ? err.error.errorList.toString()
                  : 'Something Went Wrong, Please Contact Sytem Administrator',
            });
          },
        });
      }
    });
  }
  viewTrails() {
    this.mode = 'view';
    if (this.selectedOrder.length === 1) {
      const data = JSON.stringify(this.selectedOrder[0]);
      sessionStorage.setItem('gridData', data);
      sessionStorage.setItem('mode', this.mode);
      if (this.eventId === '-1')
        this.router.navigate(['home/quicklinkordersupplier/viewTrials']);
      else
        this.router.navigate([
          'event-order/trial-orders/supplierTrialOrderList/viewTrials',
        ]);
    } else if (this.selectedOrder.length === 0 || !this.selectedOrder) {
      this.errorMessage = 'YOU NEED TO SELECT AT LEAST ONE ORDER';
      this.settingTimeOut();
    } else {
      this.errorMessage =
        'YOU NEED TO SELECT ONE ORDER FOR THE SELECTED OPERATION';
      this.settingTimeOut();
    }
  }

  getConstructedPayloadShippingDate(result: {
    supplierRqstedDate: string;
    supplierRequestedComment: string;
  }) {
    return this.selectedOrder.map((e) => {
      return {
        appendShippingDateTime: 'defVal',
        appendOrderNumbers: e.orderNumber,
        appendEciNumber: 'defVal',
        appendEciRevision: 'defVal',
        userName: this.userId,
        appendTrailerNumber: 'defVal',
        appendShippingCarrier: 'defVal',
        appendComment: 'defVal',
        appendTrackingAirbillNumber: 'defVal',
        appendSuppShpDtChng: result.supplierRqstedDate,
        appendSuppShpDtCmmnt: result.supplierRequestedComment,
      };
    });
  }
}
