import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CreateTrailsService } from 'src/app/services/event-and-orders/create-trails.service';

import { MatDialog } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { WarningPopupComponent } from 'src/app/core/dialogs/warning-popup/warning-popup.component';
import { SupplierListMap } from 'src/app/core/model/supplierList.model';
import { AppendOrderModel } from 'src/app/core/model/trial-order.model';
import { PerformanceService } from 'src/app/services/suppliers/performance.service';
import { UserService } from 'src/app/services/user.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-append-trails',
  templateUrl: './append-trails.component.html',
  styleUrls: ['./append-trails.component.scss'],
})
export class AppendTrailsComponent implements OnInit {
  defaultQuantities: string[] = [];
  isAppendDisabled = false;
  constructor(
    private readonly ngxUiLoaderService: NgxUiLoaderService,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly datePipe: DatePipe,
    private readonly performanceService: PerformanceService,
    private readonly userService: UserService,
    private readonly CreateTrailsService: CreateTrailsService
  ) { }
  supplierCodes: SupplierListMap[] = [];
  numOfOrders = 0;
  createdBy!: string;
  orders: any[] = [];
  response: AppendOrderModel = {} as AppendOrderModel;
  eventId = '';
  isFieldsFilled = false;
  showError = false;
  orderOptions = Array.from({ length: 30 }, (_, i) => i + 1);
  currentNamc = '';
  eventtypeid = 0;
  errorMessage: string | undefined = undefined;
  userId!: string;

  appendOrders() {
    if (this.numOfOrders > 0) {
      this.showError = false;
      this.isAppendDisabled=true;
      const baseOrder = {
        poTrackingNumber: '',
        psmsRouting: '',
        supplierCode: '',
        supplierName: '',
        kanbanNo: '',
        partNumber: '',
        partName: '',
        orderDeliveryDate: '',
        orderPickupDate: '',
        totalQuantity: '',
        sendToSapFlag: '',
        lifeCycleCode: '',
        plant1Qty: '',
        plant2Qty: '',
        plant3Qty: '',
        comment: '',
        store: '',
        lineSide: '',
        palletCode: '',
        workOrderNumber: '',
        supplierCodeError: false,
        supplierNameError: false,
        partNumberError: false,
        partNameError: false,
        orderDeliveryDateError: false,
        orderPickupDateError: false,
        totalQuantityError: false,
        totalQuantityMismatch: false,
        pickupDateError: false,
        deliveryDateError: false,
        invalidDateError: false,
        partNumberLengthError: false,
        pickupDateLError: false,
        invalidPickupDateError: false,
        invalidDeliveryDateError: false,
      };
      this.orders = Array.from({ length: this.numOfOrders }, (_, index) => ({
        ...baseOrder,
        orderNumber: `${this.response.trialOrder.orderNumber}`,
        quantities: this.defaultQuantities.map(() => ''),

      }));
    }

  }

  stopDateInput(event: KeyboardEvent) {
    event.preventDefault();
  }
 
  ngOnInit(): void {
    const roleDetails = JSON.parse(
      String(sessionStorage.getItem('roleDetails'))
    );
    this.currentNamc = roleDetails?.namc;
    console.log(this.currentNamc);
    this.eventId = String(sessionStorage.getItem('eventId'));
    this.fetchSupplierCodes();
    this.userService.userDetails?.subscribe((value) => {
      if (value !== null) {
        this.userId = value.userId;
      }
    });
    this.CreateTrailsService.getQuantityNames(this.eventId)?.subscribe(
      (res) => {
        this.response = res;
        this.eventtypeid = this.response.trialOrder.eventId.eventTypeId;
        this.defaultQuantities = res.trialEventQuantityNameLst.map(
          (element) => {
            return element.inputBox;
          }
        );
      }
    );
  }
  onKeyDown(event: KeyboardEvent) {
    const inputValue = (event.target as HTMLInputElement).value;
    const allowedCharacters = /^\d*$/;
    const char = event.key;
    const allowedSpecialKeys = [
      'Backspace',
      'Delete',
      'ArrowLeft',
      'ArrowRight',
      'ArrowUp',
      'ArrowDown',
      'Tab',
      'Enter',
      'Escape',
    ];
    if (!allowedCharacters.test(char) && !allowedSpecialKeys.includes(char)) {
      event.preventDefault();
    }
    if (inputValue.length >= 6 && !allowedSpecialKeys.includes(char)) {
      event.preventDefault();
    }
  }

  fetchSupplierCodes(): void {
    this.performanceService.suppliersDropDown()?.subscribe({
      next: (res) => {
        this.supplierCodes = res;
      },
      error: (_error: Error) => { },
    });
  }
  onSupplierCodeChange(event: Event, orderIndex: number): void {
    const selectedCode = (event as unknown as HTMLSelectElement).value;

    const selectedSupplier = this.supplierCodes.find(
      (supplier) => supplier.supplierCode === selectedCode
    );

    if (selectedSupplier) {
      this.orders[orderIndex].supplierName = selectedSupplier.supplierName;
    }
  }

  cancel() {
    const dialogRef = this.dialog.open(WarningPopupComponent, {
      disableClose: true,
      width: '570px',
      height: '219px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'Success') {
        this.router.navigate(['event-order/trial-orders/trialOrderList']);
      }
    });
  }
  getCommaSeparatedValues(key: string) {
    return this.orders
      .map((order) => {
        if (key === 'sendToSapFlag') { return 'Y' }
        else if (key === 'orderDeliveryDate' || key === 'orderPickupDate') {
          return this.datePipe.transform(
            order[key],
            'MM/dd/yyyy'
          )
        }
       else return order[key]
      }
      )
      .join(',');
  }

  onSubmit() {
    if (this.numOfOrders === 0) {
      return;
    }
    this.orders.forEach((order) => {
      order.supplierCodeError = !order.supplierCode
      order.supplierNameError = !order.supplierName;
      order.partNumberError = !order.partNumber;
      order.partNameError = !order.partName;
      order.orderDeliveryDateError = !order.orderDeliveryDate;
      order.orderPickupDateError = !order.orderPickupDate;
      order.totalQuantityError = !order.totalQuantity;
      order.partNumberLengthError = order.partNumber.length !== 12;

      const today = new Date();
      const deliveryDate = new Date(order.orderDeliveryDate);
      const pickupDate = new Date(order.orderPickupDate);

      order.deliveryDateError = deliveryDate <= today;
      order.pickupDateError = pickupDate <= today;
      order.pickupDateLError = pickupDate > deliveryDate;
      const total = this.defaultQuantities.reduce((sum, _, index) => {
        return sum + (parseInt(order.quantities[index], 10) || 0);
      }, 0);
      
      console.log(total);
      order.totalQuantityMismatch = order.totalQuantity !== total.toString();
    });

    const isValid = this.orders.every(
      (order) =>
        order.supplierCode &&
        order.supplierName &&
        order.partNumber &&
        order.partNumberLengthError === false &&
        order.partName &&
        order.orderDeliveryDate &&
        !order.invalidDeliveryDateError &&
        !order.invalidDateError &&
        !order.deliveryDateError &&
        order.orderPickupDate &&
        !order.invalidPickupDateError &&
        !order.pickupDateError &&
        !order.pickupDateLError &&
        order.totalQuantity &&
        !order.totalQuantityMismatch
    );
    if (!isValid) {
      this.showError = true;
    } else {
      const generateQuantityNames = () => {
        const quantityNames = this.response.trialEventQuantityNameLst.map(
          (element: { qNameBox: string }) => element.qNameBox
        );
        const result = Array.from({ length: this.orders.length }, () => [
          ...quantityNames,
        ]);

        return result;
      };
      this.showError = false;
      const payload = {
        eventId: this.eventId,
        appendOrderNumber: this.getCommaSeparatedValues('orderNumber'),
        appendKanbanNumber: this.getCommaSeparatedValues('kanbanNo'),
        appendSupplierCode: this.getCommaSeparatedValues('supplierCode'),
        appendSupplierNames: this.getCommaSeparatedValues('supplierName'),
        appendPartNumber: this.getCommaSeparatedValues('partNumber'),
        appendPartName: this.getCommaSeparatedValues('partName'),
        appendDeliveryDate: this.getCommaSeparatedValues('orderDeliveryDate'),
        appendPlant1Qty: this.getCommaSeparatedValues('plant1Qty'),
        appendPlant2Qty: this.getCommaSeparatedValues('plant2Qty'),
        appendWorkOrderNumber: this.getCommaSeparatedValues('workOrderNumber'),
        appendPlant3Qty: this.getCommaSeparatedValues('plant3Qty'),
        appendTotalOrderQty: this.getCommaSeparatedValues('totalQuantity'),
        appendComment: this.getCommaSeparatedValues('comment'),
        appendStore: this.getCommaSeparatedValues('store'),
        appendLineSide: this.getCommaSeparatedValues('lineSide'),
        appendPalletCode: this.getCommaSeparatedValues('palletCode'),
        appendPOTrackingNumber:
          this.getCommaSeparatedValues('poTrackingNumber'),
        appendToRoute: this.getCommaSeparatedValues('psmsRouting'),
        appendQuantitynames: generateQuantityNames(),
        appendQuantityQty: this.orders.map((order) =>
          this.defaultQuantities.map((quantity) =>
            (order[quantity] || 0).toString()
          )
        ),
        psmsFromRouting: this.response.psmsFromRouting,
        psmsToRouting: this.getCommaSeparatedValues('psmsRouting'),
        appendSupplierPickupDate:
          this.getCommaSeparatedValues('orderPickupDate'),
        appendLifeCycleCode: this.getCommaSeparatedValues('lifeCycleCode'),
        appendSendToSapFlag: this.getCommaSeparatedValues('sendToSapFlag'),
        appendSpecialistEmailAddress: '',
        calculateOrderNumber: this.response.calculateOrderNumber,
        trialOrder: {
          statusId: this.response.trialOrder.statusId,
          orderNumber: this.response.trialOrder.orderNumber,
          promiseDate: null,
          isArchiveFlag: this.response.trialOrder.isArchiveFlag,
          totalOrderQuantity: '',
          namcComent: '',
          supplierPickupDate: '',
          supplierCode: '',
          supplierName: '',
          kanbanNumber: '',
          partNumber: '',
          partName: '',
          plant1Qty: '',
          plant2Qty: '',
          plant3Qty: '',
          deliveryDueDate: '',
          manifestNumber: '',
          completionDate: '',
          supplierComment: '',
          storeAddress: '',
          lineSideAddress: '',
          poTrackingNumber: '',
          workOrderNumber: this.response.appendWorkOrderNumber,
          lifeCycleCode: this.response.appendLifeCycleCode,
          owkLabelLine1: '',
          owkLabelLine2: '',
          sendToSapFlag: '',
          aribaPrNumber: '',
          aribaOrderStatus: '',
          aribaOrderComment: '',
          poLineNo: '',
          requestForSuppShipDtChng: '',
          requestForSuppShipcmmntChng: '',
          palletCode: '',
          naPipeStatus: '',
          eventId: {
            eventId: this.eventId,
            eventTypeId: this.response.trialOrder.eventId.eventTypeId,
            namcCode: this.response.trialOrder.eventId.namcCode,
            projectCode: this.response.trialOrder.eventId.projectCode,
            eventName: this.response.trialOrder.eventId.eventName,
            partsSource: this.response.trialOrder.eventId.partsSource,
            eventDescription: this.response.trialOrder.eventId.eventDescription,
            psmsFromRouting: this.response.trialOrder.eventId.psmsFromRouting,
            psmsToRouting: this.response.trialOrder.eventId.psmsToRouting,
            shipmentLocation: this.response.trialOrder.eventId.shipmentLocation,
            shipmentAddress: this.response.trialOrder.eventId.shipmentAddress,
            shipmentCity: this.response.trialOrder.eventId.shipmentCity,
            shipmentState: this.response.trialOrder.eventId.shipmentState,
            shipmentCountry: this.response.trialOrder.eventId.shipmentCountry,
            shipmentPostalCode:
              this.response.trialOrder.eventId.shipmentPostalCode,
            dockCode: this.response.trialOrder.eventId.dockCode,
            orderNumberSeries:
              this.response.trialOrder.eventId.orderNumberSeries,
            logisticProvider: this.response.trialOrder.eventId.logisticProvider,
            shipmentScheduledTracking:
              this.response.trialOrder.eventId.shipmentScheduledTracking,
            assignQPCToTrial: this.response.trialOrder.eventId.assignQPCToTrial,
            subRouteNameOverride:
              this.response.trialOrder.eventId.subRouteNameOverride,
            mainRouteNameOverride:
              this.response.trialOrder.eventId.mainRouteNameOverride,
            storesAddressNameOverride:
              this.response.trialOrder.eventId.storesAddressNameOverride,
            carryoverPartsOrdering:
              this.response.trialOrder.eventId.carryoverPartsOrdering,
            completionDate: this.response.trialOrder.eventId.completionDate,
            packagingNeed: this.response.trialOrder.eventId.packagingNeed,
            eventStatus: this.response.trialOrder.eventId.eventStatus,
            ringiNumber: this.response.trialOrder.eventId.ringiNumber,
            isNAQD: this.response.trialOrder.eventId.isNAQD,
            trialEventQuantityNameLst: [],
          },
          createdBy: this.userId,
          createTimestamp: this.response.trialOrder.createTimestamp,
          revisionDate: this.response.trialOrder.revisionDate,
        },
        quantityNames: [],
        quantityNamesQty: [],
      };
      this.ngxUiLoaderService.start('onSubmit');
      this.CreateTrailsService.submitOrder(payload)?.subscribe({
        next: (res) => {
          this.router
            .navigateByUrl('/dummy', { skipLocationChange: true })
            .then(() => {
              this.router.navigate(
                ['event-order/trial-orders/trialOrderList'],
                {
                  state: { res, myData: this.eventId, dummy: true },
                }
              );
            });
          this.ngxUiLoaderService.stop('onSubmit');
        },
        error: (_err) => {
          this.ngxUiLoaderService.stop('onSubmit');
          this.errorMessage = 'There was an error while appending orders';
          setTimeout(() => {
            this.errorMessage = undefined;
          }, 5000);
        },
      });
    }
  }
}
