<div class="popup-container">
    <button class="close black-button" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  
    <h2 class="p-3 mb-0 roboto-medium">Cancel Order(s)</h2>
    <hr id="shadow" />
    <br />
    <div class="dialog-body">
        <p>This is Purchase Order(s). Are you sure you want to cancel
            all selected order(s)?</p>
        <p style="color: #d20000; font-size: 20px">
            Remember to cancel the order in SAP Ariba separately
        </p>
        <textarea [(ngModel)]="Comments"></textarea>
    </div>
    <div  class="dialog-actions">
      <button mat-button class="b2-button add yes-button" (click)="onYesClick()">YES</button>
      <button mat-button class="b2 me-3 ms-3 roboto-bold" (click)="closeDialog()">NO</button>
    </div>
  </div>