<app-notification-message
  [errorMessage]="errorMsg"
  [successMessage]="successMsg"
>
</app-notification-message>
<div class="row m-4">
  <div class="col-6 ps-2 pe-4">
    <div class="quick-links-container">
      <div class="links-section">
        <div class="category">
          <h4>ORDERS</h4>
          <ul>
            <li class="row-even">
              <a (click)="navigateOrderDetails('TRIAL_ORDER_STATUS_OPEN')"
                >Open Orders</a
              ><span class="pi pi-spin pi-spinner" *ngIf="!isLoading"></span
              ><span
                class="count"
                *ngIf="isLoading"
                >{{ ordersCount?.["Open"] }}</span
              >
            </li>
            <li class="row-odd">
              <a (click)="navigateOrderDetails('TRIAL_ORDER_STATUS_PDGTREQDT ')"
                >Supplier Ship Dates &gt; TMNA Request Date</a
              ><span class="pi pi-spin pi-spinner" *ngIf="!isLoading"></span
              ><span
                class="count"
                *ngIf="isLoading"
                >{{ ordersCount?.["missedPD"] }}</span
              >
            </li>
            <li class="row-even">
              <a (click)="navigateOrderDetails('TRIAL_ORDER_STATUS_PASTDUE')"
                >Orders Past Due</a
              ><span class="pi pi-spin pi-spinner" *ngIf="!isLoading"></span
              ><span
                class="count"
                *ngIf="isLoading"
                >{{ ordersCount?.["ordersPastDue"] }}</span
              >
            </li>
          </ul>
        </div>

        <div class="category">
          <h4>ORDER SUPPLIER SHIP DATE</h4>
          <ul>
            <li class="row-even">
              <a (click)="navigateOrderDetails('TRIAL_ORDER_STATUS_WOPD ')"
                >Orders w/o Supplier Ship Date</a
              ><span class="pi pi-spin pi-spinner" *ngIf="!isLoading"></span
              ><span
                class="count"
                *ngIf="isLoading"
                >{{ ordersCount?.["ordersWOPD"] }}</span
              >
            </li>
            <li class="row-odd">
              <a (click)="navigateOrderDetails('TRIAL_ORDER_STATUS_RFC')"
                >Supplier Request Alternate</a
              ><span class="pi pi-spin pi-spinner" *ngIf="!isLoading"></span
              ><span
                class="count"
                *ngIf="isLoading"
                >{{ ordersCount?.["rfc"] }}</span
              >
            </li>
            <li class="row-even">
              <a (click)="navigateOrderDetails('TRIAL_ORDER_STATUS_PDPASTDUE')"
                >Planned Supplier Ship Date Past Due</a
              ><span class="pi pi-spin pi-spinner" *ngIf="!isLoading"></span
              ><span
                class="count"
                *ngIf="isLoading"
                >{{ ordersCount?.["pdPastDue"] }}</span
              >
            </li>
            <li class="row-odd">
              <a
                (click)="
                  navigateOrderDetails('TRIAL_ORDER_STATUS_PDAPPROVALPENDING')
                "
                >Supplier Ship Dates Awaiting Approval</a
              ><span class="pi pi-spin pi-spinner" *ngIf="!isLoading"></span
              ><span
                class="count"
                *ngIf="isLoading"
                >{{ ordersCount?.["pdAwaitApproval"] }}</span
              >
            </li>
            <li class="row-even">
              <a (click)="navigateOrderDetails('TRIAL_ORDER_STATUS_PROCESSED')"
                >Supplier Ship Date Approved</a
              ><span class="pi pi-spin pi-spinner" *ngIf="!isLoading"></span
              ><span
                class="count"
                *ngIf="isLoading"
                >{{ ordersCount?.["pdApproved"] }}</span
              >
            </li>
            <li class="row-odd">
              <a (click)="navigateOrderDetails('TRIAL_ORDER_STATUS_PDREJECTED')"
                >Supplier Ship Date Rejected</a
              ><span class="pi pi-spin pi-spinner" *ngIf="!isLoading"></span
              ><span
                class="count"
                *ngIf="isLoading"
                >{{ ordersCount?.["pdRejected"] }}</span
              >
            </li>
            <li class="row-even">
              <a
                (click)="
                  navigateOrderDetails('TRIAL_ORDER_STATUS_SHIPLABELPASTDUE')
                "
                >Shipping Label Request Past Due</a
              ><span class="pi pi-spin pi-spinner" *ngIf="!isLoading"></span
              ><span class="count" *ngIf="isLoading">{{
                ordersCount?.["shipLabelReqPastDue"]
              }}</span>
            </li>
            <li class="row-odd">
              <a
                (click)="
                  navigateOrderDetails('TRIAL_ORDER_STATUS_SUPSHIPDTPASTDUE')
                "
                >Actual Supplier Ship Date Past Due</a
              ><span class="pi pi-spin pi-spinner" *ngIf="!isLoading"></span
              ><span class="count" *ngIf="isLoading">{{
                ordersCount?.["supShipDatePastDue"]
              }}</span>
            </li>
          </ul>
        </div>
        <div class="category">
          <h4>Order Status</h4>
          <ul>
            <li class="row-even">
              <a (click)="navigateOrderDetails('TRIAL_ORDER_STATUS_COMPLETED')"
                >Completed Orders</a
              ><span class="pi pi-spin pi-spinner" *ngIf="!isLoading"></span
              ><span
                class="count"
                *ngIf="isLoading"
                >{{ ordersCount?.["completed"] }}</span
              >
            </li>
            <li class="row-odd">
              <a (click)="navigateOrderDetails('TRIAL_ORDER_STATUS_AWAITPO')"
                >Await PO Orders</a
              ><span class="pi pi-spin pi-spinner" *ngIf="!isLoading"></span
              ><span
                class="count"
                *ngIf="isLoading"
                >{{ ordersCount?.["awaitpo"] }}</span
              >
            </li>
            <li class="row-even">
              <a
                (click)="
                  navigateOrderDetails('TRIAL_ORDER_STATUS_ORDERSHIPPED')
                "
                >Shipped Orders</a
              ><span class="pi pi-spin pi-spinner" *ngIf="!isLoading"></span
              ><span
                class="count"
                *ngIf="isLoading"
                >{{ ordersCount?.["shipped"] }}</span
              >
            </li>
            <li class="row-odd">
              <a (click)="navigateOrderDetails('TRIAL_ORDER_STATUS_FIRM')"
                >Firm Orders</a
              ><span class="pi pi-spin pi-spinner" *ngIf="!isLoading"></span
              ><span
                class="count"
                *ngIf="isLoading"
                >{{ ordersCount?.["firm"] }}</span
              >
            </li>
            <li class="row-even">
              <a (click)="navigateOrderDetails('TRIAL_ORDER_STATUS_PROCESSED')"
                >Orders Due for Shipping Detail</a
              ><span class="pi pi-spin pi-spinner" *ngIf="!isLoading"></span
              ><span
                class="count"
                *ngIf="isLoading"
                >{{ ordersCount?.["dueForShipDetail"] }}</span
              >
            </li>
            <li class="row-odd">
              <a (click)="navigateOrderDetails('TRIAL_ORDER_STATUS_CANCELLED')"
                >Cancelled Orders</a
              ><span class="pi pi-spin pi-spinner" *ngIf="!isLoading"></span
              ><span
                class="count"
                *ngIf="isLoading"
                >{{ ordersCount?.["cancelled"] }}</span
              >
            </li>
          </ul>
        </div>
        <div class="category" *ngIf="currentRole === 'supplier'">
          <h4>Supplier Performance</h4>
          <ul>
            <li class="row-even">
              <a (click)="navigateToPerformance()">On-time Delivery Ratio</a>
            </li>
            <li class="row-odd">
              <a>Part Non-Conformance Ratio</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="col-6 ps-3 pe-4">
    <div class="message-box">
      <div class="message-box-header">
        <h4>Message from TMNA</h4>
        <a
          (click)="openAddNewMessage()"
          *ngIf="currentRole === 'namc'"
          class="add-new"
          >ADD NEW</a
        >
      </div>
      <div
        class="dialog-body"
        [ngClass]="{ 'disabled-message-box': currentRole === 'supplier' }"
        *ngFor="let msg of msgs"
      >
        <p>{{ msg }}</p>
      </div>
    </div>
    <div class="filter-box" *ngIf="currentRole === 'namc'">
      <div class="supplier-filter">
        <div class="formalign">
          <label for="supplier-name">Supplier Code </label>
          <p-dropdown
            [options]="supplierList"
            optionLabel="supplierCode"
            optionValue="supplierCode"
            [(ngModel)]="selectedSupplierCode"
            class="dropdownOptions"
            placeholder="Select a Supplier Code"
            styleClass="w-100"
            (onChange)="supplierCodeChange()"
            [virtualScrollItemSize]="10"
            [virtualScroll]="true"
          >
          </p-dropdown>
        </div>
        <div class="formalign">
          <label for="supplier-name">Supplier Name</label>
          <p-dropdown
            [options]="supplierList"
            optionLabel="supplierName"
            optionValue="supplierName"
            [(ngModel)]="selectedSupplierName"
            class="dropdownOptions"
            placeholder="Select a Supplier Name"
            styleClass="w-100"
            (onChange)="supplierNameChange()"
            [virtualScrollItemSize]="10"
            [virtualScroll]="true"
          >
          </p-dropdown>
        </div>
        <div class="label-container">
          <div class="formalign">
            <label for="filter-by">Filter By</label>
            <p-dropdown
              [options]="filterList"
              optionLabel="filterName"
              optionValue="filterId"
              [(ngModel)]="selectedFilter"
              placeholder="Select"
              styleClass="w-100"
            >
            </p-dropdown>
          </div>
          <div class="formalign">
            <label for="value">Value</label>
            <input
              type="text"
              id="value"
              placeholder="Enter"
              [(ngModel)]="filteredValue"
              disableSpecialCharacters
            />
          </div>
        </div>

        <div class="dialog-actions">
          <button
            class="me-3 mt-2 b2-button add black-button blackbtnalign"
            (click)="getCountOfOrders()"
          >
            FILTER
          </button>
          <button
            class="b2-button export-btn blackbtnalign me-3 mt-2"
            (click)="resetFilters()"
            style="height: 38px"
          >
            RESET FILTER
          </button>
        </div>
      </div>
    </div>
    <div class="filter-box pe-2" *ngIf="currentRole === 'supplier'">
      <div class="supplier-filter">
        <div class="label-container">
          <div class="formalign">
            <label for="filter-by">Filter By</label>
            <select id="filter-by">
              <option value="select">Select</option>
            </select>
          </div>
          <div class="formalign">
            <label for="value">Value</label>
            <input type="text" id="value" placeholder="Enter" />
          </div>
        </div>

        <div class="dialog-actions">
          <button
            class="me-3 mt-2 b2-button add black-button blackbtnalign"
            (click)="getCountOfOrders()"
          >
            FILTER
          </button>
          <button
            class="b2-button export-btn blackbtnalign me-3 mt-2"
            (click)="resetFilters()"
            style="height: 38px"
          >
            RESET FILTER
          </button>
        </div>
      </div>
    </div>
    <div class="kpi-box">
      <mat-accordion class="example-headers-align" hideToggle multi>
        <mat-expansion-panel
          [expanded]="true"
          *ngIf="currentRole === 'supplier'"
          #panel8
          class="mat-elevation-z0 my-1"
        >
          <mat-expansion-panel-header class="header-container">
            <mat-panel-title class="fw-bold">SUPPLIER</mat-panel-title>
            <mat-icon class="toggleIcon">{{
              panel8.expanded ? "remove" : "add"
            }}</mat-icon>
          </mat-expansion-panel-header>
          <div>
            <p>
              All statistics for trial orders are tracked by TMNA and NAMC.
              Performance outside of the listed parameters for all trial
              activities may have negative impact on Key Performance Indicators
              (KPI). Please communicate any abnormal issues with your NAMC Trial
              contact.
            </p>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel
          [expanded]="true"
          #panel8
          class="mat-elevation-z0 my-1"
        >
          <mat-expansion-panel-header class="header-container">
            <mat-panel-title class="fw-bold">KPI STANDARDS</mat-panel-title>
            <mat-icon class="toggleIcon">{{
              panel8.expanded ? "remove" : "add"
            }}</mat-icon>
          </mat-expansion-panel-header>
          <div>
            <p>
              <b>Order Past Due:</b> Supplier has not responded to NAMC order
              request within 3 business days of receipt.
            </p>
            <p>
              <b>Supplier Ship Date Past Due:</b> Supplier has not provided
              Order Ship Date within 3 business days of Initial Order receipt.
            </p>
            <p>
              <b>Revised Orders Past Due:</b> Supplier has not responded to NAMC
              revised order request within 3 business days of receipt.
            </p>
            <p>
              <b> Supplier Shipping Labels:</b> Must be requested 10-15 days
              before NAMC Due Date. Past due is 9 days or sooner before NAMC Due
              Date.
            </p>
            <p>
              <b>Supplier Ship Date:</b> Parts must be shipped per instructed
              pickup date. Past due is 2 days past posted Supplier Pickup Date.
            </p>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
