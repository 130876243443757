import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/common/auth-guard';
import { ArchivedOrdersComponent } from './pages/events-and-orders/archived-orders/archived-orders.component';
import { EventsAndOrdersComponent } from './pages/events-and-orders/events-and-orders.component';
import { CreateEventComponent } from './pages/events-and-orders/manage-trial-events/create-event/create-event.component';
import { ManageTrialEventsComponent } from './pages/events-and-orders/manage-trial-events/manage-trial-events.component';
import { DisplayHistoryComponent } from './pages/events-and-orders/trial-orders/display-history/display-history.component';
import { DisplayOrderShippingAddressComponent } from './pages/events-and-orders/trial-orders/display-order-shipping-address/display-order-shipping-address.component';
import { DisplayTrialOrdersComponent } from './pages/events-and-orders/trial-orders/display-trial-orders/display-trial-orders.component';
import { TrialOrdersSupplierComponent } from './pages/events-and-orders/trial-orders/trial-orders-supplier/trial-orders-supplier.component';
import { TrialOrdersComponent } from './pages/events-and-orders/trial-orders/trial-orders.component';
import { UploadOrdersDataComponent } from './pages/events-and-orders/upload-orders-data/upload-orders-data.component';
import { UploadOrdersNamcComponent } from './pages/events-and-orders/upload-orders-namc/upload-orders-namc.component';
import { HomeComponent } from './pages/home/home.component';
import { ReviewShipDateComponent } from './pages/events-and-orders/trial-orders/display-trial-orders/review-ship-date/review-ship-date.component';
import { NoAccessComponent } from './pages/no-access/no-access.component';
import { RoleSelectorComponent } from './pages/role-selector/role-selector.component';
import { ContactsComponent } from './pages/supplier/contacts/contacts.component';
import { MassEmailComponent } from './pages/supplier/mass-email/mass-email.component';
import { OnlineTrainingNamcDocumentsComponent } from './pages/supplier/online-training-namc-documents/online-training-namc-documents.component';
import { LateOrderGridComponent } from './pages/supplier/performance/late-order-grid/late-order-grid.component';
import { PerformanceComponent } from './pages/supplier/performance/performance.component';
import { SupplierComponent } from './pages/supplier/supplier.component';
import { AddAttributeComponent } from './pages/tmna-namc/manage-attributes/add-attribute/add-attribute.component';
import { ManageAttributesComponent } from './pages/tmna-namc/manage-attributes/manage-attributes.component';
import { ModifyAttributeComponent } from './pages/tmna-namc/manage-attributes/modify-attribute/modify-attribute.component';
import { ManageConfigurationsComponent } from './pages/tmna-namc/manage-configurations/manage-configurations.component';
import { ModifyConfigurationComponent } from './pages/tmna-namc/manage-configurations/modify-configuration/modify-configuration.component';
import { AddOnlineTrainingComponent } from './pages/tmna-namc/manage-online-training-namc-documents/add-online-training/add-online-training.component';
import { ManageOnlineTrainingComponent } from './pages/tmna-namc/manage-online-training-namc-documents/manage-online-training/manage-online-training.component';
import { ReorderOnlineTrainingComponent } from './pages/tmna-namc/manage-online-training-namc-documents/reorder-online-training/reorder-online-training.component';
import { AddEditUserComponent } from './pages/tmna-namc/manage-users/add-edit-user/add-edit-user.component';
import { ManageUsersComponent } from './pages/tmna-namc/manage-users/manage-users.component';
import { TmnaNamcComponent } from './pages/tmna-namc/tmna-namc.component';
import { NewMessageComponent } from './pages/home/new-message/new-message.component';
import { ViewEditComponent } from './pages/events-and-orders/trial-orders/display-trial-orders/view-edit/view-edit.component';
import { AppendTrailsComponent } from './pages/events-and-orders/trial-orders/display-trial-orders/append-trails/append-trails.component';
import { ConfirmSingleOrderComponent } from './pages/events-and-orders/trial-orders/trial-orders-supplier/confirm-single-order/confirm-single-order.component';
import { CreateTrailsComponent } from './pages/events-and-orders/trial-orders/display-trial-orders/create-trails/create-trails.component';
const isAdminRole = ['IS Admin'];
const tmnaAdmin = 'TMNA Admin';
const namcAdmin = 'NAMC Admin';
const adminRole = [tmnaAdmin, namcAdmin];
const allAdminRole = [tmnaAdmin, namcAdmin, 'IS Admin'];
const allNamcRole = [
  tmnaAdmin,
  namcAdmin,
  'IS Admin',
  'PC User',
  'PE User',
  'QE User',
  'Shop User',
];
const adminWithSupplierRole = [tmnaAdmin, namcAdmin, 'Supplier']

const routes: Routes = [
  {
    path: 'select-roles',
    component: RoleSelectorComponent,
    data: { breadcrumb: 'ROLE SELECTOR' },
  },
  {
    path: 'no-access',
    component: NoAccessComponent,
    data: { breadcrumb: 'NO ACCESS' },
  },
  {
    path: 'home',
    children: [
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: 'new-message',
        component: NewMessageComponent,
        data: { breadcrumb: 'ADD NEW MESSAGE' },
      },
      {
        path: 'quicklinkordernamc',
        data: { breadcrumb: 'Quick Links' },
        children: [
          {
            path: '',
            component: DisplayTrialOrdersComponent,
            data: { breadcrumb: null },
          },
          {
            path: 'confirmOrder',
            component: ReviewShipDateComponent,
            data: { breadcrumb: 'Confirm Single Order Supplier Ship Date' },
          },
          {
            path: 'viewTrials',
            component: ViewEditComponent,
            data: { breadcrumb: 'VIEW TRIAL ORDER' },
          },
          {
            path: 'editTrials',
            component: ViewEditComponent,
            data: { breadcrumb: 'EDIT TRIAL ORDER', roles: adminRole },
            canActivate: [AuthGuard]
          },
        ],
      },
      {
        path: 'quicklinkordersupplier',
        data: { breadcrumb: 'Quick Links' },
        children: [
          {
            path: '',
            component: TrialOrdersSupplierComponent,
            data: { breadcrumb: null },
          },
          {
            path: 'confirmSupplierOrder',
            component: ConfirmSingleOrderComponent,
            data: { breadcrumb: 'Confirm Single Order Supplier Ship Date' },
          },
          {
            path: 'viewTrials',
            component: ViewEditComponent,
            data: { breadcrumb: 'VIEW TRIAL ORDER' },
          },
        ],
      },
    ],
  },
  {
    path: 'performance',
    data: { breadcrumb: 'PERFORMANCE' },
    children: [
      {
        path: '',
        component: PerformanceComponent,
        data: { breadcrumb: null },
      },
      {
        path: 'lateOrders',
        component: LateOrderGridComponent,
        data: { breadcrumb: 'LATE ORDERS' },
      },
    ],
  },
  {
    path: 'contacts',
    component: ContactsComponent,
    data: { breadcrumb: 'CONTACTS' },
  },
  {
    path: 'event-order',
    component: EventsAndOrdersComponent,
    data: { breadcrumb: 'EVENTS & ORDERS' },
    children: [
      {
        path: '',
        redirectTo: '/event-order/manage-trial-events',
        data: { roles: allNamcRole },
        pathMatch: 'full',
      },
      {
        path: 'manage-trial-events',
        data: { breadcrumb: 'MANAGE TRIAL EVENTS', roles: allNamcRole },
        children: [
          {
            path: '',
            component: ManageTrialEventsComponent,
            data: { breadcrumb: '', roles: allNamcRole },
            canActivate: [AuthGuard],
          },
          {
            path: 'create-new-event',
            component: CreateEventComponent,
            data: { breadcrumb: 'CREATE NEW EVENT', roles: adminRole },
            canActivate: [AuthGuard],
          },
          {
            path: 'edit-event',
            component: CreateEventComponent,
            data: { breadcrumb: 'EDIT EVENT', roles: adminRole },
            canActivate: [AuthGuard],
          },
          {
            path: 'view-event',
            component: CreateEventComponent,
            data: { breadcrumb: 'VIEW EVENT', roles: allNamcRole },
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'uploadOrders',
        component: UploadOrdersNamcComponent,
        data: { breadcrumb: 'UPLOAD ORDERS', roles: adminRole },
        canActivate: [AuthGuard],
      },
      {
        path: 'trial-orders',
        data: { breadcrumb: 'TRIAL ORDERS' },
        children: [
          {
            path: '',
            component: TrialOrdersComponent,
            data: { breadcrumb: '' },
          },
          {
            path: 'confirmOrder',
            component: ReviewShipDateComponent,
            data: { breadcrumb: 'Confirm Single Order Supplier Ship Date' },
          },
          {
            path: 'confirmSupplierOrder',
            component: ConfirmSingleOrderComponent,
            data: { breadcrumb: 'Confirm Single Order Supplier Ship Date' },
          },
          {
            path: 'trialOrderList',
            data: { breadcrumb: 'Display Trial Orders' },
            children: [
              {
                path: '',
                component: DisplayTrialOrdersComponent,
                data: { breadcrumb: null },
              },
              {
                path: 'createTrials',
                component: CreateTrailsComponent,
                data: { breadcrumb: 'CREATE TRIAL ORDER', roles: adminRole },
                canActivate: [AuthGuard]
              },
              {
                path: 'appendTrials',
                component: AppendTrailsComponent,
                data: { breadcrumb: 'APPEND TRIAL ORDER', roles: adminRole },
                canActivate: [AuthGuard]
              },
              {
                path: 'viewTrials',
                component: ViewEditComponent,
                data: { breadcrumb: 'VIEW TRIAL ORDER' },
              },
              {
                path: 'editTrials',
                component: ViewEditComponent,
                data: { breadcrumb: 'EDIT TRIAL ORDER', roles: adminRole },
                canActivate: [AuthGuard]
              },
            ],
          },
          {
            path: 'trialOrderHistory',
            component: DisplayHistoryComponent,
            data: { breadcrumb: 'DISPLAY HISTORY' },
          },
          {
            path: 'shippingAddress',
            component: DisplayOrderShippingAddressComponent,
            data: { breadcrumb: 'VIEW ORDER SHIPPING ADDRESS' },
          },
          {
            path: 'supplierTrialOrderList',
            data: { breadcrumb: 'Display Trial Orders' },
            children: [
              {
                path: '',
                component: TrialOrdersSupplierComponent,
                data: { breadcrumb: null },
              },
              {
                path: 'viewTrials',
                component: ViewEditComponent,
                data: { breadcrumb: 'VIEW TRIAL ORDER' },
              },
            ],
          },
        ],
      },
      {
        path: 'archived-orders',
        component: ArchivedOrdersComponent,
        data: { breadcrumb: 'ARCHIVED ORDERS DATA' },
      },
      {
        path: 'upload-orders-data',
        component: UploadOrdersDataComponent,
        data: { breadcrumb: 'UPLOAD ORDERS' , roles: adminWithSupplierRole},
        canActivate: [AuthGuard]
      },
      {
        path: 'namc-documents',
        component: OnlineTrainingNamcDocumentsComponent,
        data: { breadcrumb: 'NAMC DOCUMENTS' },
      },
    ],
  },
  {
    path: 'tmna-namc',
    component: TmnaNamcComponent,
    data: {
      breadcrumb: 'TMNA/NAMC',
    },
    children: [
      {
        path: 'manage-namc-documents',
        data: {
          breadcrumb: 'MANAGE NAMC DOCUMENTS',
          roles: adminRole,
        },
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: ManageOnlineTrainingComponent,
            data: { breadcrumb: null, roles: adminRole },
            canActivate: [AuthGuard],
          },
          {
            path: 're-order',
            component: ReorderOnlineTrainingComponent,
            data: {
              breadcrumb: 'REORDER TRAINING MATERIAL',
              roles: adminRole,
            },
            canActivate: [AuthGuard],
          },
          {
            path: 'add',
            component: AddOnlineTrainingComponent,
            data: {
              breadcrumb: 'ADD TRAINING MATERIAL',
              roles: adminRole,
            },
            canActivate: [AuthGuard],
          },
          {
            path: 'edit',
            component: AddOnlineTrainingComponent,
            data: {
              breadcrumb: 'EDIT TRAINING MATERIAL',
              roles: adminRole,
            },
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'manage-online-training',
        data: {
          breadcrumb: 'MANAGE ONLINE TRAINING',
          roles: adminRole,
        },
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: ManageOnlineTrainingComponent,
            data: { breadcrumb: null, roles: adminRole },
            canActivate: [AuthGuard],
          },
          {
            path: 're-order',
            component: ReorderOnlineTrainingComponent,
            data: {
              breadcrumb: 'REORDER TRAINING MATERIAL',
              roles: adminRole,
            },
            canActivate: [AuthGuard],
          },
          {
            path: 'add',
            component: AddOnlineTrainingComponent,
            data: {
              breadcrumb: 'ADD TRAINING MATERIAL',
              roles: adminRole,
            },
            canActivate: [AuthGuard],
          },
          {
            path: 'edit',
            component: AddOnlineTrainingComponent,
            data: {
              breadcrumb: 'EDIT TRAINING MATERIAL',
              roles: adminRole,
            },
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: '',
        redirectTo: '/tmna-namc/manage-users',
        pathMatch: 'full',
      },
      {
        path: 'manage-users',
        data: { breadcrumb: 'MANAGE USERS', roles: allAdminRole },
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: ManageUsersComponent,
            data: { breadcrumb: '', roles: allAdminRole },
            canActivate: [AuthGuard],
          },
          {
            path: 'edit',
            component: AddEditUserComponent,
            data: { breadcrumb: 'EDIT USER', roles: allAdminRole },
          },
          {
            path: 'add',
            component: AddEditUserComponent,
            data: { breadcrumb: 'ADD USER', roles: allAdminRole },
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'manage-attributes',
        data: { breadcrumb: 'MANAGE ATTRIBUTES', roles: isAdminRole },
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: ManageAttributesComponent,
            data: { breadcrumb: '', roles: isAdminRole },
            canActivate: [AuthGuard],
          },
          {
            path: 'modify-attribute',
            component: ModifyAttributeComponent,
            data: { breadcrumb: 'MODIFY ATTRIBUTE', roles: isAdminRole },
            canActivate: [AuthGuard],
          },
          {
            path: 'add-new-attribute',
            component: AddAttributeComponent,
            data: { breadcrumb: 'ADD NEW ATTRIBUTE', roles: isAdminRole },
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'manage-configurations',
        data: { breadcrumb: 'MANAGE CONFIGURATIONS', roles: isAdminRole },
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: ManageConfigurationsComponent,
            data: { breadcrumb: '', roles: isAdminRole },
            canActivate: [AuthGuard],
          },
          {
            path: 'modify-configuration',
            component: ModifyConfigurationComponent,
            data: { breadcrumb: 'MODIFY CONFIGURATION', roles: isAdminRole },
            canActivate: [AuthGuard],
          },
        ],
      },
    ],
  },
  {
    path: 'supplier',
    component: SupplierComponent,
    data: { breadcrumb: 'SUPPLIERS' },
    children: [
      {
        path: 'online-training',
        component: OnlineTrainingNamcDocumentsComponent,
        data: { breadcrumb: 'ONLINE TRAINING' },
      },
      {
        path: 'namc-documents',
        component: OnlineTrainingNamcDocumentsComponent,
        data: { breadcrumb: 'NAMC DOCUMENTS' },
      },
      {
        path: '',
        redirectTo: '/supplier/loadMassEmail',
        pathMatch: 'full',
      },
      {
        path: 'loadMassEmail',
        component: MassEmailComponent,
        data: { breadcrumb: 'MASS EMAIL' },
      },
      {
        path: 'performance',
        data: { breadcrumb: 'PERFORMANCE', roles: allAdminRole },
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: PerformanceComponent,
            data: { breadcrumb: null },
          },
          {
            path: 'lateOrders',
            component: LateOrderGridComponent,
            data: { breadcrumb: 'LATE ORDERS' },
          },
        ],
      },
      {
        path: 'contacts',
        component: ContactsComponent,
        data: { breadcrumb: 'CONTACTS' },
      },
    ],
  },

  {
    path: 'help/online-training',
    component: OnlineTrainingNamcDocumentsComponent,
    data: { breadcrumb: 'ONLINE TRAINING' },
  },

  { path: '**', redirectTo: '/home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
