import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CustomCellEditorComponent } from 'src/app/core/grid-renderer/custom-cell-editor/custom-cell-editor.component';
import { Subscription } from 'rxjs';
import { RefreshComponent } from 'src/app/core/grid-renderer/refresh/refresh.component';
import { SupplierCommentsActionList } from 'src/app/core/model/comments.model';
import { SelectedRole } from 'src/app/core/model/role-check.model';
import {
  AttributeDetails,
  TrialOrderDetails,
} from 'src/app/core/model/trial-order.model';
import { User } from 'src/app/core/model/user.model';
import { TrialOrdersService } from 'src/app/services/event-and-orders/trial-orders.service';
import { HomeService } from 'src/app/services/home.service';
import { UserService } from 'src/app/services/user.service';
import { NotificationMessageComponent } from 'src/app/core/notification-message/notification-message.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-trial-orders-supplier',
  templateUrl: './trial-orders-supplier.component.html',
  styleUrls: ['./trial-orders-supplier.component.scss'],
})
export class TrialOrdersSupplierComponent implements OnInit {
  @ViewChild(NotificationMessageComponent)
  childComponent!: NotificationMessageComponent;
  columnDefs: ColDef[] = [];
  columnDefsFiltered: ColDef[] = [];
  displayRowData: TrialOrderDetails[] = [];

  filterSubscription!: Subscription;
  holdOriginalArray!: string;
  namcCode = '';
  currentRole = '';
  user: User | null = null;
  allEvents = true;
  rowSelection: 'single' | 'multiple' | undefined = 'multiple';
  eventId = '';
  successMessage: string | undefined = undefined;
  errorMessage: string | undefined = undefined;
  eventDetailsFlags: AttributeDetails = {
    trialEventName: '',
    displayPOTrackingNumber: '',
    showEdit: '',
    isAllEvents: '',
    isEventDeleted: '',
  };
  selectedOrder: TrialOrderDetails[] = [];
  rowCount = 0;
  dummy = false;
  orderDetails = '';
  projectCode='' 
  endLimit = 15;
  startLimit = 1;
  screen = 'TrialOrder';

  constructor(
    private readonly userService: UserService,
    private readonly trialOrderService: TrialOrdersService,
    private readonly ngxUiLoaderService: NgxUiLoaderService,
    private readonly quickLinkService: HomeService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.eventId = String(sessionStorage.getItem('eventId'));
    this.orderDetails = sessionStorage.getItem('quickLinkOrderDetails') ?? '';
    this.namcButton();
    if (this.eventId === '-1') {
      this.getColDefs();
      this.getQuickLinkOrderDetails(this.orderDetails);
    } else {
      this.setEventDetails();
      this.getGridData();
    }
    this.userService.userDetails.subscribe((user) => {
      this.user = user;
      if (user) {
        this.loadRoleDetails();
      }
    });
    if (history.state?.successMessage) {
      this.showMessage();
    }
  }

  showMessage() {
    this.successMessage = history.state?.successMessage;
    this.settingTimeOut();
  }

  loadRoleDetails() {
    this.userService.selectedRoles?.subscribe((selectedRoles) => {
      const role = this.user?.roles.find(
        (value) => value.roleDescription.toLowerCase() === selectedRoles?.role
      );
      this.currentRole = role?.roleDescription ?? '';
      this.namcCode = role?.namcCode ?? '';
    });
  }

  namcButton() {
    this.dummy = Boolean(history.state?.dummy);
    if (this.dummy) {
      if (history.state?.err) {
        this.errorMessage = String(history.state?.err);
      } else {
        this.successMessage = String(history.state?.res.message);
      }
      setTimeout(() => {
        this.errorMessage = undefined;
        this.successMessage = undefined;
      }, 5000);
    } else if (history.state?.cancel) {
      if (!history.state?.res.cancelFlag) {
        this.errorMessage = history.state?.res.status;
      } else {
        this.successMessage = history.state?.res.status;
      }
      setTimeout(() => {
        this.errorMessage = undefined;
        this.successMessage = undefined;
      }, 50000);
    }
  }

  setEventDetails() {
    this.ngxUiLoaderService.start('setupevent');
    this.trialOrderService.getEventDetails(this.eventId)?.subscribe({
      next: (result) => {
        this.ngxUiLoaderService.stop('setupevent');
        this.eventDetailsFlags = result;
        this.getColDefs();
      },
      error: (err) => {
        this.ngxUiLoaderService.stop('setupevent');
        const error = err as HttpErrorResponse;
        this.errorMessage = error.error.error;
        setTimeout(() => {
          this.errorMessage = undefined;
          this.successMessage = undefined;
        }, 5000);
      },
    });
  }

  getColDefs() {
    this.columnDefs = [
      {
        floatingFilterComponent: RefreshComponent,
        floatingFilterComponentParams: { suppressFilterButton: true },
        checkboxSelection: true,
        minWidth: 60,
        resizable: true,
        // maxWidth: 60,
        pinned: 'left',
      },
      {
        headerName: 'ORDER STATUS',
        field: 'status',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRenderer: (params: { value: string }) => {
          return `<span class="status-holder 
            ${params.value}
            "> 
            ${params.value.replace('_', ' ')}
            </span>`;
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        // maxWidth: 195,
        minWidth: 195,
      },
      {
        headerName: 'ORDER TRACKING NO',
        field: 'orderNumber',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        // maxWidth: 160,
        minWidth: 160,
        resizable: true,
      },
      {
        headerName: 'ORDER TYPE',
        field: 'orderType',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        // maxWidth: 130,
        minWidth: 130,
        resizable: true,
      },
      {
        headerName: 'PO Tracking No',
        field: 'poTrackingNumber',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        hide: this.eventDetailsFlags.displayPOTrackingNumber === 'false',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        // maxWidth: 160,
        minWidth: 160,
        resizable: true,
      },
      {
        headerName: 'SAP Status',
        field: 'sapStatus',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRenderer: (params: { value: string }) => {
          const splits = params.value.split(';');
          return (
            '<span class="fa sap-status ' +
            splits[0].replace(' ', '').toLowerCase() +
            '" title="' +
            splits[1] +
            '"> ' +
            '<span class="sapStatusval">' +
            splits[0] +
            '</span>' +
            '</span>'
          );
        },
        hide: this.eventId !== '-1',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        // maxWidth: 130,
        minWidth: 130,
        resizable: true,
      },
      {
        headerName: 'EVENT NAME',
        field: 'eventName',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRenderer: (params: { value: string }) => {
          return this.renderTitle(params);
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        // maxWidth: 210,
        minWidth: 210,
        resizable: true,
      },
      {
        headerName: 'PROJECT',
        field: 'projectCode',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        // maxWidth: 90,
        minWidth: 90,
        resizable: true,
      },
      {
        headerName: 'PSMS ROUTING',
        field: 'psmsRouting',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        hide: this.eventId !== '-1',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        // maxWidth: 120,
        minWidth: 120,
        resizable: true,
      },
      {
        headerName: 'SPECIALIST NAME',
        field: 'specialistName',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRenderer: (param: { value: string }) => {
          return this.renderTitle(param);
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        // maxWidth: 210,
        minWidth: 210,
        resizable: true,
      },
      {
        headerName: 'SUPPLIER CODE',
        field: 'supplierCd',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        // maxWidth: 90,
        minWidth: 90,
        resizable: true,
      },
      {
        headerName: 'KANBAN NUMBER',
        field: 'kanbanNumber',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        // maxWidth: 150,
        minWidth: 150,
        resizable: true,
      },
      {
        headerName: 'PART NUMBER',
        field: 'partNumber',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRenderer: (param: { value: string }) => {
          return this.renderTitle(param);
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        // maxWidth: 170,
        minWidth: 150,
        resizable: true,
      },
      {
        headerName: 'PART NAME',
        field: 'partName',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRenderer: (param: { value: string }) => {
          return this.renderTitle(param);
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 210,
        resizable: true,
        // maxWidth: 210,
      },
      {
        headerName: 'REVISION',
        field: 'revisionDate',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        // maxWidth: 160,
        minWidth: 160,
        resizable: true,
      },
      {
        headerName: 'NAMC DUE DATE',
        field: 'orderDueDate',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 160,
        resizable: true,
        // maxWidth: 160,
      },
      {
        headerName: 'SUPPLIER PICKUP DATE',
        field: 'supplierPickupDate',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 160,
        resizable: true,
        // maxWidth: 160,
      },
      {
        headerName: 'SUPPLIER SHIP DATE',
        field: 'promiseDate',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 165,
        resizable: true,
        // maxWidth: 165,
      },
      {
        headerName: 'TOTAL QTY',
        field: 'totalQty',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 90,
        resizable: true,
        // maxWidth: 90,
      },
      {
        headerName: 'SUPPLIER NAME',
        field: 'supplierName',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        hide: this.eventId !== '-1',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 250,
        resizable: true,
        // maxWidth: 250,
      },
      {
        headerName: 'NAMC COMMENT',
        field: 'namcComment',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellEditor: CustomCellEditorComponent,
        cellRenderer: (param: { value: string }) => {
          return this.renderTitle(param);
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 250,
        resizable: true,
        // maxWidth: 250,
      },
      {
        headerName: 'SUPPLIER COMMENT',
        field: 'supplierComment',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellEditor: CustomCellEditorComponent,
        cellRenderer: (param: any) => {
          this.handleCellValueChanged(param);
          return this.renderEditTitle(param);
        },
        onCellValueChanged: (params: any) =>
          this.handleCellValueChanged(params),
        cellStyle: (params) => {
          if (params.value) {
            if (params.value.length > 1000) {
              this.childComponent.specialInvoke(
                'E',
                `SUPPLIER COMMENT IS LARGER THAN EXPECTED FOR THE ORDER NO.: ${params.data.orderNumber}`
              );
              return { color: 'red' };
            } else {
              return { color: 'black' };
            }
          }
          return { color: 'black' };
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 250,
        resizable: true,
        // maxWidth: 250,
      },
      {
        headerName: 'STORE ADDRESS',
        field: 'storeAddress',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRenderer: (param: { value: string }) => {
          return this.renderTitle(param);
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 150,
        resizable: true,
        // maxWidth: 150,
      },
      {
        headerName: 'LINE SIDE ADDRESS',
        field: 'lineSideAddress',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRenderer: (param: { value: string }) => {
          return this.renderTitle(param);
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 150,
        resizable: true,
        // maxWidth: 150,
      },
      {
        headerName: 'NAMC CONTACT',
        field: 'namcEmail',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        hide: this.eventId === '-1',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 250,
        resizable: true,
        // maxWidth: 250,
      },
      {
        headerName: 'DOCK CODE',
        field: 'dockCode',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRenderer: (param: { value: string }) => {
          return this.renderTitle(param);
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 120,
        resizable: true,
        // maxWidth: 120,
      },
      {
        headerName: 'PALLET CODE',
        field: 'palletCode',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRenderer: (param: { value: string }) => {
          return this.renderTitle(param);
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 120,
        resizable: true,
        // maxWidth: 120,
      },
      {
        headerName: 'MANIFEST NUMBER',
        field: 'manifestNumber',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 165,
        resizable: true,
        // maxWidth: 165,
      },
      {
        headerName: 'RECEIVER NUMBER',
        field: 'orderReceiverNbr',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 165,
        resizable: true,
        // maxWidth: 165,
      },
      {
        headerName: 'NA PIPE STATUS',
        field: 'naPipeStatus',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 120,
        resizable: true,
        // maxWidth: 120,
      },
      {
        headerName: 'ECI NUMBER',
        field: 'eciNumber',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellEditor: CustomCellEditorComponent,
        cellRenderer: (param: { value: string }) => {
          return this.renderEditTitle(param);
        },
        onCellValueChanged: (params: any) =>
          this.handleCellValueChanged(params),
        cellStyle: (params) => {
          if (params.value) {
            if (params.value.length !== 9) {
              this.childComponent.specialInvoke(
                'E',
                `ECI NUMBER DATA LENGTH IS NOT NINE DIGITS FOR THE ORDER NO: ${params.data.orderNumber}`
              );
              return { color: 'red' };
            } else {
              return { color: 'black' };
            }
          }
          return { color: 'black' };
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 190,
        resizable: true,
        // maxWidth: 190,
      },
      {
        headerName: 'ECI REVISION',
        field: 'eciRevision',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellEditor: CustomCellEditorComponent,
        cellRenderer: (param: any) => {
          this.handleCellValueChanged(param);
          return this.renderEditTitle(param);
        },
        onCellValueChanged: (params: any) =>
          this.handleCellValueChanged(params),
        cellStyle: (params) => {
          if (params.value) {
            if (params.value.length > 2) {
              this.childComponent.specialInvoke(
                'E',
                `ECI REVISION DATA LENGTH IS LARGER THAN EXPECTED FOR THE ORDER NO: ${params.data.orderNumber}`
              );
              return { color: 'red' };
            } else {
              return { color: 'black' };
            }
          }
          return { color: 'black' };
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 190,
        resizable: true,
        // maxWidth: 190,
      },
      {
        headerName: 'SPECIALIST CODE',
        field: 'specialistCd',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 140,
        resizable: true,
        // maxWidth: 140,
      },
      {
        headerName: 'SHIPPING DATE',
        field: 'shippingDateTime',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellEditor: CustomCellEditorComponent,
        cellRenderer: (param: { value: string }) => {
          return this.renderConditionalEdit1(param);
        },
        onCellValueChanged: (params: any) =>
          this.handleCellValueChanged(params),
        cellStyle: (params) => {
          const dateRegex =
            /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
          if (params.value.length === 0) {
            return { color: 'black' };
          } else if (!dateRegex.test(params.value)) {
            this.childComponent.specialInvoke(
              'E',
              `SHIPPING DATE ISN'T VALID DATE FOR THE ORDER NO: ${params.data.orderNumber}`
            );
            return { color: 'red' };
          } else {
            return { color: 'black' };
          }
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 165,
        resizable: true,
        // maxWidth: 165,
      },
      {
        headerName: 'TRAILER NUMBER',
        field: 'trailerNumber',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellEditor: CustomCellEditorComponent,
        cellRenderer: (param: { value: string }) => {
          return this.renderConditionalEdit1(param);
        },
        onCellValueChanged: (params: any) =>
          this.handleCellValueChanged(params),
        cellStyle: (params) => {
          if (params.value.length > 30) {
            this.childComponent.specialInvoke(
              'E',
              `TRAILER NUMBER DATA LENGTH IS LARGER THAN EXPECTED FOR THE ORDER NO: ${params.data.orderNumber}`
            );
            return { color: 'red' };
          } else {
            return { color: 'black' };
          }
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 165,
        resizable: true,
        // maxWidth: 165,
      },
      {
        headerName: 'SHIPPING CARRIER',
        field: 'shippingCarrier',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellEditor: CustomCellEditorComponent,
        cellRenderer: (param: { value: string }) => {
          return this.renderConditionalEdit1(param);
        },
        onCellValueChanged: (params: any) =>
          this.handleCellValueChanged(params),
        cellStyle: (params) => {
          if (params.value.length > 30) {
            this.childComponent.specialInvoke(
              'E',
              `SHIPPING CARRIER DATA LENGTH IS LARGER THAN EXPECTED FOR THE ORDER NO: ${params.data.orderNumber}`
            );
            return { color: 'red' };
          } else {
            return { color: 'black' };
          }
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 165,
        resizable: true,
        // maxWidth: 165,
      },
      {
        headerName: 'TRACKING/AIRLBILL NO',
        field: 'trackingAirbillNumber',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellEditor: CustomCellEditorComponent,
        cellRenderer: (param: { value: string }) => {
          return this.renderConditionalEdit1(param);
        },
        onCellValueChanged: (params: any) =>
          this.handleCellValueChanged(params),
        cellStyle: (params) => {
          if (params.value.length > 30) {
            this.childComponent.specialInvoke(
              'E',
              `TRACKING/AIRLBILL NO DATA LENGTH IS LARGER THAN EXPECTED FOR THE ORDER NO: ${params.data.orderNumber}`
            );
            return { color: 'red' };
          } else {
            return { color: 'black' };
          }
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 165,
        resizable: true,
        // maxWidth: 165,
      },
      {
        headerName: 'LIFE CYCLE CODE',
        field: 'lifeCycleCode',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRenderer: (param: { value: string }) => {
          return this.renderTitle(param);
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 140,
        resizable: true,
        // maxWidth: 140,
      },
      {
        headerName: 'WORK ORDER NUMBER',
        field: 'workOrderNumber',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        cellRenderer: (param: { value: string }) => {
          return this.renderTitle(param);
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 180,
        resizable: true,
        // maxWidth: 180,
      },
      {
        headerName: 'PO LINE NUMBER',
        field: 'poLineNo',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        hide: this.eventId !== '-1',
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 160,
        resizable: true,
        // maxWidth: 160,
      },
      {
        headerName: 'SUPPLIER SHIP DATE CHANGE REQUEST',
        field: 'requestForSuppShipDtChng',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellEditor: CustomCellEditorComponent,
        cellRenderer: (param: { value: string }) => {
          return this.renderEditTitle(param);
        },
        onCellValueChanged: (params: any) =>
          this.handleCellValueChanged(params),
        cellStyle: (params) => {
          const dateRegex =
            /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
          if (!dateRegex.test(params.value) && params.value.length !== 0) {
            this.childComponent.specialInvoke(
              'E',
              `SUPPLIER SHIP DATE CHANGE REQUEST ISN'T VALID DATE FOR THE ORDER NO: ${params.data.orderNumber}`
            );
            return { color: 'red' };
          } else {
            return { color: 'black' };
          }
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 300,
        resizable: true,
        // maxWidth: 1000,
      },
      {
        headerName: 'SUPPLIER SHIP DATE CHANGE COMMENT',
        field: 'requestForSuppShipcmmntChng',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        editable: this.eventDetailsFlags.isAllEvents === 'false',
        cellEditor: CustomCellEditorComponent,
        cellRenderer: (param: { value: string }) => {
          return this.renderEditTitle(param);
        },
        onCellValueChanged: (params: any) =>
          this.handleCellValueChanged(params),
        cellStyle: (params) => {
          if (params.value != null && params.value.length > 1000) {
            this.childComponent.specialInvoke(
              'E',
              `SUPPLIER SHIP DATE CHANGE COMMENT DATA LENGTH IS LARGER THAN EXPECTED FOR THE ORDER NO: ${params.data.orderNumber}`
            );
            return { color: 'red' };
          } else {
            return { color: 'black' };
          }
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 300,
        resizable: true,
        // maxWidth: 1000,
      },
      {
        headerName: 'ARIBA PR NO',
        field: 'aribaPrNumber',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        hide: this.eventDetailsFlags.displayPOTrackingNumber === 'false',
        cellRenderer: (param: { value: string }) => {
          return this.renderTitle(param);
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 160,
        resizable: true,
        // maxWidth: 160,
      },
      {
        headerName: 'ARIBA ORDER STATUS',
        field: 'aribaOrderStatus',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        hide: this.eventDetailsFlags.displayPOTrackingNumber === 'false',
        cellRenderer: (param: { value: string }) => {
          return this.renderTitle(param);
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 200,
        resizable: true,
        // maxWidth: 350,
      },
      {
        headerName: 'ARIBA ORDER COMMENT',
        field: 'aribaOrderComment',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        hide: this.eventDetailsFlags.displayPOTrackingNumber === 'false',
        cellRenderer: (param: { value: string }) => {
          return this.renderTitle(param);
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        minWidth: 200,
        resizable: true,
        // maxWidth: 350,
      },
    ];
    const value = localStorage.getItem('supplier-columns-saved');
    let savedColumns;

    if (value !== null) savedColumns = JSON.parse(value);
    else savedColumns = null;

    if (!savedColumns) {
      this.columnDefsFiltered = this.columnDefs;
      this.trialOrderService.emitSupplierColumns(
        this.columnDefs
          .filter((e) => e.headerName && e.headerName !== 'ACTION' && !e.hide)
          .map((e) => {
            return { label: e.headerName ?? '', value: e.headerName ?? '' };
          })
      );
    } else {
      const fieldsToShow = savedColumns.map(
        (col: { label: string; value: string }) => col.label
      );
      this.columnDefsFiltered = this.columnDefs.filter(
        (col: ColDef) =>
          !col.hide &&
          (fieldsToShow.includes(col.headerName) ||
            col.headerName === undefined ||
            col.headerName == 'ACTION')
      );
    }
    this.shouldhide();
  }

  shouldhide() {
    this.filterSubscription = this.trialOrderService.supplierValue$?.subscribe(
      (value: { label: string; value: string }[]) => {
        const fieldsToShow = value?.map((col) => col.label);
        this.columnDefsFiltered = this.columnDefs.filter(
          (col: ColDef) =>
            !col.hide &&
            (col.headerName === undefined ||
              fieldsToShow?.includes(col.headerName) ||
              col.headerName == 'ACTION')
        );
      }
    );
  }

  ngOnDestroy(): void {
    const savedColumns = JSON.parse(
      localStorage.getItem('supplier-columns-saved') ?? '[]'
    );

    this.trialOrderService.emitSupplierColumns(savedColumns);
    this.filterSubscription?.unsubscribe();
  }
  onPaginationChanged(_event: any) {
    this.startLimit = _event.currentPage;
    this.endLimit = _event.lastPage;
    this.getGridData();
  }

  getGridData() {
    const roleDetails = JSON.parse(
      sessionStorage.getItem('roleDetails') ?? '{}'
    ) as SelectedRole;
    this.ngxUiLoaderService.start('griddata');
    this.trialOrderService
      .getGridData(this.eventId, this.startLimit, this.endLimit, roleDetails)
      ?.subscribe({
        next: (result) => {
          this.ngxUiLoaderService.stop('griddata');
          this.rowCount = result.totalRow;
          this.displayRowData = result.trialOrderData as TrialOrderDetails[];
          this.projectCode = this.displayRowData?.length ? this.displayRowData[0].projectCode : '';
          this.holdOriginalArray = JSON.stringify(
            result.trialOrderData as TrialOrderDetails[]
          );
        },
        error: (err) => {
          this.ngxUiLoaderService.stop('griddata');
          const error = err as HttpErrorResponse;
          this.errorMessage = error.error.error;
          setTimeout(() => {
            this.errorMessage = undefined;
            this.successMessage = undefined;
          }, 5000);
        },
      });
  }

  renderTitle(params: { value: string }) {
    if (params.value && params.value !== '') {
      const displayValue =
        params.value.length > 25
          ? params.value.substring(0, 25) + '...'
          : params.value;
      return `<span title="${params.value}">${displayValue}</span>`;
    }
    return params.value;
  }

  renderEditTitle(params: { value: string }) {
    const { isAllEvents } = this.eventDetailsFlags;
    if (isAllEvents === 'false') {
      const displayValue =
        params.value.length > 25
          ? params.value.substring(0, 25) + '...'
          : params.value;
      return `<div><span title="${params.value}">${displayValue}</span><span><i class="material-icons small-icon">edit</i></span></div>`;
    }
    return this.renderTitle(params);
  }

  renderConditionalEdit1(params: any) {
    if (
      this.eventDetailsFlags.isAllEvents === 'false' &&
      params.data.status === 'Firmed'
    ) {
      const displayValue =
        params.value.length > 25
          ? params.value.substring(0, 25) + '...'
          : params.value;
      return `<div><span title="${params.value}">${displayValue}</span><span><i class="material-icons small-icon">edit</i></span></div>`;
    }
    return this.renderTitle(params);
  }
  onSupplierCommentEmit(event: SupplierCommentsActionList) {
    if (event.message === '') {
      this.selectedOrder = [];
      if (this.eventId !== '-1') {
        this.setEventDetails();
        this.getGridData();
        return;
      } else {
        this.getColDefs();
        this.getQuickLinkOrderDetails(this.orderDetails);
        return;
      }
    }
    if (event.success === true) {
      this.setEventDetails();
      this.getGridData();
      this.successMessage = event.message;
      this.settingTimeOut();
    } else {
      this.errorMessage = event.message;
      this.settingTimeOut();
    }
  }

  handleCellValueChanged(params: any): void {
    const listener = (event: KeyboardEvent) => {
      if (event.code === 'Enter') {
        params.node.setSelected(false);
      }
    };

    window.addEventListener('keydown', listener);
  }

  onSelectOrder(event: TrialOrderDetails[]) {
    this.selectedOrder = event;
  }

  cancel() {
    if (this.screen === 'quickLinks') {
      this.router.navigate(['home']);
    } else {
      this.router.navigate(['event-order/trial-orders']);
    }
  }

  settingTimeOut() {
    setTimeout(() => {
      this.errorMessage = undefined;
      this.successMessage = undefined;
    }, 5000);
  }

  onSave() {
    const keysToCompare: (keyof TrialOrderDetails)[] = [
      'orderNumber',
      'supplierComment',
      'eciNumber',
      'eciRevision',
      'shippingDateTime',
      'trackingAirbillNumber',
      'trailerNumber',
      'shippingCarrier',
      'requestForSuppShipDtChng',
      'requestForSuppShipcmmntChng',
    ];
    const changedObjects = this.findChangedObjects(
      JSON.parse(this.holdOriginalArray) as TrialOrderDetails[],
      this.displayRowData,
      keysToCompare
    );

    const payloadObj = changedObjects.map((e) => {
      return {
        appendShippingDateTime: e.shippingDateTime,
        appendOrderNumbers: e.orderNumber,
        appendEciNumber: e.eciNumber,
        appendEciRevision: e.eciRevision,
        userName: this.user?.userId,
        appendTrailerNumber: e.trailerNumber,
        appendShippingCarrier: e.shippingCarrier,
        appendComment: e.supplierComment,
        appendTrackingAirbillNumber: e.trackingAirbillNumber,
        appendSuppShpDtChng: e.requestForSuppShipDtChng,
        appendSuppShpDtCmmnt: e.requestForSuppShipcmmntChng,
      };
    });
    if (payloadObj.length === 0) {
      this.errorMessage = 'Please Make Changes to At Least One Order';
      setTimeout(() => {
        this.errorMessage = undefined;
      }, 5000);
      return;
    }

    this.saveMultipleOrders(payloadObj);
  }

  saveMultipleOrders(payloadObj: any) {
    this.ngxUiLoaderService.start('griddata');
    this.trialOrderService.multipleSaveOrdersForSupplier(payloadObj).subscribe({
      next: (response) => {
        this.getGridData();
        if (response.result === 'Failure') {
          this.childComponent.specialInvoke('E', response.errorList.toString());
        } else {
          this.childComponent.specialInvoke(
            'S',
            'Selected Orders Are Successfully Saved.'
          );
        }
      },
      error: (err) => {
        this.getGridData();
        this.errorMessage =
          err.status === 422
            ? err.error.errorList.join('\n')
            : 'Something Went Wrong, Please Contact Sytem Administrator';
        setTimeout(() => {
          this.errorMessage = undefined;
          this.successMessage = undefined;
        }, 5000);
      },
    });
  }

  getQuickLinkOrderDetails(orderDetails: string) {
    this.screen = 'quickLinks';
    const roleDetails = JSON.parse(
      String(sessionStorage.getItem('roleDetails'))
    ) as SelectedRole;
    this.ngxUiLoaderService.start('quicklinksorders');
    this.quickLinkService
      .getQuickLinkOrderDetails(orderDetails, roleDetails)
      ?.subscribe({
        next: (result) => {
          this.ngxUiLoaderService.stop('quicklinksorders');
          this.displayRowData = result.hasOwnProperty('trialOrderData')
            ? (result.trialOrderData as TrialOrderDetails[])
            : [];
        },
        error: (err) => {
          this.ngxUiLoaderService.stop('quicklinksorders');
          const error = err as HttpErrorResponse;
          this.errorMessage = error.error.error;
          setTimeout(() => {
            this.errorMessage = undefined;
            this.successMessage = undefined;
          }, 5000);
        },
      });
  }

  findChangedObjects = <TrialOrderDetails extends { orderNumber: string }>(
    originalArray: TrialOrderDetails[],
    newArray: TrialOrderDetails[],
    keysToCompare: (keyof TrialOrderDetails)[]
  ): Partial<TrialOrderDetails>[] => {
    return newArray
      .map((newObj: TrialOrderDetails) => {
        const originalObj = originalArray.find(
          (obj: TrialOrderDetails) => obj['orderNumber'] === newObj.orderNumber
        );
        if (!originalObj) return null;

        const originalFiltered = this.pickKeys(originalObj, keysToCompare);
        const newFiltered = this.pickKeys(newObj, keysToCompare);

        return this.hasChanges(originalFiltered, newFiltered)
          ? newFiltered
          : null;
      })
      .filter(Boolean) as Partial<TrialOrderDetails>[];
  };

  hasChanges = <TrialOrderDetails extends { orderNumber: string }>(
    obj1: Partial<TrialOrderDetails>,
    obj2: Partial<TrialOrderDetails>
  ): boolean => JSON.stringify(obj1) !== JSON.stringify(obj2);

  pickKeys = <TrialOrderDetails extends object>(
    obj: TrialOrderDetails,
    keys: (keyof TrialOrderDetails)[]
  ): Partial<TrialOrderDetails> =>
    keys.reduce((filtered, key) => {
      if (key in obj) {
        filtered[key] = obj[key];
      }
      return filtered;
    }, {} as Partial<TrialOrderDetails>);
}
