import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { TrialEventsList } from '../../model/manage-trial-events.model';
import { ManageTrialEventsComponent } from 'src/app/pages/events-and-orders/manage-trial-events/manage-trial-events.component';
import { ManageTrialEventsService } from 'src/app/services/events-and-orders/manage-trial-events.service';

@Component({
  selector: 'app-view-event',
  template: `<a
    href="javascript:;"
    class="hoverFeature"
    (click)="openEventView()"
    >{{ params.value }}</a
  >`,
  styles: [
    `
      .hoverFeature:hover {
        text-decoration: underline;
      }
    `,
  ],
})
export class ViewEventComponent implements ICellRendererAngularComp {
  private readonly manageTrialEventService = inject(ManageTrialEventsService);
  params!: ICellRendererParams<
    TrialEventsList,
    undefined,
    { componentParent: ManageTrialEventsComponent }
  >;

  constructor(private readonly router: Router) {}

  refresh(
    _params: ICellRendererParams<
      TrialEventsList,
      undefined,
      { componentParent: ManageTrialEventsComponent }
    >
  ): boolean {
    return false;
  }

  agInit(
    params: ICellRendererParams<
      TrialEventsList,
      undefined,
      { componentParent: ManageTrialEventsComponent }
    >
  ): void {
    this.params = params;
  }

  openEventView() {
    this.manageTrialEventService.setEditData(this.params.data);
    this.router.navigate(['/event-order/manage-trial-events/view-event'], {
      state: { data: this.params.data },
    });
  }
}
