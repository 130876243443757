import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { exhaustMap, filter, fromEvent, tap } from 'rxjs';
import { WarningPopupComponent } from 'src/app/core/dialogs/warning-popup/warning-popup.component';
import { SupplierListMap } from 'src/app/core/model/performance.model';
import { AppendOrderModel } from 'src/app/core/model/trial-order.model';
import { NotificationMessageComponent } from 'src/app/core/notification-message/notification-message.component';
import { CreateTrailsService } from 'src/app/services/event-and-orders/create-trails.service';
import { PerformanceService } from 'src/app/services/suppliers/performance.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-create-trails',
  templateUrl: './create-trails.component.html',
  styleUrls: ['./create-trails.component.scss'],
})
export class CreateTrailsComponent implements OnInit, AfterViewInit {
  @ViewChild(NotificationMessageComponent)
  childComponent!: NotificationMessageComponent;
  data: Data = {} as Data;
  eventId: string | undefined;
  ordernumber: string | undefined;
  trackingnumber = '';
  psmsRouting = '';
  supplierCodes: SupplierListMap[] = [];
  suppliercode = '';
  suppliername = '';
  comment = '';
  kanbanNumber = '';
  partNumber = '';
  partName = '';
  plant1Qty = '';
  plant2Qty = '';
  plant3Qty = '';
  totalQuantity!: number;
  storeAddress = '';
  lineSideAdress = '';
  specialistName = '';
  specialistCode = '';
  namcDate = '';
  disableSave = false;
  isSaveDisabled = false;
  supplierPickupDate = '';
  shippingDateTime = '';
  manifestNumber = '';
  lifeCycleCode = '';
  palletCode = '';
  currentNamc = '';
  workOrderNumber = '';
  mandatoryFieldError = 'This field is mandatory';
  eventTypeId = 0;
  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly route: ActivatedRoute,
    private readonly CreateTrailsService: CreateTrailsService,
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly performanceService: PerformanceService,
    private readonly ngxUiLoaderService: NgxUiLoaderService,
    private readonly dialog: MatDialog
  ) {}
  orderQuantities: { name: string; quantity: number }[] = [];
  isViewMode = false;

  response: AppendOrderModel = {} as AppendOrderModel;
  defaultQuantities: string[] = [];
  selectedOption = '';
  userId!: string;
  createdBy!: string;
  errorMessage = '';
  mandatoryFieldErrors = {
    partNumber: '',
    partName: '',
    supplierCode: '',
    namcDueDate: '',
    supplierPickUp: '',
    totalQuantity: '',
  };
  validdaterror = {
    nameduedate: '',
    pickupdate: '',
  };
  dateErrors = {
    namcDate: '',
    supplierPickupDate: '',
  };
  lengthError = {
    partNumbererror: '',
  };
  totalErrorMessage = '';
  validDate(): boolean {
    let ifValid = true;
    this.validdaterror.nameduedate = this.namcDate.trim()
      ? ''
      : this.mandatoryFieldError;
    this.validdaterror.pickupdate = this.supplierPickupDate.trim()
      ? ''
      : this.mandatoryFieldError;
    if (!this.namcDate.trim() || !this.supplierPickupDate.trim()) {
      ifValid = false;
    }
    return ifValid;
  }
  validateQuantities(): boolean {
    let isValid = true;
    const hasValidQuantity = this.orderQuantities.some(
      (item) => item.quantity > 0
    );

    if (!hasValidQuantity) {
      this.errorMessage = 'At least 1 Quantity Name is Required';
      isValid = false;
    } else {
      this.errorMessage = '';
    }
    if (this.partNumber.length !== 12) {
      this.lengthError.partNumbererror =
        'Part Number should be always 12 characters long';
      isValid = false;
    } else {
      this.lengthError.partNumbererror = '';
    }
    this.mandatoryFieldErrors.partNumber = this.partNumber.trim()
      ? ''
      : this.mandatoryFieldError;
    this.mandatoryFieldErrors.partName = this.partName.trim()
      ? ''
      : this.mandatoryFieldError;
    this.mandatoryFieldErrors.supplierCode = this.suppliercode.trim()
      ? ''
      : this.mandatoryFieldError;
    this.mandatoryFieldErrors.totalQuantity = this.totalQuantity
      ? ''
      : this.mandatoryFieldError;

    if (
      !this.partNumber.trim() ||
      !this.partName.trim() ||
      !this.suppliercode.trim() ||
      !this.totalQuantity
    ) {
      isValid = false;
    }

    if (Number(this.totalQuantity) !== this.calculateTotalQuantity()) {
      this.totalErrorMessage =
        'Total Qty must match the sum of all Quantity Names values';
      isValid = false;
    } else {
      this.totalErrorMessage = '';
    }

    return isValid;
  }

  calculateTotalQuantity(): number {
    return this.orderQuantities.reduce(
      (sum, item) => sum + Number(item.quantity || 0),
      0
    );
  }
  isInvalidFutureDate(_date: string): boolean {
    this.dateErrors = this.dateErrors || {};
    let errordate = true;
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const supplierPickupDate = new Date(this.supplierPickupDate);
    const orderDeliveryDate = new Date(this.namcDate);
    if (supplierPickupDate <= currentDate) {
      this.dateErrors.supplierPickupDate =
        'Selected date is invalid (it can not be present or past date)';
      errordate = false;
    } else if (supplierPickupDate > orderDeliveryDate) {
      this.dateErrors.supplierPickupDate =
        'Supplier pickup date should be less than or same as the NAMC due date';
      errordate = false;
    } else {
      this.dateErrors.supplierPickupDate = '';
    }
    if (orderDeliveryDate <= currentDate) {
      this.dateErrors.namcDate =
        'Selected date is invalid (it can not be present or past date)';
      errordate = false;
    } else {
      this.dateErrors.namcDate = '';
    }
    return errordate;
  }
  ngOnInit(): void {
    const roleDetails = JSON.parse(
      String(sessionStorage.getItem('roleDetails'))
    );
    this.currentNamc = roleDetails?.namc;
    this.eventId = String(sessionStorage.getItem('eventId'));
    this.userService.userDetails?.subscribe((value) => {
      if (value !== null) {
        this.userId = value.userId;
      }
    });
    this.selectedOption =
      this.currentNamc === '60TPR' || this.currentNamc === '26MTM'
        ? 'NO'
        : 'YES';
    this.isViewMode = true;
    this.CreateTrailsService.getQuantityNames(this.eventId).subscribe((res) => {
      this.response = res;
      this.eventTypeId = this.response.trialOrder.eventId.eventTypeId;
      this.ordernumber = this.response.trialOrder.orderNumber;
      this.orderQuantities = res.trialEventQuantityNameLst.map((item) => ({
        name: item.inputBox,
        quantity: 0,
      }));
      this.defaultQuantities = res.trialEventQuantityNameLst.map((element) => {
        return element.qNameBox;
      });
    });
    this.fetchSupplierCodes();
  }
  toggleSelection(option: string) {
    if (this.selectedOption === option) {
      this.selectedOption = 'YES';
    } else {
      this.selectedOption = option;
    }
  }
  fetchSupplierCodes(): void {
    this.ngxUiLoaderService.start('getCreateTrialOnoaddata');
    this.performanceService.suppliersDropDown()?.subscribe({
      next: (res) => {
        this.ngxUiLoaderService.stop('getCreateTrialOnoaddata');
        setTimeout(() => {
          this.supplierCodes = res;
        });
      },
      error: (_error: Error) => {
        this.errorMessage = 'Error fetching supplier codes';
      },
    });
  }
  onSupplierCodeChange(selectedCode: string): void {
    const selectedSupplier = this.supplierCodes.find(
      (supplier) => supplier.supplierCode === selectedCode
    );
    this.suppliername = selectedSupplier ? selectedSupplier.supplierName : '';
  }
  convertToNumber(value: string): number {
    return value ? parseInt(value, 10) : 0;
  }
  mapOrderQuantities(): void {
    this.orderQuantities.forEach((item) => {
      if (this.data.hasOwnProperty(item.name)) {
        item.quantity = Number(this.data[item.name]);
      } else {
        item.quantity = 0;
      }
    });
  }

  ngAfterViewInit() {
    const submitButton = document.getElementById('submit-btn');
    if (submitButton) {
      fromEvent(submitButton, 'click')
        .pipe(
          filter(() => this.onSubmit()),
          tap(() => this.ngxUiLoaderService.start('Loader-01')),
          exhaustMap(() => {
            const payload = this.createPayload();
            return this.CreateTrailsService.oncreateTrails(payload);
          }),
          tap(() => this.ngxUiLoaderService.stop('Loader-01'))
        )
        .subscribe({
          next: (res) => {
            this.router
              .navigateByUrl('/dummy', { skipLocationChange: true })
              .then(() => {
                this.router.navigate(
                  ['event-order/trial-orders/trialOrderList'],
                  {
                    state: { res, myData: this.eventId, dummy: true },
                  }
                );
              });
          },
          error: (_err) => {
            this.childComponent.specialInvoke('E', 'Something Went Wrong');
            window.scrollTo({ top: 0, behavior: 'smooth' });
          },
        });
    }
  }

  createPayload() {
    return {
      eventId: this.eventId,
      psmsFromRouting: this.response.psmsFromRouting,
      psmsToRouting: this.psmsRouting,
      appendDeliveryDate: this.namcDate,
      appendSupplierPickupDate: this.supplierPickupDate,
      appendWorkOrderNumber: this.workOrderNumber,
      appendLifeCycleCode: this.lifeCycleCode,
      trialEventQuantityNameLst: this.response.trialEventQuantityNameLst,
      appendSendToSapFlag: this.selectedOption === 'YES' ? 'Y' : 'N',
      calculateOrderNumber: this.response.calculateOrderNumber,
      trialOrder: {
        statusId: this.response.trialOrder.statusId,
        orderNumber: this.response.trialOrder.orderNumber,
        promiseDate: null,
        isArchiveFlag: this.response.trialOrder.isArchiveFlag,
        totalOrderQuantity: Number(this.totalQuantity),
        namcComent: this.comment,
        supplierPickupDate: this.response.appendSupplierPickupDate,
        supplierCode: this.suppliercode,
        supplierName: this.suppliername,
        kanbanNumber: this.kanbanNumber,
        partNumber: this.partNumber,
        partName: this.partName,
        plant1Qty: this.convertToNumber(this.plant1Qty),
        plant2Qty: this.convertToNumber(this.plant2Qty),
        plant3Qty: this.convertToNumber(this.plant3Qty),
        deliveryDueDate: this.response.appendDeliveryDate,
        manifestNumber: this.manifestNumber,
        completionDate: this.response.trialOrder.eventId.completionDate,
        supplierComment: '',
        storeAddress: this.storeAddress,
        lineSideAddress: this.lineSideAdress,
        poTrackingNumber: this.trackingnumber,
        workOrderNumber: this.response.appendWorkOrderNumber,
        lifeCycleCode: this.lifeCycleCode,
        owkLabelLine1: '',
        owkLabelLine2: '',
        sendToSapFlag: this.selectedOption,
        aribaPrNumber: '',
        aribaOrderStatus: '',
        aribaOrderComment: '',
        poLineNo: '',
        requestForSuppShipDtChng: '',
        requestForSuppShipcmmntChng: '',
        palletCode: this.palletCode,
        naPipeStatus: '',
        eventId: {
          eventId: this.eventId,
          eventTypeId: this.response.trialOrder.eventId.eventTypeId,
          namcCode: this.response.trialOrder.eventId.namcCode,
          projectCode: this.response.trialOrder.eventId.projectCode,
          eventName: this.response.trialOrder.eventId.eventName,
          partsSource: this.response.trialOrder.eventId.partsSource,
          eventDescription: this.response.trialOrder.eventId.eventDescription,
          psmsFromRouting: this.response.trialOrder.eventId.psmsFromRouting,
          psmsToRouting: this.response.trialOrder.eventId.psmsToRouting,
          shipmentLocation: this.response.trialOrder.eventId.shipmentLocation,
          shipmentAddress: this.response.trialOrder.eventId.shipmentAddress,
          shipmentCity: this.response.trialOrder.eventId.shipmentCity,
          shipmentState: this.response.trialOrder.eventId.shipmentState,
          shipmentCountry: this.response.trialOrder.eventId.shipmentCountry,
          shipmentPostalCode:
            this.response.trialOrder.eventId.shipmentPostalCode,
          dockCode: this.response.trialOrder.eventId.dockCode,
          orderNumberSeries: this.response.trialOrder.eventId.orderNumberSeries,
          logisticProvider: this.response.trialOrder.eventId.logisticProvider,
          shipmentScheduledTracking:
            this.response.trialOrder.eventId.shipmentScheduledTracking,
          assignQPCToTrial: this.response.trialOrder.eventId.assignQPCToTrial,
          subRouteNameOverride:
            this.response.trialOrder.eventId.subRouteNameOverride,
          mainRouteNameOverride:
            this.response.trialOrder.eventId.mainRouteNameOverride,
          storesAddressNameOverride:
            this.response.trialOrder.eventId.storesAddressNameOverride,
          carryoverPartsOrdering:
            this.response.trialOrder.eventId.carryoverPartsOrdering,
          completionDate: this.response.trialOrder.eventId.completionDate,
          packagingNeed: this.response.trialOrder.eventId.packagingNeed,
          eventStatus: this.response.trialOrder.eventId.eventStatus,
          ringiNumber: this.response.trialOrder.eventId.ringiNumber,
          isNAQD: this.response.trialOrder.eventId.isNAQD,
          trialEventQuantityNameLst: [],
        },
        createdBy: this.userId,
        createTimestamp: this.response.trialOrder.createTimestamp,
        revisionDate: this.response.trialOrder.revisionDate,
      },
      quantityNames: this.defaultQuantities,
      quantityNamesQty: this.orderQuantities.map((item) => item.quantity),
    };
  }

  onSubmit() {
    const showerror = this.isInvalidFutureDate(this.supplierPickupDate);
    const validerror = this.validateQuantities();
    const validdateerror = this.validDate();

    if (!showerror || !validdateerror || !validerror) {
      return false;
    } else {
      return true;
    }
  }

  cancel() {
    const dialogRef = this.dialog.open(WarningPopupComponent, {
      disableClose: true,
      width: '570px',
      height: '219px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'Success') {
        this.router.navigate(['event-order/trial-orders/trialOrderList']);
      }
    });
  }

  onKeyDown(event: KeyboardEvent) {
    const inputValue = (event.target as HTMLInputElement).value;
    const allowedCharacters = /^\d*$/;
    const char = event.key;
    const allowedSpecialKeys = [
      'Backspace',
      'Delete',
      'ArrowLeft',
      'ArrowRight',
      'ArrowUp',
      'ArrowDown',
      'Tab',
      'Enter',
      'Escape',
    ];
    if (!allowedCharacters.test(char) && !allowedSpecialKeys.includes(char)) {
      event.preventDefault();
    }
    if (inputValue.length >= 6 && !allowedSpecialKeys.includes(char)) {
      event.preventDefault();
    }
  }

  onKeyDownPartNumber(event: KeyboardEvent) {
    const allowedCharacters = /^[a-zA-Z0-9/-]*$/;
    const char = event.key;
    if (!allowedCharacters.test(char)) {
      event.preventDefault();
    }
  }

  stopDateInput(event: KeyboardEvent) {
    event.preventDefault();
  }
}
