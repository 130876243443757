import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { WarningPopupComponent } from 'src/app/core/dialogs/warning-popup/warning-popup.component';
import { HomeService } from 'src/app/services/home.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-new-message',
  templateUrl: './new-message.component.html',
  styleUrls: ['./new-message.component.scss'],
})
export class NewMessageComponent implements OnInit {
  expirationDate = '';
  namcComment = '';
  MandatDate = true;
  MandatComment = true;
  userId: string | undefined;
  errorMessage = '';
  requiredDisplayCheck = true;

  constructor(
    private readonly homeService: HomeService,
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly dialog: MatDialog
  ) {}
  ngOnInit(): void {
    this.userService.userDetails?.subscribe({
      next: (user) => {
        this.userId = user?.userId;
      },
    });
  }

  cancel() {
    const dialogRef = this.dialog.open(WarningPopupComponent, {
      disableClose: true,
      width: '570px',
      height: '219px',
    });

    dialogRef.afterClosed()?.subscribe((result: string | undefined) => {
      if (result === undefined) {
        return;
      }
      if (result === 'Success') {
        window.history.back();
      }
    });
  }

  validateFields() {
    this.MandatDate = true;
    this.MandatComment = true;

    setTimeout(() => {
      this.MandatDate = true;
      this.MandatComment = true;
    }, 3000);
    if (!this.expirationDate) {
      this.MandatDate = false;
    }
    if (!this.namcComment) {
      this.MandatComment = false;
    }
    if (this.MandatComment && this.MandatDate) {
      this.submitMessage();
    }
  }

  submitMessage() {
    this.homeService
      .sendMessage(this.namcComment, this.expirationDate, this.userId ?? '')
      .subscribe({
        next: (_response: { result: string }) => {
          window.history.back();
        },
        error: () => {
          this.errorMessage =
            'Something went wrong. Please Contact System Administrator.';
        },
      });
  }
}
