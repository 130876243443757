import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Dropdown } from 'primeng/dropdown';
import { DropdownData } from 'src/app/core/model/dropdown-data.model';
import { User } from 'src/app/core/model/user.model';
import { RoleSelectorService } from 'src/app/services/role-selector.service';
import { UserService } from 'src/app/services/user.service';
import { SelectedRole } from '../../core/model/role-check.model';

@Component({
  selector: 'app-role-selector',
  templateUrl: './role-selector.component.html',
  styleUrls: ['./role-selector.component.scss'],
})
export class RoleSelectorComponent implements OnInit, AfterViewInit {
  @ViewChild('dropDownThing')
  dropDownThing!: Dropdown;
  options = [
    { value: 'namc', label: 'NAMC User' },
    { value: 'supplier', label: 'Supplier User' },
  ];
  namcData: DropdownData[] = [];
  namcList: DropdownData[] = [];
  supplierList: DropdownData[] = [];
  cSupplierList: DropdownData[] = [];
  selectedRole: SelectedRole = {
    role: undefined,
    namc: undefined,
    supplierCode: undefined,
    childSupplierCode: undefined,
  };
  roleSetNamc = false;
  roleSetSupplier = false;
  public loginForm: FormGroup = {} as FormGroup;
  userRole: 'namc' | 'supplier' | undefined = undefined;
  user: User | null = null;
  successMessage: string | undefined = undefined;
  errorMessage: string | undefined = undefined;
  selectText = '';
  cancelValue = false;
  requiredDisplayCheck = true;

  constructor(
    public formbuilder: FormBuilder,
    private readonly router: Router,
    private readonly changeref: ChangeDetectorRef,
    private readonly ngxUiLoaderService: NgxUiLoaderService,
    private readonly userService: UserService,
    private readonly roleService: RoleSelectorService,
    private readonly location: Location
  ) {}

  ngAfterViewInit(): void {
    if (
      this.selectedRole.supplierCode === undefined &&
      this.loginForm.controls['selectedValue'].value &&
      this.selectedRole.role !== 'namc'
    ) {
      if (this.dropDownThing !== undefined) {
        this.dropDownThing.disabled = true;
      }
    }
  }

  initialize() {
    this.selectedRole = JSON.parse(
      sessionStorage.getItem('roleDetails') ?? '{}'
    );
    this.loginForm = this.formbuilder.group({
      selectedValue: [
        this.selectedRole.role ? this.selectedRole.role : '',
        Validators.required,
      ],
      namcValue: [this.selectedRole.namc ? this.selectedRole.namc : ''],
      supplierValue: [
        this.selectedRole.supplierCode ? this.selectedRole.supplierCode : '',
      ],
      childSupplierValue: [
        this.selectedRole.childSupplierCode
          ? this.selectedRole.childSupplierCode
          : '',
      ],
    });
    this.roleSetNamc = this.selectedRole.role === 'namc';
    this.roleSetSupplier = this.selectedRole.role === 'supplier';
    if (this.roleSetNamc) {
      this.loadNamc();
    }
    if (this.roleSetSupplier) {
      this.loadSupplier();
      this.loadChildSupplier();
    }
  }

  ngOnInit(): void {
    this.initialize();
    this.userService.userDetails?.subscribe({
      next: (user) => {
        this.user = user;
        if (user === null) {
          return;
        }
        this.setUserRoles();
      },
    });
    this.cancelValue = this.userService.getCancelButtonValue();
  }

  setUserRoles() {
    const isSupplier =
      this.user !== null && Object.keys(this.user).length > 0
        ? this.user?.roles.find(
            (value) => value.roleDescription === 'Supplier'
          ) !== undefined
        : false;
    const isNamc =
      this.user !== null && Object.keys(this.user).length > 0
        ? this.user?.roles.find(
            (value) =>
              value.roleDescription !== 'Supplier' &&
              value.roleDescription !== 'null'
          ) !== undefined
        : false;
    if (isSupplier && isNamc) {
      this.userRole = undefined;
      this.selectText = 'Role';
    } else if (isSupplier) {
      this.userRole = 'supplier';
      this.selectText = 'Supplier';
      this.roleSetSupplier = true;
      this.loginForm.controls['selectedValue'].setValue(this.userRole);
      this.loadSupplier();
    } else if (isNamc) {
      if (
        this.user?.roles.length === 1 &&
        this.user.roles[0].namcCode !== 'null'
      ) {
        this.selectedRole.role = 'namc';
        this.selectedRole.namc = this.user.roles[0].namcCode;
        this.selectedRole.supplierCode = undefined;
        this.selectedRole.childSupplierCode = undefined;
        this.sendSubmit();
      } else {
        this.userRole = 'namc';
        this.selectText = 'NAMC';
        this.roleSetNamc = true;
        this.loginForm.controls['selectedValue'].setValue(this.userRole);
        this.loadNamc();
      }
    } else {
      this.router.navigate(['/no-access'], { state: { access: 'NO' } });
    }
    this.setValidators();
  }

  loadNamc() {
    this.ngxUiLoaderService.start('namcLoad');
    this.userService.userDetails?.subscribe({
      next: (user) => {
        this.user = user;
        if (user === null) {
          return;
        }
        this.namcList = [];
        this.user?.roles.forEach((role) => {
          if (role.namcShortName === 'ALL') {
            return;
          }
          if (
            (role.namcCode && role.namcShortName) ||
            (role.namcCode !== '' && role.namcShortName !== '')
          ) {
            this.namcList.push({
              code: role.namcCode,
              label: role.namcShortName,
            });
            this.namcList.sort((a, b) => a.label.localeCompare(b.label));
          }
        });
      },
    });
    this.ngxUiLoaderService.stop('namcLoad');
  }

  loadSupplier() {
    this.ngxUiLoaderService.start('supplierLoad');
    this.roleService.getSupplierList()?.subscribe({
      next: (result) => {
        this.ngxUiLoaderService.stop('supplierLoad');
        this.supplierList = [];
        result.forEach((value) => {
          this.supplierList.push({ code: value.mCode, label: value.code });
          this.supplierList.sort((a, b) => a.label.localeCompare(b.label));
        });
      },
      error: (err) => {
        this.ngxUiLoaderService.stop('supplierLoad');
        const error = err as HttpErrorResponse;
        this.errorMessage = error.error.error;
        setTimeout(() => {
          this.errorMessage = undefined;
          this.successMessage = undefined;
        }, 5000);
      },
    });
  }

  loadChildSupplier() {
    this.ngxUiLoaderService.start('childSupplierLoad');
    this.roleService
      .getChildSupplierList(this.loginForm.controls['supplierValue'].value)
      ?.subscribe({
        next: (result) => {
          this.ngxUiLoaderService.stop('childSupplierLoad');
          this.cSupplierList = [];
          this.cSupplierList.push({ code: '-1', label: 'ALL' });
          result.forEach((value) => {
            this.cSupplierList.push({ code: value, label: value });
          });
        },
        error: (err) => {
          this.ngxUiLoaderService.stop('childSupplierLoad');
          const error = err as HttpErrorResponse;
          this.errorMessage = error.error.error;
          setTimeout(() => {
            this.errorMessage = undefined;
            this.successMessage = undefined;
          }, 5000);
        },
      });
  }

  onRoleSelect(event: MatRadioChange) {
    this.roleSetNamc = event.value === 'namc';
    this.roleSetSupplier = event.value === 'supplier';
    if (this.roleSetNamc) {
      this.loadNamc();
      this.loginForm.controls['supplierValue'].reset();
      this.loginForm.controls['childSupplierValue'].reset();
    } else {
      this.loadSupplier();
      this.loginForm.controls['namcValue'].reset();
    }
    this.setValidators();
    if (this.roleSetSupplier) {
      this.changeref.detectChanges();
      this.dropDownThing.disabled = true;
    }
  }

  setNamcValidation() {
    this.loginForm.controls['supplierValue'].disable();
    this.loginForm.controls['childSupplierValue'].disable();
    this.loginForm.controls['namcValue'].enable();
    this.loginForm.controls['namcValue'].addValidators([Validators.required]);
    this.loginForm.controls['namcValue'].updateValueAndValidity();
  }

  setSupplierCodeValidation() {
    this.loginForm.controls['namcValue'].disable();
    this.loginForm.controls['supplierValue'].enable();
    this.loginForm.controls['supplierValue'].addValidators([
      Validators.required,
    ]);
    this.loginForm.controls['supplierValue'].updateValueAndValidity();
    this.loginForm.controls['childSupplierValue'].enable();
    this.loginForm.controls['childSupplierValue'].addValidators([
      Validators.required,
    ]);
    this.loginForm.controls['childSupplierValue'].updateValueAndValidity();
  }

  onSubmit() {
    this.loginForm.markAllAsTouched();
    if (this.loginForm.valid) {
      if (this.loginForm.controls['selectedValue'].value === 'namc') {
        this.selectedRole.role = 'namc';
        this.selectedRole.namc = this.loginForm.controls['namcValue'].value;
        this.selectedRole.supplierCode = undefined;
        this.selectedRole.childSupplierCode = undefined;
      } else if (
        this.loginForm.controls['selectedValue'].value === 'supplier'
      ) {
        this.selectedRole.role = 'supplier';
        this.selectedRole.namc = undefined;
        this.selectedRole.supplierCode =
          this.loginForm.controls['supplierValue'].value;
        this.selectedRole.childSupplierCode =
          this.loginForm.controls['childSupplierValue'].value;
      }
      this.sendSubmit();
    }
  }

  sendSubmit() {
    this.ngxUiLoaderService.start('sendSubmit');
    sessionStorage.setItem('roleDetails', JSON.stringify(this.selectedRole));
    this.roleService.setSelectedRoles(this.selectedRole)?.subscribe({
      next: (_result) => {
        this.ngxUiLoaderService.stop('sendSubmit');
        window.open(`${this.location.prepareExternalUrl('/home')}`, '_self');
      },
      error: (err) => {
        this.ngxUiLoaderService.stop('sendSubmit');
        const error = err as HttpErrorResponse;
        this.errorMessage = error.error.error;
        setTimeout(() => {
          this.errorMessage = undefined;
          this.successMessage = undefined;
        }, 5000);
      },
    });
  }

  setValidators() {
    if (this.roleSetNamc) {
      this.loginForm.controls['namcValue'].markAsUntouched();
      this.setNamcValidation();
    } else if (this.roleSetSupplier) {
      this.loginForm.controls['supplierValue'].markAsUntouched();
      this.setSupplierCodeValidation();
    }
  }

  onSupplierChange() {
    this.loginForm.controls['childSupplierValue'].reset();
    this.setSupplierCodeValidation();
    this.loadChildSupplier();
    this.dropDownThing.disabled = false;
  }

  cancel() {
    if (this.cancelValue) {
      this.router.navigate(['/home']);
    }
  }
}
