import { Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { SessionTimeoutService } from './services/session-timeout.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  title = 'tproms-ui';
  hideFooter = false;
  requiredDisplayCheck = false;

  constructor(
    private readonly router: Router,
    private readonly sessionTimeoutService: SessionTimeoutService
  ) {}

  ngOnInit() {
    const e = document.getElementById('footerheight')?.offsetHeight ?? 10;
    const h = document.getElementById('headerHeight')?.offsetHeight ?? 10;
    const t = document.getElementById('toolbarheight')?.offsetHeight ?? 10;
    localStorage.setItem('footerheight', e.toString());
    localStorage.setItem('headerHeight', h.toString());
    localStorage.setItem('toolbarheight', t.toString());
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd) // Only listen to NavigationEnd
      )
      .subscribe(() => {
        this.setRequiredFieldValue();
      });
    this.sessionTimeoutService.startTracking();
    this.sessionTimeoutService.LoggedOutStatus?.subscribe({
      next: (res) => {
        if (res) {
          const overlay = document.getElementById('overlay');
          if (overlay) {
            overlay.classList.add('active');
          }
        }
      },
    });
  }
  ngOnDestroy(): void {
    this.sessionTimeoutService.stopTracking();
  }

  ngAfterViewInit() {
    const t = document.getElementById('toolbarheight')?.offsetHeight ?? 10;
    localStorage.setItem('toolbarheight', t.toString());
  }
  setRequiredFieldValue() {
    const urlsToCheck = [
      'modify-attribute',
      'add-new-attribute',
      'modify-configuration',
      'create-new-event',
      'view-event',
      'edit-event',
      'manage-namc-documents/add',
      'manage-namc-documents/edit',
      'manage-online-training/add',
      'manage-online-training/edit',
      'manage-users/add',
      'manage-users/edit',
      'trial-orders',
      'select-roles',
      'uploadOrders',
      'contacts',
      'loadMassEmail',
      'new-message'
    ];
    this.requiredDisplayCheck = urlsToCheck.some((url) =>
      this.router.url.includes(url)
    );
  }
}
