<app-notification-message
  [errorMessage]="errorMessage"
  [successMessage]="successMessage"
>
</app-notification-message>
<form [formGroup]="createForm">
  <div class="main-container pb-3">
    <div class="mb-2 mt-1 fw-bold">Event Details</div>
    <div class="form-container createEventShadow">
      <div class="form-group">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="namcCode">NAMC CODE</label>
        </div>
        <input
          id="namcCode"
          [ngClass]="{'is-invalid': createForm.get('namcCode')?.touched && createForm.get('namcCode')?.invalid && createForm.get('namcCode')?.errors?.['required']}"
          formControlName="namcCode"
          disableSpecialCharacters
        />
        <div
          *ngIf="
            createForm.get('namcCode')?.touched &&
            createForm.get('namcCode')?.invalid
          "
        >
          <small *ngIf="createForm.get('namcCode')?.errors?.['required']"
            >NAMC CODE is required</small
          >
        </div>
      </div>
      <div class="form-group">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="projectCode">PROJECT CODE</label>
        </div>
        <input
          id="projectCode"
          type="text"
          [ngClass]="{'is-invalid': createForm.get('projectCode')?.touched && createForm.get('projectCode')?.invalid && createForm.get('projectCode')?.errors?.['required']}"
          formControlName="projectCode"
          disableSpecialCharacters
          maxlength="4"
        />
        <div
          *ngIf="
            createForm.get('projectCode')?.touched &&
            createForm.get('projectCode')?.invalid
          "
        >
          <small *ngIf="createForm.get('projectCode')?.errors?.['required']"
            >PROJECT CODE is required</small
          >
          <small *ngIf="createForm.get('projectCode')?.errors?.['maxlength']"
            >PROJECT CODE SHOULD BE UNDER 4 CHARACTERS</small
          >
        </div>
      </div>
      <div class="form-group">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="partsSource">PARTS SOURCE</label>
        </div>
        <div class="mt-1">
          <p-dropdown
            formControlName="partsSource"
            [options]="partsSourceCatalog"
            optionLabel="attributeDescription"
            optionValue="attributeValue"
            class="dropdownOptions"
            placeholder="(Select)"
            [virtualScroll]="true"
            scrollHeight="100px"
            [ngClass]="{'is-invalid': createForm.get('partsSource')?.touched && createForm.get('partsSource')?.invalid && createForm.get('partsSource')?.errors?.['required']}"
            [virtualScrollItemSize]="30"
          >
          </p-dropdown>
        </div>
        <div
          *ngIf="
            createForm.get('partsSource')?.touched &&
            createForm.get('partsSource')?.invalid
          "
        >
          <small *ngIf="createForm.get('partsSource')?.errors?.['required']"
            >PARTS SOURCE is required</small
          >
        </div>
      </div>
      <div class="form-group">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="eventName"
            >EVENT NAME
            <span class="text-primary fst-italic"
              >NAMC ProjectCode ProjectName</span
            ></label
          >
        </div>
        <input
          id="eventName"
          type="text"
          [ngClass]="{'is-invalid': createForm.get('eventName')?.touched && createForm.get('eventName')?.invalid && createForm.get('eventName')?.errors?.['required']}"
          formControlName="eventName"
          maxlength="30"
        />
        <div
          *ngIf="
            createForm.get('eventName')?.touched &&
            createForm.get('eventName')?.invalid
          "
        >
          <small *ngIf="createForm.get('eventName')?.errors?.['required']"
            >EVENT NAME is required</small
          >
          <small *ngIf="createForm.get('eventName')?.errors?.['maxlength']"
            >EVENT NAME SHOULD BE UNDER 30 CHARACTERS</small
          >
        </div>
      </div>
      <div class="form-group">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="eventDescription">EVENT DESCRIPTION</label>
        </div>
        <input
          id="eventDescription"
          type="text"
          [ngClass]="{'is-invalid': createForm.get('eventDescription')?.touched && createForm.get('eventDescription')?.invalid && createForm.get('eventDescription')?.errors?.['required']}"
          formControlName="eventDescription"
          maxlength="100"
        />
        <div
          *ngIf="
            createForm.get('eventDescription')?.touched &&
            createForm.get('eventDescription')?.invalid
          "
        >
          <small
            *ngIf="createForm.get('eventDescription')?.errors?.['required']"
            >EVENT DESCRIPTION is required</small
          >
          <small
            *ngIf="createForm.get('eventDescription')?.errors?.['maxlength']"
            >EVENT DESCRIPTION SHOULD BE UNDER 100 CHARACTERS</small
          >
        </div>
      </div>
      <div class="form-group">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="assignQpc">ASSIGN QPC TO TRIAL</label>
        </div>
        <div class="mt-1">
          <p-dropdown
            formControlName="assignQpc"
            [options]="assignQpcCatalog"
            optionLabel="description"
            optionValue="value"
            class="dropdownOptions"
            placeholder="(Select)"
          >
          </p-dropdown>
        </div>
        <div
          *ngIf="
            createForm.get('assignQpc')?.touched &&
            createForm.get('assignQpc')?.invalid
          "
        >
          <small *ngIf="createForm.get('assignQpc')?.errors?.['required']"
            >ASSIGN QPC TO TRIAL is required</small
          >
        </div>
      </div>
    </div>
    <div class="mb-2 mt-1 fw-bold">Shipment Details</div>
    <div class="form-container createEventShadow">
      <div class="form-group">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="shipmentLocation">SHIPMENT LOCATION</label>
        </div>
        <input
          id="shipmentLocation"
          type="text"
          [ngClass]="{'is-invalid': createForm.get('shipmentLocation')?.touched && createForm.get('shipmentLocation')?.invalid && createForm.get('shipmentLocation')?.errors?.['required']}"
          formControlName="shipmentLocation"
          disableSpecialCharacters
        />
        <div
          *ngIf="
            createForm.get('shipmentLocation')?.touched &&
            createForm.get('shipmentLocation')?.invalid
          "
        >
          <small
            *ngIf="createForm.get('shipmentLocation')?.errors?.['required']"
            >SHIPMENT LOCATION is required</small
          >
          <small
            *ngIf="createForm.get('shipmentLocation')?.errors?.['maxlength']"
            >SHIPMENT LOCATION SHOULD BE UNDER 30 CHARACTERS</small
          >
        </div>
      </div>
      <div class="form-group">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="shipmentAddress">SHIPMENT ADDRESS</label>
        </div>
        <textarea
          style="height: 65px; resize: none;"
          class="form-control"
          id="shipmentAddress"
          [ngClass]="{'is-invalid': createForm.get('shipmentAddress')?.touched && createForm.get('shipmentAddress')?.invalid && createForm.get('shipmentAddress')?.errors?.['required']}"
          formControlName="shipmentAddress"
          maxlength="150"  rows="3" cols="30"
        ></textarea>
        <div
          *ngIf="
            createForm.get('shipmentAddress')?.touched &&
            createForm.get('shipmentAddress')?.invalid
          "
        >
          <small *ngIf="createForm.get('shipmentAddress')?.errors?.['required']"
            >SHIPMENT ADDRESS is required</small
          >
          <small
            *ngIf="createForm.get('shipmentAddress')?.errors?.['maxlength']"
            >SHIPMENT ADDRESS SHOULD BE UNDER 150 CHARACTERS</small
          >
        </div>
      </div>
      <div class="form-group">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="shipmentCity">SHIPMENT CITY</label>
        </div>
        <input
          id="shipmentCity"
          type="text"
          formControlName="shipmentCity"
          [ngClass]="{'is-invalid': createForm.get('shipmentCity')?.touched && createForm.get('shipmentCity')?.invalid && createForm.get('shipmentCity')?.errors?.['required']}"
          maxlength="25"
        />
        <div
          *ngIf="
            createForm.get('shipmentCity')?.touched &&
            createForm.get('shipmentCity')?.invalid
          "
        >
          <small *ngIf="createForm.get('shipmentCity')?.errors?.['required']"
            >SHIPMENT CITY is required</small
          >
          <small *ngIf="createForm.get('shipmentCity')?.errors?.['maxlength']"
            >SHIPMENT CITY SHOULD BE UNDER 20 CHARACTERS</small
          >
        </div>
      </div>
      <div class="form-group">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="shipmentState">SHIPMENT STATE/PROVINCE</label>
        </div>
        <input
          id="shipmentState"
          type="text"
          [ngClass]="{'is-invalid': createForm.get('shipmentState')?.touched && createForm.get('shipmentState')?.invalid && createForm.get('shipmentState')?.errors?.['required']}"
          formControlName="shipmentState"
          maxlength="4"
        />
        <div
          *ngIf="
            createForm.get('shipmentState')?.touched &&
            createForm.get('shipmentState')?.invalid
          "
        >
          <small *ngIf="createForm.get('shipmentState')?.errors?.['required']"
            >SHIPMENT STATE/PROVINCE is required</small
          >
          <small *ngIf="createForm.get('shipmentState')?.errors?.['maxlength']"
            >SHIPMENT STATE/PROVINCE should be only 4 charaters</small
          >
        </div>
      </div>
      <div class="form-group">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="shipmentCountry">SHIPMENT COUNTRY</label>
        </div>
        <div class="mt-1">
          <p-dropdown
            formControlName="shipmentCountry"
            [options]="shipmentCountryCatalog"
            optionLabel="attributeDescription"
            optionValue="attributeValue"
            class="dropdownOptions"
            placeholder="(Select)"
          >
          </p-dropdown>
        </div>
        <div
          *ngIf="
            createForm.get('shipmentCountry')?.touched &&
            createForm.get('shipmentCountry')?.invalid
          "
        >
          <small *ngIf="createForm.get('shipmentCountry')?.errors?.['required']"
            >SHIPMENT COUNTRY is required</small
          >
        </div>
      </div>
      <div class="form-group">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="shipmentZip">SHIPMENT ZIP</label>
        </div>
        <input
          id="shipmentZip"
          type="text"
          [ngClass]="{'is-invalid': createForm.get('shipmentZip')?.touched && createForm.get('shipmentZip')?.invalid && createForm.get('shipmentZip')?.errors?.['required']}"
          formControlName="shipmentZip"
          disableSpecialCharacters
          maxlength="20"
        />
        <div
          *ngIf="
            createForm.get('shipmentZip')?.touched &&
            createForm.get('shipmentZip')?.invalid
          "
        >
          <small *ngIf="createForm.get('shipmentZip')?.errors?.['required']"
            >SHIPMENT ZIP is required</small
          >
          <small *ngIf="createForm.get('shipmentZip')?.errors?.['maxlength']"
            >SHIPMENT ZIP SHOULD BE UNDER 20 CHARACTERS</small
          >
        </div>
      </div>
      <div class="form-group">
        <div class="label-container">
          <label for="shipmentScheduled">SHIPMENT SCHEDULED TRACKING</label>
        </div>
        <p-dropdown
          formControlName="shipmentScheduled"
          [options]="shipmentScheduledCatalog"
          optionLabel="label"
          optionValue="value"
          class="dropdownOptions"
          placeholder="(Select)"
        >
        </p-dropdown>
      </div>
    </div>
    <div class="mb-2 mt-1 fw-bold">Order Details</div>
    <div class="form-container createEventShadow">
      <div class="form-group">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="mainRouteNameOverride">MAINROUTE NAME OVERRIDE</label>
        </div>
        <input
          id="mainRouteNameOverride"
          type="text"
          formControlName="mainRouteNameOverride"
          disableSpecialCharacters
          maxlength="6"
        />
      </div>
      <div class="form-group">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="subRouteNameOverride">SUBROUTE NAME OVERRIDE</label>
        </div>
        <input
          id="subRouteNameOverride"
          type="text"
          formControlName="subRouteNameOverride"
          disableSpecialCharacters
          maxlength="6"
        />
      </div>
      <div
        class="form-group"
        *ngIf="createForm.get('namcCode')?.value === '71NPE'"
      >
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="ringiNumber">RINGI NUMBER</label>
        </div>
        <div class="mt-1">
          <p-dropdown
            formControlName="ringiNumber"
            [options]="ringiNumberCatalog"
            optionLabel="ringiNumber"
            optionValue="ringiNumber"
            class="dropdownOptions"
            [filter]="true"
            placeholder="(Select)"
            [ngClass]="{'is-invalid': createForm.get('ringiNumber')?.touched && createForm.get('ringiNumber')?.invalid && createForm.get('ringiNumber')?.errors?.['required']}"
          >
          </p-dropdown>
        </div>
        <div
          *ngIf="
            createForm.get('ringiNumber')?.touched &&
            createForm.get('ringiNumber')?.invalid
          "
        >
          <small *ngIf="createForm.get('ringiNumber')?.errors?.['required']"
            >RINGI NUMBER is required</small
          >
        </div>
      </div>
      <div class="form-group" *ngIf="selectedRoleDetail.namc != '71NPE'">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="costCenter">COST CENTER</label>
        </div>
        <input
          id="costCenter"
          type="text"
          formControlName="costCenter"
          [ngClass]="{'is-invalid': createForm.get('costCenter')?.touched && createForm.get('costCenter')?.invalid && createForm.get('costCenter')?.errors?.['required']}"
          disableSpecialCharacters
          maxlength="10"
        />
        <div
          *ngIf="
            createForm.get('costCenter')?.touched &&
            createForm.get('costCenter')?.invalid
          "
        >
          <small *ngIf="createForm.get('costCenter')?.errors?.['required']"
            >COST CENTER is required</small
          >
        </div>
      </div>
      <div class="form-group">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="dockCode">DOCK CODE</label>
        </div>
        <input
          id="dockCode"
          type="text"
          formControlName="dockCode"
          maxlength="2"
          [ngClass]="{'is-invalid': createForm.get('dockCode')?.touched && createForm.get('dockCode')?.invalid && createForm.get('dockCode')?.errors?.['required']}"
          disableSpecialCharacters
        />
        <div
          *ngIf="
            createForm.get('dockCode')?.touched &&
            createForm.get('dockCode')?.invalid
          "
        >
          <small *ngIf="createForm.get('dockCode')?.errors?.['required']"
            >DOCK CODE is required</small
          >
          <small *ngIf="createForm.get('dockCode')?.errors?.['maxlength']"
            >DOCK CODE SHOULD BE UNDER 2 CHARACTERS</small
          >
        </div>
      </div>
      <div class="form-group">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="orderType">ORDER TYPE</label>
        </div>
        <div class="mt-1">
          <p-dropdown
            formControlName="orderType"
            [options]="orderTypeCatalog"
            optionLabel="eventDescription"
            optionValue="eventTypeId"
            class="dropdownOptions"
            placeholder="(Select)"
          >
          </p-dropdown>
        </div>
        <div
          *ngIf="
            createForm.get('orderType')?.touched &&
            createForm.get('orderType')?.invalid
          "
        >
          <small *ngIf="createForm.get('orderType')?.errors?.['required']"
            >ORDER TYPE is required</small
          >
        </div>
      </div>
      <div class="form-group">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="orderNumberSeries"
            >ORDER NUMBER SERIES
            <span class="fst-italic text-primary"
              >See Format Matrix</span
            ></label
          >
        </div>
        <input
          id="orderNumberSeries"
          type="text"
          [ngClass]="{'is-invalid': createForm.get('orderNumberSeries')?.touched && createForm.get('orderNumberSeries')?.invalid && createForm.get('orderNumberSeries')?.errors?.['required']}"
          formControlName="orderNumberSeries"
          disableSpecialCharacters
        />
        <div
          *ngIf="
            createForm.get('orderNumberSeries')?.touched &&
            createForm.get('orderNumberSeries')?.invalid
          "
        >
          <small
            *ngIf="createForm.get('orderNumberSeries')?.errors?.['required']"
            >ORDER NUMBER SERIES is required</small
          >
          <small
            *ngIf="createForm.get('orderNumberSeries')?.errors?.['maxlength']"
            >ORDER NUMBER SERIES SHOULD BE UNDER 6 CHARACTERS</small
          >
        </div>
      </div>
      <div class="form-group">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="trialOrderSpecialistCode"
            >TRIAL ORDER SPECIALIST CODE</label
          >
        </div>
        <input
          id="trialOrderSpecialistCode"
          type="text"
          [ngClass]="{'is-invalid': createForm.get('trialOrderSpecialistCode')?.touched && createForm.get('trialOrderSpecialistCode')?.invalid && createForm.get('trialOrderSpecialistCode')?.errors?.['required']}"
          formControlName="trialOrderSpecialistCode"
          (keydown)="onKeyDown($event)"
          [readonly]="disableSpecialistCode"
        />
        <div
          *ngIf="
            createForm.get('trialOrderSpecialistCode')?.touched &&
            createForm.get('trialOrderSpecialistCode')?.invalid
          "
        >
          <small
            *ngIf="createForm.get('trialOrderSpecialistCode')?.errors?.['required']"
            >TRIAL ORDER SPECIALIST CODE is required</small
          >
          <small
            *ngIf="createForm.get('trialOrderSpecialistCode')?.errors?.['maxlength']"
            >TRIAL ORDER SPECIALIST CODE should be under 2 digits</small
          >
        </div>
      </div>
      <div class="form-group">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="carryOverParts">CARRY OVER PARTS ORDERING ALLOWED</label>
        </div>
        <div class="mt-2">
          <p-dropdown
            formControlName="carryOverParts"
            [options]="carryOverPartsCatalog"
            optionLabel="description"
            optionValue="value"
            class="dropdownOptions"
            placeholder="(Select)"
          >
          </p-dropdown>
        </div>
        <div
          *ngIf="
            createForm.get('carryOverParts')?.touched &&
            createForm.get('carryOverParts')?.invalid
          "
        >
          <small *ngIf="createForm.get('carryOverParts')?.errors?.['required']"
            >CARRY OVER PARTS ORDERING ALLOWED is required</small
          >
        </div>
      </div>
      <div class="form-group">
        <div class="label-container">
          <label for="packingNeed">PACKAGING NEED</label>
        </div>
        <div class="mt-3">
          <p-dropdown
            formControlName="packingNeed"
            [options]="packingNeedCatalog"
            optionLabel="attributeDescription"
            optionValue="attributeValue"
            class="dropdownOptions"
            placeholder="(Select)"
          >
          </p-dropdown>
        </div>
        <div
          *ngIf="
            createForm.get('packingNeed')?.touched &&
            createForm.get('packingNeed')?.invalid
          "
        >
          <small *ngIf="createForm.get('packingNeed')?.errors?.['required']"
            >PACKAGING NEED is required</small
          >
        </div>
      </div>
      <div class="form-group">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="rows">ORDER QUANTITY NAMES</label>
        </div>
        <div [formGroup]="createForm">
          <table aria-label="create-event">
            <thead>
              <tr>
                <th>Order #</th>
                <th>Name</th>
                <th *ngIf="!isDisabled">
                  <button class="quantity-btn" (click)="addRow()">+</button>
                </th>
              </tr>
            </thead>
            <tbody formArrayName="rows">
              <tr
                *ngFor="let row of rows.controls; let i = index"
                [formGroupName]="i"
              >
                <td>{{ i + 1 }}</td>
                <td>
                  <input
                    formControlName="value"
                    type="text"
                    oninput="this.value = this.value.toUpperCase()"
                    disableSpecialCharacters
                    maxlength="20"
                  />
                </td>
                <td *ngIf="!isDisabled">
                  <button
                    class="quantity-btn"
                    *ngIf="i > 3"
                    (click)="deleteRow(i)"
                  >
                    <fa-icon
                      [icon]="faTrashCan"
                      title="Delete Quantity"
                      class="d-flex justify-content-center"
                      style="color: #707070"
                    ></fa-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div *ngIf="showErrorMessage">
          <small>You can only Add 5 additional Quantity names </small>
        </div>
      </div>
      <div class="form-group">
        <div class="label-container">
          <div class="vertical-bar"></div>
          <label for="logisticsProvider">LOGISTICS PROVIDER</label>
        </div>
        <div class="mt-1">
          <p-dropdown
            formControlName="logisticsProvider"
            [options]="logisticsProviderCatalog"
            optionLabel="attributeDescription"
            optionValue="attributeValue"
            class="dropdownOptions"
            placeholder="(Select)"
            [virtualScroll]="true"
            [ngClass]="{'is-invalid': createForm.get('logisticsProvider')?.touched && createForm.get('logisticsProvider')?.invalid && createForm.get('logisticsProvider')?.errors?.['required']}"
            scrollHeight="200px"
            [virtualScrollItemSize]="10"
          >
          </p-dropdown>
        </div>
        <div
          *ngIf="
            createForm.get('logisticsProvider')?.touched &&
            createForm.get('logisticsProvider')?.invalid
          "
        >
          <small
            *ngIf="createForm.get('logisticsProvider')?.errors?.['required']"
            >LOGISTICS PROVIDER is required</small
          >
        </div>
      </div>
      <div class="form-group">
        <div class="label-container">
          <label for="recieveOrderNAQDyES">RECEIVE ORDER NAQD MTPROMS </label>
        </div>
        <div class="fakeClass">
          <input
            type="radio"
            name="radioGroup"
            [disabled]="isDisabled"
            [checked]="selectedOption === 'YES'"
            (click)="toggleSelection('YES')"
            value="YES"
            (keydown)="toggleSelection('YES')"
          />
          <div class="mt-2">YES</div>
          <input
            type="radio"
            name="radioGroup"
            [disabled]="isDisabled"
            [checked]="selectedOption === 'NO'"
            (click)="toggleSelection('NO')"
            value="NO"
            (keydown)="toggleSelection('NO')"
          />
          <div class="mt-2">NO</div>
        </div>
      </div>
    </div>
  </div>
  <footer class="footer">
    <button class="cancel-button" (click)="cancel()">CANCEL</button>

    <div class="button-container">
      <button class="b2-button add" *ngIf="!isView" (click)="onSubmit()">
        SUBMIT
      </button>
    </div>
  </footer>
</form>
