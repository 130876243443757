import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, catchError, forkJoin, of, switchMap } from 'rxjs';
import { TrialOrdersService } from 'src/app/services/event-and-orders/trial-orders.service';
import { UploadOrderSupplierService } from 'src/app/services/upload-orders-supplier/upload-orders-supplier.service';

@Component({
  selector: 'app-supplier-mail',
  templateUrl: './supplier-mail.component.html',
  styleUrls: ['./supplier-mail.component.scss'],
})
export class SupplierMailComponent implements OnInit {
  form!: FormGroup;
  @ViewChild('fileUpload') myInputVariable: ElementRef | undefined;
  enableCC = false;
  enableFrom = false;
  faDelete = faTrash;
  uploadFileName: string[] = [];
  fileList: File[] = [];
  errorMessage: string | undefined = undefined;
  payloadFileKeys: string[] = [];
  isDisabled = false;

  constructor(
    public dialogRef: MatDialogRef<SupplierMailComponent>,
    @Inject(MAT_DIALOG_DATA) public dataStore: string[],
    private readonly fb: FormBuilder,
    private readonly ngxUiLoaderService: NgxUiLoaderService,
    private readonly trialOrdersService: TrialOrdersService,
    private readonly uploadOrdersService: UploadOrderSupplierService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      from: new FormControl({ value: '', disabled: true }, Validators.required),
      to: new FormControl({ value: '', disabled: true }),
      subject: ['', Validators.required],
      addCC: new FormControl(false),
      addFrom: new FormControl(false),
      cc: new FormControl({ value: '', disabled: true }, [this.multipleEmailValidator()]),
      files: '',
      message: ['', Validators.required],
    });
    this.form.get('addCC')?.valueChanges.subscribe((checked) => {
      if (checked) {
        this.form.get('cc')?.enable();
      } else {
        this.form.get('cc')?.disable();
      }
    });
    this.form.get('addFrom')?.valueChanges.subscribe((checked) => {
      if (checked) {
        this.form.get('from')?.enable();
      } else {
        this.form.get('from')?.disable();
      }
    });
    this.form.patchValue({
      to: 'this.dataStore.selectedRow',
    });
  }

  multipleEmailValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const emailInput: string = control.value;

      if (!emailInput) {
        return null;
      }

      const emails = emailInput.split(',').map((email) => email.trim());

      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

      const invalidEmails = emails.filter((email) => !emailPattern.test(email));

      if (invalidEmails.length > 0) {
        return { invalidEmails: invalidEmails };
      }

      return null;
    };
  }

  get isCCInvalid() {
    const ccControl = this.form.get('cc');
    return ccControl?.invalid && (ccControl?.touched || ccControl?.dirty);
  }

  close() {
    this.dialogRef.close({ status: false, outcome: '' });
  }

  sendNotification() {
    if(this.isDisabled){
      return;
    }
    this.isDisabled = true;
    if (this.fileList.length > 0) {
      this.ngxUiLoaderService.start('Loader-01');
      const uploadTasks = this.fileList.map((file) => {
        const originalFileName = file.name;
        const fileType = originalFileName.substring(
          originalFileName.lastIndexOf('.') + 1
        );
        const fileName = `sendSupplier/${originalFileName}`;

        return this.uploadOrdersService.uploadFile(fileName, fileType).pipe(
          switchMap((presignedUrl) =>
            this.uploadToS3(presignedUrl.url, '', file)
          ),
          catchError((err) => {
            this.ngxUiLoaderService.stop('Loader-01');
            this.setErrorMessage(`Error uploading file ${file.name}: ${err}`);
            return of(null);
          })
        );
      });

      forkJoin(uploadTasks).subscribe({
        next: (_responses) => {
          this.ultimateSendNotification();
        },
        error: (_err) => {
          this.ngxUiLoaderService.stop('Loader-01');
          this.setErrorMessage('Something Went Wrong');
        },
      });
    } else {
      this.ngxUiLoaderService.stop('Loader-01');
      this.ultimateSendNotification();
    }
  }

  onFileSelected(event: Event) {
    if (this.uploadFileName.length >= 5) {
      this.setErrorMessage('Maximum 5 Files Can Be Selected');
      return;
    }
    const input = event.target as HTMLInputElement;
    if (input.files) {
      const filesArray = Array.from(input.files);
      for (const file of filesArray) {
        this.fileList.push(file);
        this.uploadFileName.push(file.name);
        this.payloadFileKeys.push(`sendSupplier/${file.name}`);
      }
    }
  }

  removeFile(fileName: string) {
    this.uploadFileName = this.uploadFileName.filter(
      (element) => element !== fileName
    );
    this.payloadFileKeys = this.payloadFileKeys.filter(
      (element) => element.split('/')[1] !== fileName
    );
  }

  onSubmit() {
    if (this.form.valid) {
      this.sendNotification();
    } else {
      this.form.markAllAsTouched();
    }
  }

  uploadToS3(presignedUrl: string, fileName: string, file: File) {
    return new Observable((observer) => {
      const fileInput = file;
      const renamedFile = new File([fileInput], fileName, {
        type: fileInput.type,
        lastModified: fileInput.lastModified,
      });
      axios
        .put(presignedUrl, renamedFile)
        .then((response) => {
          observer.next(response.data);
          observer.complete();
        })
        .catch((error) => {
          observer.error(error);
        });
    });
  }

  ultimateSendNotification() {
    const payloadObj = {
      from: this.form.value.from,
      subject: this.form.value.subject,
      message: this.form.value.message,
      appendedOrders: this.dataStore,
      emailIdList: this.form.value.cc ? (this.form.value.cc).split(',') : [],
      fileList: this.payloadFileKeys,
    };
    this.trialOrdersService.sendMailNotification(payloadObj)?.subscribe({
      next: (result) => {
        this.ngxUiLoaderService.stop('Loader-01');
        this.isDisabled = false;
        this.dialogRef.close({ status: true, outcome: result });
      },
      error: (err: Error) => {
        this.ngxUiLoaderService.stop('Loader-01');
        this.isDisabled = false;
        const error = err as HttpErrorResponse;
        this.setErrorMessage(error.error.error);
      },
    });
  }

  setErrorMessage(errore: string) {
    this.errorMessage = errore;
    setTimeout(() => {
      this.errorMessage = undefined;
    }, 5000);
  }
}
