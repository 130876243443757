import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { WarningPopupComponent } from 'src/app/core/dialogs/warning-popup/warning-popup.component';
import { SupplierListMap } from 'src/app/core/model/performance.model';
import { AppendOrderModel } from 'src/app/core/model/trial-order.model';
import { User } from 'src/app/core/model/user.model';
import { CreateTrailsService } from 'src/app/services/event-and-orders/create-trails.service';
import { PerformanceService } from 'src/app/services/suppliers/performance.service';
import { UserService } from 'src/app/services/user.service';
@Component({
  selector: 'app-view-edit',
  templateUrl: './view-edit.component.html',
  styleUrls: ['./view-edit.component.scss'],
})
export class ViewEditComponent implements OnInit {
  data: any;
  ordernumber: string | undefined;
  trackingnumber = '';
  psmsRouting = '';
  supplierCodes: SupplierListMap[] = [];
  suppliercode = '';
  suppliername = '';
  comment = '';
  kanbanNumber = '';
  partNumber = '';
  partName = '';
  plant1Qty = 0;
  plant2Qty = 0;
  plant3Qty = 0;
  totalQuantity = '';
  storeAddress = '';
  lineSideAdress = '';
  specialistName = '';
  specialistCode = '';
  namccomment = '';
  supplierComment = '';
  scanComment = '';
  namcDate = '';
  disableSave = false;
  isSaveDisabled = false;
  supplierPickupDate = '';
  shippingDateTime = '';
  manifestNumber = '';
  deliveryduedate = '';
  lifeCycleCode = '';
  sendToSapFlag = '';
  poLineNo = '';
  aribaPrNumber = '';
  aribaOrderStatus = '';
  aribaOrderComment = '';
  palletCode = '';
  workOrderNumber = '';
  user: User | null = null;
  mandatoryFieldError = 'This field is mandatory';
  orderQuantities: any[] = [];
  constructor(
    private readonly router: Router,
    private readonly datePipe: DatePipe,
    private readonly userService: UserService,
    private readonly CreateTrailsService: CreateTrailsService,
    private readonly performanceService: PerformanceService,
    private readonly ngxUiLoaderService: NgxUiLoaderService,
    private readonly dialog: MatDialog
  ) {}

  isViewMode = false;
  POMode = false;
  userId!: string;
  editMode = false;
  response: AppendOrderModel = {} as AppendOrderModel;
  defaultQuantities: string[] = [];
  errorMessage: string | undefined;
  mandatoryFieldErrors = {
    partNumber: '',
    partName: '',
    supplierCode: '',
    totalQuantity: '',
  };
  dateErrors = {
    supplierDeliveryDate: '',
    supplierPickupDate: '',
  };
  totalErrorMessage = '';
  namcCode = false;
  currentRole = '';
  viewSuppMode = false;
  validateQuantities(): boolean {
    let isValid = true;
    const hasValidQuantity = this.orderQuantities.some(
      (item) => item.quantity > 0
    );

    if (!hasValidQuantity) {
      this.errorMessage = 'At least 1 Quantity Name is Required';
      isValid = false;
    } else {
      this.errorMessage = undefined;
    }

    this.mandatoryFieldErrors.partNumber = this.partNumber.trim()
      ? ''
      : this.mandatoryFieldError;
    this.mandatoryFieldErrors.partName = this.partName.trim()
      ? ''
      : this.mandatoryFieldError;
    this.mandatoryFieldErrors.supplierCode = this.suppliercode.trim()
      ? ''
      : this.mandatoryFieldError;
    this.mandatoryFieldErrors.totalQuantity = this.totalQuantity.trim()
      ? ''
      : this.mandatoryFieldError;
    if (
      !this.partNumber.trim() ||
      !this.partName.trim() ||
      !this.suppliercode.trim() ||
      !this.totalQuantity?.trim()
    ) {
      isValid = false;
    }

    if (Number(this.totalQuantity) !== this.calculateTotalQuantity()) {
      this.totalErrorMessage =
        'Total Qty must match the sum of all Quantity Names values';
      isValid = false;
    } else {
      this.totalErrorMessage = '';
    }

    return isValid;
  }
  calculateTotalQuantity(): number {
    return this.orderQuantities.reduce(
      (sum, item) => sum + Number(item.quantity || 0),
      0
    );
  }
  isInvalidFutureDate(_date: string) {
    this.dateErrors = this.dateErrors || {};
    let isValid = true;
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const supplierPickupDate = new Date(this.supplierPickupDate);
    const orderDeliveryDate = new Date(this.deliveryduedate);
    if (supplierPickupDate <= currentDate) {
      this.dateErrors.supplierPickupDate =
        'Selected date is invalid (it can not be present or past date)';
      isValid = false;
    } else if (supplierPickupDate > orderDeliveryDate) {
      this.dateErrors.supplierPickupDate =
        'Supplier pickup date should be less than or same as the NAMC due date';
      isValid = false;
    } else {
      this.dateErrors.supplierPickupDate = '';
    }
    if (orderDeliveryDate <= currentDate) {
      this.dateErrors.supplierDeliveryDate =
        'Selected date is invalid (it can not be present or past date)';
      isValid = false;
    } else {
      this.dateErrors.supplierDeliveryDate = '';
    }
    return isValid;
  }
  ngOnInit(): void {
    this.data = JSON.parse(sessionStorage.getItem('gridData') as string);

    this.CreateTrailsService.getQuantityNames(this.data.eventId).subscribe(
      (res) => {
        this.response = res;
        this.mergeQuantities(
          res.trialEventQuantityNameLst,
          this.data.quantityValues
        );
        this.defaultQuantities = res.trialEventQuantityNameLst.map(
          (element) => {
            return element.qNameBox;
          }
        );
      }
    );

    this.userService.selectedRoles.subscribe((selectedRoles) => {
      this.currentRole = selectedRoles?.namc ?? '';
    });
    if (sessionStorage.getItem('roleName') === 'Supplier') {
      this.viewSuppMode = true;
    }
    if (this.data.orderType === 'PO') {
      this.POMode = true;
    }
    if (sessionStorage.getItem('mode') === 'view') {
      this.isViewMode = true;
      this.mapData();
    } else if (sessionStorage.getItem('mode') === 'edit') {
      this.isViewMode = false;
      this.editMode = true;
      this.userService.userDetails?.subscribe((value) => {
        if (value !== null) {
          this.userId = value.userId;
        }
      });
      this.fetchSupplierCodes();
      this.checkDisableSave();
      this.mapData();
    }
  }
  mergeQuantities(
    trialEventQuantityNameLst: any[],
    quantityValues: any[]
  ): void {
    const trialEventQuantities = trialEventQuantityNameLst.map((element) => {
      return {
        name: element.inputBox,
        id: element.qNameBox,
      };
    });

    const parsedQuantityValues =
      typeof quantityValues === 'string'
        ? JSON.parse(quantityValues)
        : quantityValues;

    const mergedQuantities = trialEventQuantities.map((quantity) => {
      const matchingItem = parsedQuantityValues?.find(
        (item: { qtyName: any }) => item.qtyName === quantity.name
      );

      return {
        name: quantity.name,
        quantity: matchingItem ? Number(matchingItem.qtyOrder) || 0 : 0,
        id: quantity.id,
      };
    });

    parsedQuantityValues?.forEach(
      (item: { qtyName: any; qtyOrder: any; eventCategoryId: any }) => {
        if (
          !trialEventQuantities.some(
            (quantity) => quantity.name === item.qtyName
          )
        ) {
          mergedQuantities.push({
            name: item.qtyName,
            quantity: Number(item.qtyOrder) || 0,
            id: item.eventCategoryId,
          });
        }
      }
    );

    // Assign the merged quantities to 'orderQuantities'
    this.orderQuantities = mergedQuantities;
  }

  toggleSelection(option: string) {
    if (this.sendToSapFlag === option) {
      this.sendToSapFlag = 'YES';
    } else {
      this.sendToSapFlag = option;
    }
  }

  checkDisableSave(): void {
    if (!this.disableSave) {
      if (
        this.data.status === 'Completed' ||
        this.data.status === 'Firmed' ||
        this.data.status === 'Cancelled' ||
        this.data.status === 'Awaiting_Labels' ||
        this.data.status === 'Manifest_Created'
      ) {
        this.disableSave = true;
      } else {
        this.disableSave = false;
      }
    }
    if (this.data.manifestNumber && this.data.manifestNumber.trim() !== '') {
      this.disableSave = true;
    }
    this.isSaveDisabled = this.disableSave;
  }

  fetchSupplierCodes(): void {
    this.performanceService.suppliersDropDown()?.subscribe({
      next: (res) => {
        this.supplierCodes = res;
      },
      error: (_error) => {
        this.errorMessage = 'Error fetching supplier codes';
      },
    });
  }
  onSupplierCodeChange(selectedCode: string): void {
    const selectedSupplier = this.supplierCodes.find(
      (supplier) => supplier.supplierCode === selectedCode
    );
    this.suppliername = selectedSupplier ? selectedSupplier.supplierName : '';
  }
  mapData() {
    this.trackingnumber = this.data?.poTrackingNumber;
    this.ordernumber = this.data?.orderNumber;
    this.psmsRouting = this.data?.psmsRouting;
    this.suppliercode = this.data?.supplierCd;
    this.suppliername = this.data?.supplierName;
    this.kanbanNumber = this.data?.kanbanNumber;
    this.partNumber = this.data?.partNumber;
    this.partName = this.data?.partName;
    this.plant1Qty = this.data?.plant1Qty;
    this.plant2Qty = this.data?.plant2Qty;
    this.plant3Qty = this.data?.plant3Qty;
    this.totalQuantity = this.data?.totalQty;
    this.lineSideAdress = this.data?.lineSideAddress;
    this.storeAddress = this.data?.storeAddress;
    this.specialistName = this.data?.specialistName;
    this.specialistCode = this.data?.specialistCd;
    this.namccomment = this.data?.namcComment;
    this.supplierComment = this.data?.supplierComment;
    this.scanComment = this.data?.scanComment;
    this.namcDate = this.data?.orderDueDate;
    this.deliveryduedate = this.convertToDateFormat(this.data?.orderDueDate);
    this.supplierPickupDate = this.convertToDateFormat(
      this.data.supplierPickupDate
    );
    this.shippingDateTime = this.data?.promiseDate;
    this.manifestNumber = this.data?.manifestNumber;
    this.lifeCycleCode = this.data?.lifeCycleCode;
    this.sendToSapFlag = this.data?.sendToSapFlag === 'Y' ? 'YES' : 'NO';
    this.poLineNo = this.data?.poLineNo;
    this.aribaPrNumber = this.data?.aribaPrNumber;
    this.aribaOrderStatus = this.data?.aribaOrderStatus;
    this.aribaOrderComment = this.data?.aribaOrderComment;
    this.palletCode = this.data?.palletCode;
    this.workOrderNumber =
      this.currentRole === '26MTM' ? this.data?.workOrderNumber : '';
    this.comment = this.data?.namcComment;
  }
  onKeyDown(event: KeyboardEvent) {
    const inputValue = (event.target as HTMLInputElement).value;
    const allowedCharacters = /^\d*$/;
    const char = event.key;
    const allowedSpecialKeys = [
      'Backspace',
      'Delete',
      'ArrowLeft',
      'ArrowRight',
      'ArrowUp',
      'ArrowDown',
      'Tab',
      'Enter',
      'Escape',
    ];
    if (!allowedCharacters.test(char) && !allowedSpecialKeys.includes(char)) {
      event.preventDefault();
    }
    if (inputValue.length >= 6 && !allowedSpecialKeys.includes(char)) {
      event.preventDefault();
    }
  }
  stopDateInput(event: KeyboardEvent) {
    event.preventDefault();
  }

  convertToDateFormat(date: string): string {
    const [month, day, year] = date.split('/');
    return `${year}-${month?.padStart(2, '0')}-${day?.padStart(2, '0')}`;
  }

  onSubmit(): void {
    if (!this.validateQuantities()) {
      return;
    }
    if (!this.isInvalidFutureDate(this.supplierPickupDate)) {
      return;
    }
    if (!this.isInvalidFutureDate(this.deliveryduedate)) {
      return;
    }
    const payload = {
      trialOrder: {
        orderNumber: this.ordernumber,
        storeAddress: this.storeAddress,
        lineSideAddress: this.lineSideAdress,
        kanbanNumber: this.kanbanNumber,
        poTrackingNumber: this.trackingnumber,
        partName: this.partName,
        partNumber: this.partNumber,
        updatedBy: this.userId,
        supplierCode: this.suppliercode,
        supplierName: this.suppliername,
      },
      appendTotalOrderQty: this.totalQuantity,
      psmsToRouting: this.psmsRouting,
      appendWorkOrderNumber: this.workOrderNumber,
      appendLifeCycleCode: this.lifeCycleCode,
      appendSendToSapFlag: this.sendToSapFlag === 'YES' ? 'Y' : 'N',
      appendComment: this.comment,
      appendSupplierPickupDate: this.datePipe.transform(
        this.supplierPickupDate,
        'MM/dd/yyyy'
      ),
      appendDeliveryDate: this.datePipe.transform(
        this.deliveryduedate,
        'MM/dd/yyyy'
      ),
      quantityNames: this.orderQuantities.map((item) => item.id),
      quantityNamesQty: this.orderQuantities.map((item) => item.quantity),
      appendPlant1Qty: this.plant1Qty,
      appendPlant2Qty: this.plant2Qty,
      appendPlant3Qty: this.plant3Qty,
    };
    this.ngxUiLoaderService.start('loader-01');
    this.CreateTrailsService.editOrder(payload).subscribe({
      next: (res) => {
        this.ngxUiLoaderService.stop('loader-01');
        this.router
          .navigateByUrl('/dummy', { skipLocationChange: true })
          .then(() => {
            const res = {
              message:`Order : ${payload.trialOrder.orderNumber}, has been successfully updated`,
              statusCode: 200,
            };
            if (sessionStorage.getItem('eventId') === '-1') {
              this.router.navigate(['home/quicklinkordernamc'], {
                state: {
                  res,
                  myData: this.data.eventId,
                  dummy: true,
                },
              });
            } else {
              this.router.navigate(
                ['event-order/trial-orders/trialOrderList'],
                {
                  state: {
                    res,
                    myData: this.data.eventId,
                    dummy: true,
                  },
                }
              );
            }
          });
      },
      error: (_err) => {
        this.ngxUiLoaderService.stop('loader-01');
        this.errorMessage =
          'Something Went Wrong Please Contact System Administrator.';
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
    });
  }

  cancel() {
    if (this.editMode) {
      const dialogRef = this.dialog.open(WarningPopupComponent, {
        disableClose: true,
        width: '570px',
        height: '219px',
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result === 'Success') {
          if (sessionStorage.getItem('eventId') === '-1')
            this.router.navigate(['home/quicklinkordernamc']);
          else
            this.router.navigate(['event-order/trial-orders/trialOrderList']);
        }
      });
    } else if (this.viewSuppMode) {
      if (sessionStorage.getItem('eventId') === '-1')
        this.router.navigate(['home/quicklinkordersupplier']);
      else
        this.router.navigate([
          'event-order/trial-orders/supplierTrialOrderList',
        ]);
    } 
    else if(sessionStorage.getItem('eventId') === '-1'){
         this.router.navigate(['home/quicklinkordernamc']);

    }
    else  this.router.navigate(['event-order/trial-orders/trialOrderList']);
  }
}
