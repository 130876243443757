import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AcceptRejectDialogComponent } from 'src/app/core/dialogs/event-and-orders/review-namc/review-namc.component';
import { WarningPopupComponent } from 'src/app/core/dialogs/warning-popup/warning-popup.component';
import {
  OrderData,
  TrialOrderDetails,
} from 'src/app/core/model/trial-order.model';
import { User } from 'src/app/core/model/user.model';
import { TrialOrdersService } from 'src/app/services/event-and-orders/trial-orders.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-review-ship-date',
  templateUrl: './review-ship-date.component.html',
  styleUrls: ['./review-ship-date.component.scss'],
})
export class ReviewShipDateComponent implements OnInit {
  currentRole: string | undefined;
  namcCode = '';
  orderData: OrderData = {} as OrderData;
  namcMode = false;
  supplierMode = false;
  user: User | null = null;
  orderTrackingNumber = '';
  partNumber = '';
  partName = '';
  namcDueDate = '';
  orderdeliveryDate = '';
  totalQuantity = '';
  supplierPickupDate = '';
  supplierShipDate = '';
  namcComment = '';
  supplierComment = '';
  disableflag = false;
  errorMessage = '';
  successMessage = '';
  selectedOrder: TrialOrderDetails = {} as TrialOrderDetails;
  screen = '';

  constructor(
    public dialog: MatDialog,
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly orderservice: TrialOrdersService
  ) {}

  ngOnInit(): void {
    this.selectedOrder = JSON.parse(history.state?.data);
    this.screen = history.state?.screen;
    this.userService.userDetails.subscribe((user) => {
      this.user = user;
      if (user) {
        this.supplierMode = true;
      }
    });
    this.mapData();
  }

  mapData() {
    this.orderTrackingNumber = this.selectedOrder.orderNumber;
    this.orderservice.getShippingDate(this.orderTrackingNumber)?.subscribe({
      next: (res: any) => {
        this.orderData = res;
        this.disableflag = this.orderData.disableFlag;
        if (this.disableflag === true) {
          this.errorMessage = this.orderData.message;
        }
        this.partNumber = this.orderData.orderData?.partNumber;
        this.partName = this.orderData.orderData?.partName;
        this.namcComment = this.orderData.orderData?.namcComment;
        this.supplierComment = this.orderData.orderData?.supplierComment;
        this.supplierPickupDate = this.orderData.orderData?.supplierPickupDate;
        this.supplierShipDate = this.orderData.orderData?.promiseDate;
        this.namcDueDate = this.orderData.orderData?.orderDueDate;
        this.orderdeliveryDate = this.orderData.orderData?.orderDueDate;
        this.totalQuantity = this.orderData.orderData?.totalQty;
      },
      error: (_err) => {
        this.errorMessage = 'Failed to fetch supplier codes';
      },
    });
  }

  openAcceptReview() {
    const dialogRef = this.dialog.open(AcceptRejectDialogComponent, {
      width: '400px',
      data: {
        headerTitle: 'Accept',
        namcComment: this.namcComment,
        selectedOrder: this.selectedOrder,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result !== '') {
        this.errorMessage =
          Object.keys(result).length > 1 ? result.error[0] : result.error;
        this.successMessage = result.success ? result.success[0] : undefined;
        this.router.navigate(['event-order/trial-orders/trialOrderList'], {
          state: {
            errorMessage: this.errorMessage,
            successMessage: this.successMessage,
          },
        });
      }
    });
  }

  openRejectReview() {
    const dialogRef = this.dialog.open(AcceptRejectDialogComponent, {
      width: '400px',
      data: {
        headerTitle: 'Reject',
        namcComment: this.namcComment,
        selectedOrder: this.selectedOrder,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result !== '') {
        this.errorMessage =
          Object.keys(result).length > 1 ? result.error[0] : result.error;
        this.successMessage = result.success ? result.success[0] : undefined;
        this.router.navigate(['event-order/trial-orders/trialOrderList'], {
          state: {
            errorMessage: this.errorMessage,
            successMessage: this.successMessage,
          },
        });
      }
    });
  }
  
  cancel() {
    const dialogRef = this.dialog.open(WarningPopupComponent, {
      disableClose: true,
      width: '570px',
      height: '220px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'Success') {
        if (this.screen === 'quickLinks') {
          this.router.navigate(['home/quicklinkordernamc']);
        } else {
          this.router.navigate(['event-order/trial-orders/trialOrderList']);
        }
      }
    });
  }
  closeErrorMessage() {
    //No work
  }

  goBack() {
    //No work
  }

  onSubmit() {
    this.orderData = history.state?.data;
    this.disableflag = this.orderData.disableFlag;
    this.errorMessage = this.orderData.message;

    this.partNumber = this.orderData.orderData?.partNumber;
    this.partName = this.orderData.orderData?.partName;
    this.namcComment = this.orderData.orderData?.namcComment;
    this.supplierComment = this.orderData.orderData?.supplierComment;
    this.supplierPickupDate = this.orderData.orderData?.supplierPickupDate;
    this.namcDueDate = this.orderData.orderData?.orderDueDate;
    this.orderdeliveryDate = this.orderData.orderData?.orderDueDate;
    this.totalQuantity = this.orderData.orderData?.totalQty;
    this.supplierShipDate = this.formatDate(
      this.orderData.orderData.promiseDate
    );
  }

  formatDate(date: any): string {
    if (date === null) {
      return '';
    }
    const parsedDate = new Date(date);
    if (isNaN(parsedDate.getTime())) {
      return '';
    }

    return parsedDate.toISOString().split('T')[0];
  }
}
