import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TrialOrderDetails } from 'src/app/core/model/trial-order.model';
import { TrialOrdersService } from 'src/app/services/event-and-orders/trial-orders.service';
import { UserService } from 'src/app/services/user.service';
@Component({
  selector: 'app-confirm-selected-orders',
  templateUrl: './confirm-selected-orders.component.html',
  styleUrls: ['./confirm-selected-orders.component.scss'],
})
export class ConfirmSelectedOrdersComponent implements OnInit {
  selectedRow: TrialOrderDetails[] = [];
  ordersNumber = '';
  userId!: string;
  constructor(
    private readonly dialogRef: MatDialogRef<ConfirmSelectedOrdersComponent>,
    private readonly trialOrderService: TrialOrdersService,
    private readonly userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly ngxUiLoaderService: NgxUiLoaderService
  ) {
    this.selectedRow = this.data.selectedOrder;
  }
  ngOnInit(): void {
    this.userService.userDetails?.subscribe((value) => {
      if (value !== null) {
        this.userId = value.userId;
      }
    });
  }

  onYesClick(): void {
    this.ordersNumber = this.selectedRow
      .map((order) => order.orderNumber)
      .join(',');
    const payload = {
      appendOrderNumbers: this.ordersNumber,
      userId:this.userId
    };
    this.ngxUiLoaderService.start('confirmSelOrders');
    this.trialOrderService.confirmShipDateSupplier(payload)?.subscribe({
      next: (result) => {
        this.ngxUiLoaderService.stop('confirmSelOrders');
        this.dialogRef.close(result);
      },
      error: (err) => {
        this.ngxUiLoaderService.stop('confirmSelOrders');
        const error = err as HttpErrorResponse;
        this.dialogRef.close(error.error);
      },
    });
  }

  onNoClick(): void {
    this.dialogRef.close('');
  }

  closeDialog(): void {
    this.dialogRef.close('');
  }
}
