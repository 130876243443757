<div *ngIf="disableflag">
  <app-notification-message [errorMessage]="errorMessage">
  </app-notification-message>
</div>
<div class="form-g">
  <label for="eventDescription">ORDER TRACKING NUMBER</label>
  <input
    id="eventDescription"
    type="text"
    [(ngModel)]="orderTrackingNumber"
    [readonly]="supplierMode"
    [ngClass]="{ 'readonly-input': supplierMode }"
    style="width: 500px"
    class="form-control"
    disableSpecialCharacters
  />
</div>
<div (ngSubmit)="onSubmit()" class="form-container">
  <div class="form-group">
    <div class="label-container">
      <label for="eventDescription">PART NUMBER</label>
    </div>
    <input
      id="eventDescription"
      type="text"
      [(ngModel)]="partNumber"
      [readonly]="supplierMode"
      [ngClass]="{ 'readonly-input': supplierMode }"
      class="form-control"
      disableSpecialCharacters
    />
  </div>
  <div class="form-group">
    <div class="label-container">
      <label for="eventDescription">PART NAME</label>
    </div>
    <input
      id="eventDescription"
      type="text"
      [(ngModel)]="partName"
      [readonly]="supplierMode"
      [ngClass]="{ 'readonly-input': supplierMode }"
      class="form-control"
      disableSpecialCharacters
    />
  </div>
  <div class="form-group">
    <div class="label-container">
      <label for="eventType">ORDER DELIVERY DUE DATE</label>
    </div>
    <input
      id="eventType"
      type="text"
      [(ngModel)]="namcDueDate"
      [readonly]="supplierMode"
      [ngClass]="{ 'readonly-input': supplierMode }"
      class="form-control"
      disableSpecialCharacters
    />
  </div>
  <div class="form-group">
    <div class="label-container">
      <label for="projectCode">TOTAL QUANTITY</label>
    </div>
    <input
      id="projectCode"
      [(ngModel)]="totalQuantity"
      [readonly]="supplierMode"
      [ngClass]="{ 'readonly-input': supplierMode }"
      type="text"
      class="form-control"
      disableSpecialCharacters
    />
  </div>
  <div class="form-group">
    <div class="label-container">
      <label for="supplierPickupDate">SUPPLIER PICKUP DATE</label>
    </div>
    <input
      type="text"
      id="supplierPickupDate"
      [readonly]="supplierMode"
      [ngClass]="{ 'readonly-input': supplierMode }"
      class="form-control"
      [(ngModel)]="supplierPickupDate"
      name="supplierPickupDate"
      disableSpecialCharacters
    />
  </div>
  <div class="form-group">
    <div class="label-container">
      <label for="supplierShipDate">SUPPLIER SHIP DATE</label>
    </div>
    <input
      type="text"
      id="supplierShipDate"
      [readonly]="supplierMode"
      [ngClass]="{ 'readonly-input': supplierMode }"
      class="form-control"
      [(ngModel)]="supplierShipDate"
      name="supplierShipDate"
      disableSpecialCharacters
    />
  </div>
  <div class="form-group">
    <div class="label-container">
      <div class="vertical-bar"></div>
      <label for="namcComment">NAMC COMMENT</label>
    </div>
    <textarea
      id="namcComment"
      [readonly]="namcMode"
      [ngClass]="{ 'readonly-input': namcMode }"
      [(ngModel)]="namcComment"
      name="namcComment"
    ></textarea>
  </div>
  <div class="form-group">
    <div class="label-container">
      <label for="namcComment">SUPPLIER COMMENT</label>
    </div>
    <textarea
      id="namcComment"
      [(ngModel)]="supplierComment"
      [readonly]="supplierMode"
      [ngClass]="{ 'readonly-input': supplierMode }"
      name="supplierComment"
    ></textarea>
  </div>

  <footer class="footer">
    <button class="cancel-button" (click)="cancel()">CANCEL</button>

    <button
      *ngIf="!disableflag"
      class="b2-button add black-button blackbtnalign me-3 mt-2"
      (click)="openRejectReview()"
      [disabled]="!namcComment.trim()"
    >
      Reject Supplier Ship Date
    </button>
    <button
      *ngIf="!disableflag"
      class="black-button blackbtnalign me-3 mt-2"
      (click)="openAcceptReview()"
      [disabled]="!namcComment.trim()"
    >
      Accept Supplier Ship Date
    </button>
  </footer>
</div>
