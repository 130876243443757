<div *ngIf="disableflag">
  <app-notification-message [errorMessage]="errorMessage">
  </app-notification-message>
</div>
<div>
  <div class="form-g">
    <label for="eventDescription">Order Tracking Number</label>
    <input
      id="eventDescription"
      type="text"
      [(ngModel)]="orderTrackingNumber"
      [readonly]="supplierMode"
      [ngClass]="{ 'readonly-input': supplierMode }"
      style="width: 500px"
      class="form-control"
      disableSpecialCharacters
    />
  </div>
  <div class="form-container">
    <div class="form-group">
      <label for="eventDescription">PART NUMBER</label>
      <input
        id="eventDescription"
        type="text"
        [(ngModel)]="partNumber"
        [readonly]="supplierMode"
        [ngClass]="{ 'readonly-input': supplierMode }"
        class="form-control"
        disableSpecialCharacters
      />
    </div>
    <div class="form-group">
      <label for="eventDescription">PART NAME</label>
      <input
        id="eventDescription"
        [(ngModel)]="partName"
        [readonly]="supplierMode"
        [ngClass]="{ 'readonly-input': supplierMode }"
        type="text"
        class="form-control"
        disableSpecialCharacters
      />
    </div>
    <div class="form-group">
      <label for="eventType">NAMC DUE DATE</label>
      <input
        id="eventType"
        type="text"
        class="form-control"
        [(ngModel)]="namcDueDate"
        [readonly]="supplierMode"
        [ngClass]="{ 'readonly-input': supplierMode }"
        disableSpecialCharacters
      />
    </div>
    <div class="form-group">
      <label for="projectCode">TOTAL QUANTITY</label>
      <input
        id="projectCode"
        type="text"
        [(ngModel)]="totalQuantity"
        [readonly]="supplierMode"
        [ngClass]="{ 'readonly-input': supplierMode }"
        class="form-control"
        disableSpecialCharacters
      />
    </div>
    <div class="form-group">
      <label for="supplierPickupDate">SUPPLIER PICKUP DATE</label>
      <input
        type="text"
        id="supplierPickupDate"
        [readonly]="supplierMode"
        [ngClass]="{ 'readonly-input': supplierMode }"
        class="form-control"
        [(ngModel)]="supplierPickupDate"
        name="supplierPickupDate"
        disableSpecialCharacters
      />
    </div>

    <div class="form-group">
      <div class="label-container">
        <div class="vertical-bar"></div>
        <label for="supplierShipDate">SUPPLIER SHIP DATE</label>
      </div>
      <input
        type="date"
        id="supplierShipDate"
        class="form-control"
        [(ngModel)]="supplierShipDate"
        (ngModelChange)="onDateChange($event)"
        name="supplierShipDate"
        [min]="minDate"
        (keydown)="stopDateInput($event)"
      />
      <div *ngIf="isErrorSupplierDate">
        <small class="errorValid">
          Supplier Ship Date can not be earlier than 10 days of pickup date.
        </small>
      </div>
    </div>
    <div class="form-group">
      <label for="namcComment">NAMC COMMENT</label>
      <textarea
        id="namcComment"
        [(ngModel)]="namcComment"
        name="namcComment"
        [readonly]="supplierMode"
        [ngClass]="{ 'readonly-input': supplierMode }"
      ></textarea>
    </div>
    <div class="form-group">
      <div class="label-container">
        <div class="vertical-bar"></div>
        <label for="namcComment">SUPPLIER COMMENT</label>
      </div>
      <textarea
        id="namcComment"
        [(ngModel)]="supplierComment"
        name="supplierComment"
      ></textarea>
      <div *ngIf="isError">
        <small class="errorValid">
          Please Enter in Comments Field Reason for Alternate Ship Date Request
        </small>
      </div>
    </div>

    <footer class="footer">
      <button class="cancel-button" *ngIf="!disableflag" (click)="cancel('cancel')">
        CANCEL
      </button>
      <button class="cancel-button" *ngIf="disableflag" (click)="cancel('back')">
        BACK
      </button>
      <div class="button-container">
        <button class="b2-button add" *ngIf="!disableflag" (click)="onSubmit()">
          SUBMIT
        </button>
      </div>
    </footer>
  </div>
</div>
