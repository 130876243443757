<div class="popup-container">
  <button class="close black-button" (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </button>

  <h2 class="px-3 py-1 mb-0 roboto-medium">
    <fa-icon class="me-2"></fa-icon>
    Filter(s)
  </h2>
  <hr id="shadow" />
  <div class="filter-container">
    <br />
    <p-multiSelect
      [options]="cities"
      [(ngModel)]="selectedCities"
      [filter]="false"
      [showSelectAll]="true"
      optionLabel="label"
      [style]="{ width: '47.5rem', margin: '0 20px' }"
    >
      <ng-template pTemplate="header">
        <div
          pButton
          class="hyperlink"
          type="button"
          label="Select All"
          (click)="selectAll()"
        ></div>
        <div
          pButton
          class="hyperlink"
          type="button"
          label="Clear All"
          (click)="clearAll()"
        ></div>
      </ng-template>
    </p-multiSelect>

    <br />
    <div class="d-flex flex-row flex-wrap" style="margin: 0 20px">
      <div class="m-1" *ngFor="let city of selectedCities">
        <div
          class="border border-1 rounded border-dark d-flex flex-row justify-content-center align-items-center"
        >
          <label class="py-1 px-1" *ngIf="city !== null"
            >{{ city.label }}
          </label>
          <fa-icon
            [icon]="faXmark"
            title="Filter Grid"
            style="cursor: pointer"
            (click)="chipClicked(city.label)"
            class="fw-bolder text-dark fs-6 pe-1"
          ></fa-icon>
        </div>
      </div>
    </div>
  </div>
  <div class="right-align mt-2">
    <button class="b2-button add roboto-medium" (click)="onSave()">
      APPLY FILTER
    </button>
    <button class="b2 me-3 ms-3 roboto-bold" (click)="selectAllnSave()">
      CLEAR FILTER
    </button>
  </div>
</div>
