<app-notification-message
  [errorMessage]="errorMessage"
></app-notification-message>
<div class="order-container">
  <label for="orderSelect">Select Number of Orders to Append</label>
  <select id="orderSelect" [(ngModel)]="numOfOrders">
    <option *ngFor="let number of orderOptions" [value]="number">
      {{ number }}
    </option>
  </select>
  <button
    class="append-btn"
    (click)="appendOrders()"
    [disabled]="isAppendDisabled"
  >
    APPEND ORDERS
  </button>
</div>

<div class="tablebox">
  <div class="table-container">
    <table>
      <thead class="header">
        <tr>
          <th *ngIf="eventtypeid === 2">PO Tracking Number</th>
          <th>PSMS Routing</th>
          <th >
            <span class="required-color header-display px-2" >
              Supplier Code</span
            >
          </th>
          <th>
            <span class="required-color header-display px-2">
              Supplier Name</span
            >
          </th>
          <th>Kanban No</th>
          <th>
            <span class="required-color header-display px-2"> Part Number</span>
            <span
              *ngIf="currentNamc === '26MTM'"
              class="text-primary fst-italic"
              >'I' denotes blank space</span
            >
          </th>
          <th>
            <span class="required-color header-display px-2"> Part Name</span>
          </th>
          <th>
            <span class="required-color header-display px-2">
              Order Delivery Due Date</span
            >
          </th>
          <th>
            <span class="required-color header-display px-2"
              >Supplier Pickup Date</span
            >
          </th>
          <th>Plant 1 Qty</th>
          <th>Plant 2 Qty</th>
          <th>Plant 3 Qty</th>
          <th *ngFor="let quantity of defaultQuantities">
            <span> {{ quantity }} </span>
          </th>
          <th>
            <span class="required-color header-display px-2">
              Total Quantity</span
            >
          </th>
          <th>Comment</th>
          <th>Store Address</th>
          <th>Lineside Address</th>
          <th>Life Cycle Code</th>
          <th *ngIf="eventtypeid === 2">Send To SAP Flag</th>
          <th *ngIf="currentNamc === '26MTM'">WORK ORDER NUMBER</th>
          <th>Pallet Code</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let order of orders; let i = index"
          [ngClass]="{ 'even-row': i % 2 === 0, 'odd-row': i % 2 !== 0 }"
        >
          <td *ngIf="eventtypeid === 2">
            <input
              type="text"
              [(ngModel)]="order.poTrackingNumber"
              maxlength="12"
            />
          </td>
          <td>
            <input type="text" [(ngModel)]="order.psmsRouting" maxlength="5" />
          </td>

          <td >
          
            <p-dropdown
            [options]="supplierCodes"
            optionLabel="supplierCode"
            optionValue="supplierCode"
            class="dropdownOptions"
            placeholder="Select Supplier"
            [virtualScroll]="true"
            scrollHeight="200px"
            [virtualScrollItemSize]="30"
            (onChange)="onSupplierCodeChange($event, i)"
            [(ngModel)]="order.supplierCode"
             [ngClass]="{
                'input-valid': order.supplierCode,
                'require-field': order.supplierCodeError 
              }"
            >
            </p-dropdown>
          </td>
          <td>
            <input
              type="text"
              [(ngModel)]="order.supplierName"
              [ngClass]="{
                'input-valid': order.supplierName,
                'required-field': order.supplierNameError
              }"
              readonly
              disabled
            />
          </td>
          <td>
            <input type="text" [(ngModel)]="order.kanbanNo" maxlength="4" />
          </td>
          <td>
            <input
              type="text"
              maxlength="12"
              [(ngModel)]="order.partNumber"
              [ngClass]="{
                'input-valid': order.partNumber,
                'required-field':
                  order.partNumberError || order.partNumberLengthError
              }"
            />
          </td>
          <td>
            <input
              type="text"
              maxlength="50"
              [(ngModel)]="order.partName"
              [ngClass]="{
                'input-valid': order.partName,
                'required-field': order.partNameError
              }"
            />
          </td>
          <td>
            <input
              type="date"
              [(ngModel)]="order.orderDeliveryDate"
              (keydown)="stopDateInput($event)"
              [ngClass]="{
                'input-valid':
                  order.orderDeliveryDateError ||
                  order.invalidDateError ||
                  order.deliveryDateError,
                'required-field':
                  order.orderDeliveryDateError ||
                  order.deliveryDateError ||
                  order.invalidDateError
              }"
            />
          </td>
          <td>
            <input
              type="date"
              (keydown)="stopDateInput($event)"
              [(ngModel)]="order.orderPickupDate"
              [ngClass]="{
                'input-valid':
                  order.orderPickupDateError ||
                  order.orderPickupDate ||
                  order.pickupDateLError ||
                  order.invalidDateError,
                'required-field':
                  order.orderPickupDateError ||
                  order.pickupDateError ||
                  order.invalidDateError ||
                  order.pickupDateLError
              }"
            />
          </td>
          <td><input type="number" [(ngModel)]="order.plant1Qty" maxlength="6"
            min="0" (keydown)="onKeyDown($event)"/></td>
          <td><input type="number" [(ngModel)]="order.plant2Qty" maxlength="6"
            min="0" (keydown)="onKeyDown($event)" /></td>
          <td><input type="number" [(ngModel)]="order.plant3Qty" maxlength="6"
            min="0" (keydown)="onKeyDown($event)" /></td>
          <td *ngFor="let quantity of defaultQuantities; let i = index">
            <input maxlength="6" type="text"  [(ngModel)]="order.quantities[i]"/>
          </td>

          <td>
            <input
              type="text"
              maxlength="6"
              [(ngModel)]="order.totalQuantity"
              [ngClass]="{
                'input-valid': order.totalQuantity,
                'required-field':
                  order.totalQuantityError || order.totalQuantityMismatch
              }"
            />
          </td>
          <td>
            <textarea [(ngModel)]="order.comment" maxlength="1000"></textarea>
          </td>
          <td>
            <input type="text" [(ngModel)]="order.store" maxlength="10" />
          </td>
          <td>
            <input type="text" [(ngModel)]="order.lineSide" maxlength="10" />
          </td>
          <td>
            <input
              type="text"
              [(ngModel)]="order.lifeCycleCode"
              maxlength="1"
            />
          </td>
          <td *ngIf="eventtypeid === 2">
            <input
              type="text"
              [(ngModel)]="order.sendToSapFlag"
              maxlength="1"
            />
          </td>
          <td *ngIf="currentNamc === '26MTM'">
            <input
              type="text"
              [(ngModel)]="order.workOrderNumber"
              maxlength="17"
            />
          </td>
          <td>
            <input type="text" [(ngModel)]="order.palletCode" maxlength="2" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="showError" style="color: red; margin-left: 10px">
    Please verify highlighted fields
  </div>
</div>
<footer class="footer">
  <button class="cancel-button" (click)="cancel()">CANCEL</button>

  <div class="button-container">
    <button class="b2-button add" (click)="onSubmit()">SUBMIT</button>
  </div>
</footer>
