<app-notification-message
  [errorMessage]="errorMessage"
  [successMessage]="successMessage"
>
</app-notification-message>

<div *ngIf="validationMessages.length > 0" class="alert alert-danger">
  <ul>
    <li *ngFor="let message of validationMessages">{{ message }}</li>
  </ul>
</div>

<div class="form-group mt-3">
  <div class="actual-label-container">
    <div class="vertical-bar"></div>
    <label for="upload">UPLOAD</label>
  </div>
  <p-dropdown
    [options]="uploadOptions"
    optionLabel="name"
    optionValue="id"
    [(ngModel)]="selectDrop"
    class="dropdownOptions"
    placeholder="Select Upload"
    (onChange)="showForm($event)"
  >
  </p-dropdown>
</div>
<form
  [formGroup]="eventForm"
  (ngSubmit)="onSubmit()"
  class="form-container"
  *ngIf="hideForm"
>
  <div class="form-group">
    <div class="label-container">
      <div class="vertical-bar"></div>
      <label for="event">EVENT</label>
    </div>
    <p-dropdown
      formControlName="event"
      [ngClass]="{'is-invalid': eventForm.get('event')?.touched && eventForm.get('event')?.invalid && eventForm.get('event')?.errors?.['required']}"
      [options]="events"
      optionLabel="eventName"
      optionValue="eventId"
      placeholder="Select Event"
      styleClass="w-100"
      style="height: 33px !important"
      (onChange)="setDetails()"
      [filter]="true"
    >
    </p-dropdown>
    <div
      *ngIf="eventForm.get('event')?.touched && eventForm.get('event')?.invalid"
    >
      <small
        *ngIf="eventForm.get('event')?.errors?.['required']"
        class="errorValid"
        >Event is Required.</small
      >
    </div>
  </div>
  <div class="form-group">
    <label for="eventDescription">EVENT DESCRIPTION</label>
    <input
      id="eventDescription"
      formControlName="eventDescription"
      type="text"
      class="form-control"
      disableSpecialCharacters
    />
  </div>
  <div class="form-group">
    <label for="eventType">EVENT TYPE</label>
    <input
      id="eventType"
      formControlName="eventType"
      type="text"
      class="form-control"
      disableSpecialCharacters
    />
  </div>
  <div class="form-group">
    <label for="projectCode">PROJECT CODE</label>
    <input
      id="projectCode"
      formControlName="projectCode"
      type="text"
      class="form-control"
      disableSpecialCharacters
    />
  </div>
  <div class="form-group">
    <div class="label-container">
      <div class="vertical-bar"></div>
      <label for="file">FILE</label>
    </div>
    <input
      type="file"
      #fileInput
      (change)="onFileChange($event)"
      accept=".xls, .xlsx"
      hidden
    />
    <button
      type="button"
      class="custom-file-upload"
      (click)="fileInput.value = ''; fileInput.click()"
    >
      Choose File
    </button>
    <div *ngIf="uploadedFileName" class="file-info">
      <span>{{ uploadedFileName }}</span>
      <div class="btn" (click)="removeFile()" (keydown)="removeFile()">
        &#x2212;
      </div>
    </div>
    <div
      *ngIf="eventForm.get('file')?.touched && eventForm.get('file')?.invalid"
    >
      <small
        *ngIf="eventForm.get('file')?.errors?.['required']"
        class="errorValid"
        >File is required.</small
      >
    </div>
  </div>
  <footer class="footer">
    <div class="button-container">
      <button class="b2-button add b2 submit">UPLOAD ORDER FILES</button>
    </div>
  </footer>
</form>
