import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { handleError } from '../../core/common/common';
import {
  DropdownList,
  TrialEventsList,
} from '../../core/model/manage-trial-events.model';
import { SelectedRole } from 'src/app/core/model/role-check.model';

@Injectable({
  providedIn: 'root',
})
export class ManageTrialEventsService {
  baseApiUrl: string;
  message: string | undefined = undefined;
  constructor(private readonly http: HttpClient) {
    this.baseApiUrl = environment.baseAPIURL;
  }

  private readonly editDataSubject = new BehaviorSubject<any>(this.getStoredData());
  editData$ = this.editDataSubject.asObservable();

  setEditData(data: any) {
    sessionStorage.setItem('editData', JSON.stringify(data));
    this.editDataSubject.next(data);
  }

  private getStoredData() {
    const storedData = sessionStorage.getItem('editData');
    return storedData ? JSON.parse(storedData) : null;
  }

  clearData() {
    sessionStorage.removeItem('editData');
    this.editDataSubject.next(null);
  }

  getTrialEventsList(roleDetails: SelectedRole) {
    const roleDetail = JSON.stringify(roleDetails);
    const encodedRoleDetails = encodeURIComponent(roleDetail);
    return this.http
      .get<TrialEventsList[]>(`${this.baseApiUrl}/events-orders/events?roleDetails=${encodedRoleDetails}`, {
        withCredentials: true,
        responseType: 'json',
      })
      .pipe(catchError(handleError));
  }

  dropDownOnLoad() {
    return this.http
      .get<DropdownList>(`${this.baseApiUrl}/events-orders/events/onload`, {
        withCredentials: true,
        responseType: 'json',
      })
      .pipe(catchError(handleError));
  }

  mobiliseOrderQuantities(eventId: string) {
    return this.http
      .get<{
        quantityResult: { quantityId: number; quantityName: string }[];
        orderCount: number;
        specialistDetails: {
          specialistCode: number | null;
          specialistContact: string;
        }[];
      }>(
        `${this.baseApiUrl}/events-orders/events/order-quantity?eventId=${eventId}`,
        {
          withCredentials: true,
          responseType: 'json',
        }
      )
      .pipe(catchError(handleError));
  }

  induceEvent(payload: any) {
    return this.http
      .post<any>(`${this.baseApiUrl}/events-orders/events`, payload, {
        withCredentials: true,
        responseType: 'json',
      })
      .pipe(catchError(handleError));
  }

  editEvent(payload: any) {
    return this.http
      .patch<any>(`${this.baseApiUrl}/events-orders/events`, payload, {
        withCredentials: true,
        responseType: 'json',
      })
      .pipe(catchError(handleError));
  }

  deleteEventService(eventId: number, updatedBy: string | undefined) {
    return this.http
      .delete(
        `${this.baseApiUrl}/events-orders/events?eventId=${eventId}&updatedBy=${updatedBy}`,
        {
          withCredentials: true,
          responseType: 'json',
        }
      )
      .pipe(catchError(handleError));
  }

  setMessage(msg?: string) {
    this.message = msg;
  }
  getMessage() {
    return this.message;
  }
}
